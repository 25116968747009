import React, { useContext } from "react";

import Steppers from "../../../../../../../Components/Steppers/Steppers";

import { FaWpforms, FaUsers } from "react-icons/fa";

const AddStepper = ({ currentStep, close, inv = true }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>{ inv ? "Add Inventory Item" : "Add Fixed Asset"}</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaUsers />
        <p>Select Vendor</p>
      </div>
      
    </Steppers>
  );
};

export default AddStepper;
