import { useRef, useState, useEffect } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import TextArea from "../../../../../../../Components/Inputs/TextArea";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { NavItem } from "react-bootstrap";

import useAxios from "../../../../../../../Hooks/useAxios";

import { useParams } from "react-router-dom";

import useApp from "../../../../../../../Hooks/useApp";

import usePouchCud from "./../../../../../Hooks/usePouchCud";

const AddModal = ({ showModal, setShowModal, documents, setDocuments }) => {
  const { id } = useParams();
  const input = useRef({ form: {}, err: {} });
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(null);

  const doc = useRef();

  const [showError, setShowError] = useState(0);

  const { setShowSuccessModal, setModalMessage } = useApp();

  const { create } = usePouchCud();

  const request = useAxios();

  function handleSubmit() {
    setShowError((c) => c + 1);
    if (
      input.current.form["description"] === null ||
      input.current.form["description"] === undefined ||
      input.current.form["description"] === ""
    ) {
      setSubmited(false);
      return;
    }
    doc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      let formData = new FormData();
      formData.append("document", files[0]);
      formData.append("vendorId", id);
      formData.append("description", input.current.form["description"]);
      setShowModal(false);
      let res = await request({
        method: "POST",
        url: "vendor/upload-document",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });

      if (res === "error") return;
      await create({ name: "vendor_documents", doc: res });
      setDocuments([res, ...documents]);
      setModalMessage("Document added");
      setShowSuccessModal(true);
    }
    addDocument();
  }, [submited, files]);

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add vendor document
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            initial=""
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "eg Contract",
              type: "text",
              inputType: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <BtnBlock
            click={handleSubmit}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Select Document</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
          <input
            className=" invisible"
            ref={doc}
            type="file"
            id="myFile"
            name="filename"
            onChange={(e) => setFiles(e.target.files)}
          />
        </form>
      </div>
    </BasicModal>
  );
};

export default AddModal;
