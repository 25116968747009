// import Table from "../../../../../../Components/Table";
import React, { useState } from "react";
import BellTopBar from "../../../../../../Components/Bars/BellTopBar.jsx";

import AllVendorTable from "./components/AllVendorTable";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";

import VendorAll from "./context/VendorsContext";

import { useNavigate } from "react-router-dom";

const PouchData = () => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const db_name = "vendors";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["name", "email", "phone", "tra"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const navigate = useNavigate();

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  function handleAdd() {
    navigate("/dashboard/vendors/add");
  }

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <VendorAll.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        total,
        setIsFiltered,
        showPagination,
      }}
    >
      <div className="w-full h-full bg-ghost_white ">
        <BellTopBar label="Vendors" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            mt={2}
            mb={2}
            changes={0}
            showAdd={true}
            AddClick={handleAdd}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            addLabel="Add"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllVendorTable />
        </div>
      </div>
    </VendorAll.Provider>
  );
};

export default PouchData;
