import React, { useRef, useState, useContext } from "react";

import NumberInput from "../../../../../../../../Components/Inputs/NumberInput";
import SelectInput from "../../../../../../../../Components/Inputs/SelectInput";

import { FaLongArrowAltRight } from "react-icons/fa";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import useHandleFormError from "../../../../../../../../Hooks/useHandleFormError";

import AllocateContext from "./../../AllocateContex";

const selectData = [
  {
    label: "Select Allocation Type",
    value: "",
  },
  {
    label: "User",
    value: true,
  },

  {
    label: "Space",
    value: false,
  },
];

const SelectRoom = ({}) => {
  const input = useRef({ form: {}, err: {} });

  const { setAllocate, allocate, setStep, inv } = useContext(AllocateContext);

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  async function handleSubmit() {
    await setShowError(showError + 1);
    if (formErrors({ input })) return;

    await setAllocate({
      to_user: input.current.form.allocation === "false" ? false : true,
      qty: input.current.form.qty,
    });

    setStep((c) => c + 1);
  }

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "60%" }}
    >
      <div className="w-full">
        <SelectInput
          input={input}
          initial={allocate.to_user || ""}
          inputKey="allocation"
          showError={showError}
          config={{
            required: true,
            items: selectData,
            label: "Allocate to space or team member ?",
            firstDisabled: false,
          }}
        />
        <NumberInput
          input={input}
          inputKey="qty"
          initial={1}
          showError={showError}
          config={{
            label: `Qty to allocate, ${inv.store_qty} in store`,
            min: 1,
            max: inv.qty,
          }}
        />
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Continue</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  );
};

export default SelectRoom;
