import React from "react";
import { RiLuggageCartLine } from "react-icons/ri";

export default function Assets({fixed}) {

    return (
        <div className="w-full h-56 shadow-sm rounded-2xl bg-primary p-6 flex flex-col justify-between">
            <div className="flex justify-between items-center mb-1 ">
                <div className="font-DM-Reg text-2xl text-white">
                    <p>Assigned Fixed Assets</p>
                </div>
                <div>
                    <RiLuggageCartLine
                        size="32px"
                        className="text-white"
                    />
                </div>
            </div>
            <div className="flex justify-between items-center my-5 ">
                <div className="font-pop-reg text-3xl text-white">
                    <p>{fixed} {fixed === 1 ? 'Item' : 'Items'}</p>
                </div>
                <div className="flex justify-center items-center ">
                    <div className="flex flex-col justify-center items-start">
                        <p className="text-white my-0 underline cursor-pointer text-lg"></p>
                    </div>
                </div>
            </div>
        </div>
    )
}