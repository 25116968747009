import React from "react";
import ReactDOM from "react-dom/client";
import APP from "./APP";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./css.css";

// import PouchTest from "./APP/Pages/PouchTest";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <APP />
    {/* <PouchTest /> */}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
