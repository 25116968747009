import { useState } from "react";
import BellTopBar from "../../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../../Components/FinalTable";
import AddOrderModal from "./components/Modals/AddOrderModal";
import PurchaseOrderFilters from "./components/Modals/PurchaseOrderFilters";

import AllContext from "./Context";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";

import Table from "./components/Table";

const PouchData = ({ showLive }) => {
  const [addModal, setShowAddModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [tabsData, setTabsData] = useState({});

  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "purchase_orders";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
    fixed,
  });

  async function handleClearFilters() {
    await setFixed({});
    await resetQuery();

    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        tabsData,
      }}
    >
      <div className="w-full h-screen bg-ghost_white">
        <div className="w-full ">
          <BellTopBar label="Orders" />
        </div>
        <div className="w-full px-7 pb-8 mt-8">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => setShowAddModal(true)}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <Table />
        </div>
        <AddOrderModal showModal={addModal} setShowModal={setShowAddModal} />
        <PurchaseOrderFilters
          showFilter={openFilter}
          setShowFilter={setOpenFilter}
        />
      </div>
    </AllContext.Provider>
  );
};

export default PouchData;
