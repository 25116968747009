import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
  HorizontalActionBtn,
} from "../../../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 4} label="NAME" />
        <HeaderBtn w={90 / 4} label="EMAIL" />
        <HeaderBtn w={90 / 4} label="PHONE" />
        <HeaderBtn w={90 / 4} label="TRA" />
        <div className="flex-1 invisible">
          {/* <TableHorizontalActionBtn /> */}
          <HorizontalActionBtn></HorizontalActionBtn>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
