const host = "prod.relax.mkombozihealthcare.co.tz";

const baseUrl = "https://api.mkombozihealthcare.co.tz/api";

const user = "admin";

const pass = "medico-prod";

const dbs = [
  "team",
  "team_roles",
  "spaces",
  "space_types",
  "vendors",
  "vendor_payment_details",
  "vendor_documents",
  "vendor_contact_person",
  "vendor_address",
  "inventory_items",
  "inventory_item_categories",
  "unit_of_measures",
  "fixed_assets",
  "fixed_assets_categories",
  "purchase_orders",
  "purchase_other_charges",
  "purchase_inventory",
  "purchase_documents",
  "purchase_fixed",
  "store_request"
];

export { host, pass, user, baseUrl, dbs };
