import React, { useState } from "react";

import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "../../../../../../../../Components/FinalTable";

import HeaderBtn from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import HeaderBtnSort from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

const TableHeader = () => {
  return (
    <TableHeaderRow>
      <HeaderBtn w={90 / 4} label="NAME" />
      <HeaderBtn w={90 / 4} label="Type" />
      <HeaderBtn w={90 / 4} label="Date uploaded" />
      <HeaderBtnSort w={90 / 4} label="Actions" />
      <div className="flex-1 invisible">
        {/* <TableHorizontalActionBtn /> */}
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  );
};

export default TableHeader;
