import React, { useContext, useState } from "react";

import TeamContext from "./TeamContext";

import TRow from "./TRow";

const TBody = ({ checked_id, setCheckedId }) => {
  const { data } = useContext(TeamContext);

  return (
    <>
      {data.map((doc) => (
        <TRow
          key={doc.id || doc._id}
          doc={doc}
          checked_id={checked_id}
          setCheckedId={setCheckedId}
        />
      ))}
    </>
  );
};

export default TBody;
