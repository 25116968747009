import React, { useContext } from "react";

import EditVendor from "../context/editVendor";

const useAddVendor = () => {
  const {
    vendor,
    setVendor,
    contactPerson,
    setContactPerson,
    address,
    setAddress,
    payment,
    setPayment,
  } = useContext(EditVendor);
  return {
    vendor,
    setVendor,
    contactPerson,
    setContactPerson,
    address,
    setAddress,
    payment,
    setPayment,
  };
};

export default useAddVendor;
