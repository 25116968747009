import React, { useRef, useState, useContext } from "react";

import NumberInput from "../../../../../../../../Components/Inputs/NumberInput";

import TextInput from "../../../../../../../../Components/Inputs/TextInput";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import AllocateContext from "../../AllocateContex";

import useAxios from "../../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";
const Submit = () => {
  const { allocate, inv } = useContext(AllocateContext);

  const { setShowSuccessModal, setModalMessage } = useApp();

  const input = useRef({ form: {}, err: {} });

  const navigate = useNavigate();

  const request = useAxios();

  async function handleSubmit() {
    let data = {
      qty: parseInt(allocate.qty),
      fixed_asset_id: parseInt(inv.id),
    };

    if (allocate.to_user === true) {
      data.user_id = allocate.to;
      //data.to_user = true;
    } else {
      data.space_id = parseInt(allocate.to);
      //data.to_user = false;
    }

    let res = await request({
      method: "POST",
      url: "store/allocation",
      data: data,
    });

    if (res === "error") return;

    setModalMessage("Fixed asset allocated");
    setShowSuccessModal(true);
    navigate("../");
  }

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "60%" }}
    >
      <p className=" text-xl font-DM-Bold mb-2 text-cadet_grey">
        Complete Allocation
      </p>
      <div className="w-full">
        <TextInput
          input={input}
          inputKey="alocate"
          initial={
            allocate.to_user === true
              ? `Allocate ${allocate.qty} ${inv.name} to user`
              : `Allocate ${allocate.qty} ${inv.name}  to room`
          }
          config={{ disabled: true }}
        />
        <NumberInput
          input={input}
          inputKey="qty"
          initial={allocate.qty}
          config={{
            label: "Qty to allocate",
            disabled: true,
            min: 1,
            max: 20,
          }}
        />
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Submit</span>
        </BtnRounded>
      </div>
    </div>
  );
};

export default Submit;
