import React from 'react'

const OrderDescription = () => {
  return (
    <div className='flex flex-col gap-y-12 p-9'>
        <div className="flex justify-between">
            <h1 className='font-DM-Bold font-bold text-2xl text-black'>Bandage Orders</h1>
            <h1 className='font-DM-Reg text-2xl text-black'>18 Oct 2020</h1>
        </div>

        <div className="flex flex-col gap-y-1 lg:max-w-3xl">
            <h3 className='font-DM-Bold font-bold text-2xl text-[#55585B]'>Description</h3>
            <p className='font-DM-Reg font-normal text-2xl '>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum eum, nihil quia fuga optio soluta tenetur quas possimus.
                 Iste incidunt odio maxime officia deleniti aliquam obcaecati, sequi error quae consectetur?
            </p>
        </div>
    </div>
  )
}

export default OrderDescription