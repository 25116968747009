import React, { useState, useContext, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import useFuzzySearch from "../../../../../../../../../Hooks/useFuzzySearch";

import usePouchPagination from "../../../../../../../../../Hooks/usePouchPagination";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import BtnPlain from "../../../../../../../../../Components/Buttons/BtnPlain";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import SearchInput2 from "../../../../../../../../../Components/Inputs/SearchInput";

import AddInvContext from "../AddInvContext";

import useApp from "./../../../../../../../../../Hooks/useApp";

import useAxios from "./../../../../../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

import { useParams, useNavigate } from "react-router-dom";

const InvTable = ({ showLive, setShowLive }) => {
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { docs, changedDocs, checkedDocs, checkedVendor } =
    useContext(AddInvContext);

  const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();

  const { id } = useParams();

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    getInitVendors(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      let res = await request({
        method: "GET",
        url: "vendor/search",
        params: {
          value: searchInput,
        },
      });
      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;
      setShowLive(true);
      const fuse = new Fuse([...res.name, ...res.tra_pin], {
        keys: ["name", "tra_pin", "email", "phone"],
      });

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
    }

    search();
  }, [searchInput]);

  async function getInitVendors(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    let res = await request({
      method: "GET",
      url: "vendor",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitVendors(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitVendors(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(q) {
    //console.log(q);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSordOrder(order);
    setSortField(field);
    getInitVendors(1, field, order);
  }

  async function handleClearFilters() {
    await getInitVendors(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  async function handleSubmit() {
    if (checkedVendor === null || checkedVendor === -1) {
      setModalMessage("Select vendor");
      setShowFormModal(true);
      return;
    }

    let purchase_inv = [];
    let new_docs = [];

    for (let id of checkedDocs) {
      new_docs.push(changedDocs[id]);
    }
    //console.log(new_docs);

    for (let i = 0; i < new_docs.length; i++) {
      let doc = new_docs[i];
      purchase_inv.push({
        inventoryItemId: parseInt(doc.id),
        purchaseOrderId: parseInt(id),
        vendorId: parseInt(checkedVendor),
        quantity: parseInt(doc.qty) || 1,
        unitCost: parseInt(doc.unit_cost),
      });
    }

    let res = await request({
      method: "POST",
      url: "purchase/inventory",
      data: purchase_inv,
    });

    if (res !== "error") {
      await setModalMessage("Purchase Inventory Added");
      await setShowSuccessModal(true);

      navigate(`/dashboard/orders/edit/${id}`);
    }
  }

  return (
    <div className="w-full mt-8">
      <TContext.Provider
        value={{
          showLive,
          limit,
          setLimit,
          data,
          page,
          hasNextPage,
          hasPrevPage,
          handleNext,
          handlePrev,
          handleHeaderQuery,
          handleFilterQuery,
          total: "...",
          setIsFiltered,
          showPagination,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <SearchInput2
              placeholder="Search"
              input={searchInput}
              setInput={setSearchInput}
            />
            {isFiltered ? (
              <BtnRounded click={handleClearFilters}>Clear</BtnRounded>
            ) : null}
          </div>
          <THead />
          <TBody />
          <TInfo />
          <div className="w-full flex justify-end">
            <BtnPlain bg="primary" textColor="white" click={handleSubmit}>
              Complete
            </BtnPlain>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default InvTable;
