import React, { useContext } from "react";

import AllContext from "../../Context";

import TRow from "./TRow";

const TBody = () => {
  const { data } = useContext(AllContext);
  
  return (
    <div className="w-full ">
      {data.map((doc) => (
        <TRow doc={doc} key={doc._id || doc.id} data={data} />
      ))}
    </div>
  );
};

export default TBody;
