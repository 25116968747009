import React, { useContext } from "react";

import AllFixedContext from "../Context/FixedContext";

const useAllInventory = () => {
  const {
    limit,
    setLimit,
    data,
    showLive,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    isFiltered,
    handleClearFilters,
    total,
    setIsFiltered,
    showPagination,
    setFixed,
    resetQuery,
  } = useContext(AllFixedContext);

  return {
    limit,
    setLimit,
    data,
    showLive,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    isFiltered,
    handleClearFilters,
    total,
    setIsFiltered,
    showPagination,
    setFixed,
    resetQuery,
  };
};

export default useAllInventory;
