import React, { useRef, useState } from "react";
import TextArea from "../../../../../../../Components/Inputs/TextArea";
import TextInput from "../../../../../../../Components/Inputs/TextInput";

import NumberInput from "../../../../../../../Components/Inputs/NumberInput";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import useAddVendor from "../hooks/useAddVendor";

import useAxios from "./../../../../../../../Hooks/useAxios";

const VendorDetails = ({ setStep }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { vendor, setVendor } = useAddVendor();

  const request = useAxios();

  const handleSubmit = async () => {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "vendor/validate",
      data: { vendor: input.current.form },
    });

    await setVendor({ ...vendor, ...input.current.form });

    if (res === "error") {
      return;
    }

    setStep((c) => c + 1);
  };

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "70%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        {/* Left */}
        <div className="flex w-full md:w-1/2 flex-col ">
          <TextInput
            initial={vendor.name || ""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. John Doe",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={vendor.email || ""}
            input={input}
            showError={showError}
            inputKey="email"
            mt={0.5}
            config={{
              required: true,
              label: "Email",
              placeholder: "Eg. johndoe@gmail.com",
              inputType: "email",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <NumberInput
            initial={vendor.phone || ""}
            input={input}
            showError={showError}
            inputKey="phone"
            mt={0.5}
            config={{
              required: true,
              label: "Phone",
              placeholder: "Enter phone number ",
              maxChar: 13,
              minChar: 6,
            }}
          />

          <TextInput
            initial={vendor.traPin || ""}
            input={input}
            showError={showError}
            inputKey="traPin"
            mt={0.5}
            config={{
              required: true,
              label: "Tra Pin",
              placeholder: "as254564312323",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>

        <div className="flex w-full md:w-1/2 flex-col ">
          <TextArea
            initial={vendor.bio || ""}
            input={input}
            showError={showError}
            inputKey="bio"
            mt={0.5}
            config={{
              required: false,
              label: "Bio",
              placeholder: "Eg. dealer in pharamacutical equipments",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Continue</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  );
};

export default VendorDetails;
