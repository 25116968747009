import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAxios from "../../../../../../Hooks/useAxios";

import BellTopBar from "../../../../../../Components/Bars/BellTopBar.jsx";

import TableTopBar from "../../../../../../Components/FinalTable/TableTopBar";

import AllVendorTable from "./components/AllVendorTable";

import Fuse from "fuse.js";

import VendorAll from "./context/VendorsContext";

const LiveData = ({ showLive, setShowLive }) => {
  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(5);

  const [data, setData] = useState([]);

  const [showPagination, setShowPagination] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  const [page, setPage] = useState(1);

  const [sortOrder, setSortOrder] = useState("desc");

  const [sortField, setSortField] = useState("created_at");

  const [filters, setFilters] = useState({});

  const [hasNextPage, setHasNextPage] = useState(false);

  const [hasPrevPage, setHasPrevPage] = useState(false);

  const [tableIsLoading, setTableIsLoading] = useState(false);

  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();

  const navigate = useNavigate();

  const getInitVendors = async (
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) => {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "vendor",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTotal(res.total_docs);
  };

  useEffect(() => {
    getInitVendors(1, "created_at", "desc", {});
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "vendor/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse([...res.name, ...res.tra_pin], {
        keys: ["name", "tra_pin", "email", "phone"],
      });

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
      setTotal(res.length);
    }

    search();
  }, [searchInput]);

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitVendors(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitVendors(page - 1, sortField, sortOrder);
    }
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitVendors(1, field, order);
  }

  async function handleClearFilters() {
    await getInitVendors(1, "created_at", "desc");
    setPage(1);
    setSearchInput("");
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  function handleAdd() {
    navigate("/dashboard/vendors/add");
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitVendors(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  return (
    <VendorAll.Provider
      value={{
        showLive,
        setShowLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-full bg-ghost_white ">
        <BellTopBar label="Vendors" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            mt={2}
            mb={2}
            changes={0}
            showAdd={true}
            AddClick={handleAdd}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            addLabel="Add"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllVendorTable />
        </div>
      </div>
    </VendorAll.Provider>
  );
};

export default LiveData;
