import React from "react";
import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import Badge from "../../../../../../../Components/Badges/Badge";

import TableActions from "./TableActions";

const TableBody = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
        <TableText label={`sn: ${doc.sn}`} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.fixed_assets_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`${doc.unit_cost} TZS`} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`store: ${doc.store_qty} `} />
        <TableText label={`total: ${doc.total_qty} `} />
      </TableCell>
      <TableCell w={90 / 6}>
        <Badge
          label={doc.is_purchased ? "Purchased" : "Po"}
          bg={doc.is_purchased ? "pale_spring_bud" : "pale_pink"}
        />
        <div style={{ height: "5px" }} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`${doc.shelf_no || "Not Set"}`} />
      </TableCell>
      <TableActions doc={doc} />
    </TableRow>
  );
};

export default TableBody;
