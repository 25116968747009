import { FaUsers } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import Tooltip from "../../ToolTip";

import { useLocation, useNavigate } from "react-router-dom";

const Vendors = ({ open = false, active = false }) => {
  const id = "vendor-tooltip";
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Vendors"}
        open={open}
        active={location.pathname.includes("dashboard/vendors")}
        click={() => navigate("vendors")}
      >
        <FaUsers />
      </BtnTemplate>
      <Tooltip id={id} txt1="All your vendors" txt2="in one spot">
        <FaUsers />
      </Tooltip>
    </div>
  );
};

export default Vendors;
