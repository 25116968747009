import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import Badge from "../../../../../../../Components/Badges/Badge";

import TableActions from "./TableActions";

import useAllInventory from "../Hooks/useAllInventory";

import Trow from "./Trow";

const TableBody = () => {
  const { data } = useAllInventory();

  return (
    <>
      {data.map((doc) => (
        <Trow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  );
};

export default TableBody;
