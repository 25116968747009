import React, { useState, useEffect, useRef } from "react";

import { colors } from "../../Util/colors";

const DateSelector = ({
  input,
  inputKey,
  initial,
  showError = 0,
  mt = 0,
  mb = 0,
  config = {
    required: true,
    disabled: true,
    label: "Select date",
    min: null,
    max: null,
  },
}) => {
  const [dateInput, setDateInput] = useState(initial);
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [err, setErr] = useState(false);
  const [errMessage, setErrorMessage] = useState("Field Required");

  const picker = useRef();

  useEffect(() => {
    if (showError === 0) {
      return;
    }
    handleBlur();
  }, [showError]);

  useEffect(() => {
    setDateInput(initial);
    input.current.form[inputKey] = initial;
  }, []);

  function handleChange(date) {
    setDateInput(date);
    input.current.err[inputKey] = true;
    input.current.form[inputKey] = date;
  }

  function handleBlur() {
    if (config.required === false) {
      return;
    }

    if (dateInput === "") {
      setBorderColor("red");

      input.current.err[inputKey] = true;
      setErr(true);
      setErrorMessage(`* Date is required`);
      return;
    }
    setBorderColor("bright_grey");
    setErr(false);
    input.current.err[inputKey] = false;
    setErrorMessage(`* Date required`);
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className=" font-DM-Reg text-base mt-5">{config.label}</label>
      <div className="w-full">
        <input
          className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey mb-5  `}
          onClick={(e) => (config.disabled ? null : e.target.showPicker())}
          style={{
            borderColor: colors[borderColor],
            cursor: config.disabled ? "not-allowed" : "text",
          }}
          ref={picker}
          disabled={config.disabled}
          type={"date"}
          value={dateInput}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e.target.value)}
          min={config.min}
          max={config.max}
        ></input>
      </div>

      <small
        className="text-red font-DM-Reg"
        style={{ opacity: err ? "100%" : "0%" }}
      >
        {errMessage}
      </small>
    </div>
  );
};

export default DateSelector;
