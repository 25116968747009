import ContactImg from "../../../../../../../Assets/Images/avatarAsset .png";

const Contact = ({ contactPerson }) => {
  const { name, email, phone } = contactPerson;
  return (
    <div
      className=" bg-white rounded-md shadow-lg mt-5 p-5 ml-5 flex justify-between"
      style={{ width: 428, height: 161 }}
    >
      <div>
        <p className=" font-DM-Bold"> CONTACT PERSON</p>
        <img
          src={ContactImg}
          alt=" Contact person avatar"
          style={{ width: 72, height: 87 }}
        />
      </div>

      <div className=" flex justify-between flex-col">
        <div>
          <p className=" text-[#98A1B5] font-DM-Bold text-sm">NAME</p>
          <p className=" text-sm mb-2 font-DM-Reg">{name}</p>
        </div>

        <div>
          <p className=" text-[#98A1B5] font-DM-Bold text-sm">EMAIL</p>
          <p className=" text-sm mb-2 font-DM-Reg">{email}</p>
        </div>

        <div>
          <p className=" text-[#98A1B5] font-DM-Bold text-sm">PHONE NUMBER</p>
          <p className=" text-sm mb-2 font-DM-Reg">{phone}</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
