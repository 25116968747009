import React from "react";
import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import Badge from "../../../../../../../Components/Badges/Badge";

import TableActions from "./TableActions";

import { convDate } from "../../../../../../../Util/Time";

const TableBody = ({ doc }) => {

  return (
    <TableRow key={doc._id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.inventory_item_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`${doc.unit_cost} TZS`} />
        <TableText label={`per: ${doc.unit_of_measures.name}`} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`Store: ${doc.store_qty}`} />
        <TableText label={`Total: ${doc.total_qty}`} />
      </TableCell>
      <TableCell w={90 / 6}>
        <Badge
          label={doc.is_purchased ? "Purchased" : "Po"}
          bg={doc.is_purchased ? "pale_spring_bud" : "pale_pink"}
        />
        <div style={{ height: "5px" }} />
        <Badge
          label={`moq: ${doc.moq}`}
          bg={doc.moq <= doc.qty ? "pale_spring_bud" : "pale_pink"}
        />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={`Shelf: ${doc.shelf_no || "Set"}`} />
        <Badge
          label={
            doc.is_expired
              ? "Exp: Set"
              : `Exp: ${
                  doc.expiry_date ? convDate(parseInt(doc.expiry_date)) : "Set"
                }`
          }
          bg={doc.is_expired === false ? "pale_spring_bud" : "pale_pink"}
        />
      </TableCell>
      <TableActions doc={doc} />
    </TableRow>
  );
};

export default TableBody;
