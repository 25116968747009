import React, { useState, useContext, useEffect } from "react";

import SearchInput2 from "../../../../../../../../Components/Inputs/SearchInput";

import usePouchPagination from "../../../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../../../Hooks/useFuzzySearch";

import { FaLongArrowAltRight } from "react-icons/fa";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import TeamContext from "./TeamContext";

import TableHead from "./THead";

import TBody from "./TBody";

import TableInfoSection from "./TableInfoSection";

import useApp from "../../../../../../../../Hooks/useApp";

import AllocateContext from "../../AllocateContex";
import Fuse from "fuse.js";
import useAxios from "../../../../../../../../Hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { TableTopBar } from "../../../../../../../../Components/FinalTable";

const LiveData = ({ showLive, setShowLive }) => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();
  const { setShowFormModal, setModalMessage } = useApp();
  const [docs, setDocs] = useState(0);
  const navigate = useNavigate();

  const { setStep, setAllocate, allocate } = useContext(AllocateContext);


  useEffect(() => {
    getInitVisitLogs(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "team/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse(
        [...res.names, ...res.emails],
        {
          keys: ["name", "email"],
        }
      );

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitVisitLogs(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "team",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitVisitLogs(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitVisitLogs(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitVisitLogs(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitVisitLogs(1, field, order);
  }

  async function handleClearFilters() {
    await getInitVisitLogs(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }


  async function handleSubmit() {
    if (checked_id === -1) {
      setModalMessage("Select user to continue");
      setShowFormModal(true);
      return;
    }

    setAllocate({ ...allocate, to: checked_id });

    setStep((c) => c + 1);
  }

  return (
    <TeamContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
       <div className="h-auto">
       <TableTopBar
            
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={false}
            AddClick={() => navigate("add")}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        <TableHead />
      <TBody checked_id={checked_id} setCheckedId={setCheckedId} />
      <TableInfoSection />
      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
        <span className="px-3 font-normal text-2xl">
          <FaLongArrowAltRight />
        </span>
      </BtnRounded>
       </div>
    </TeamContext.Provider>
  );
};

export default LiveData;
