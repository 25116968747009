import React, { useState, useEffect } from "react";

import { colors } from "./../../../../../../../../Util/colors";

const NumberInput = ({
  input = "",
  setInput = () => {},
  doc,
  changedDocs,
  setChangedDocs,
  input_key,
  checkedDocs = [],
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");

  useEffect(() => {
    if (checkedDocs.length === 0) return;

    if (checkedDocs.includes(doc.id)) {
      if (changedDocs[doc.id]) {
        let allDocs = { ...changedDocs };
        let newDoc = changedDocs[doc.id];
        allDocs[doc.id] = newDoc;
        setChangedDocs(allDocs);
        return;
      }

      let allDocs = { ...changedDocs };
      let newDoc = { ...doc };
      allDocs[doc.id] = newDoc;
      setChangedDocs(allDocs);
      return;
    }
  }, [checkedDocs]);

  function handleChange(txt) {
    setInput(txt);
    if (changedDocs[doc.id]) {
      let allDocs = { ...changedDocs };
      let newDoc = changedDocs[doc.id];
      newDoc[input_key] = txt;
      allDocs[doc.id] = newDoc;
      setChangedDocs(allDocs);
      return;
    }
    let allDocs = { ...changedDocs };
    let newDoc = { ...doc };
    newDoc[input_key] = txt;
    allDocs[doc.id] = newDoc;
    setChangedDocs(allDocs);
  }

  return (
    <input
      className=" border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey"
      onFocus={() => setBorderColor("primary")}
      style={{
        borderColor: colors[borderColor],
      }}
      type={"number"}
      value={input}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default NumberInput;
