import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import Badge from "../../../../../../../Components/Badges/Badge";

import TableActions from "./TableActions";

import useAllVendors from "../hooks/useAllVendors";

const TableBody = () => {
  const { data } = useAllVendors();
  return (
    <>
      {data.map((doc) => (
        <TableRow key={doc.id}>
          <TableCell w={90 / 5}>
            <TableText label={doc.name} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={doc.email} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={doc.phone} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={doc.tra_pin} />
          </TableCell>
          <TableCell w={90 / 5}>
            <Badge
              label={doc.is_active ? "Active" : "Inactive"}
              bg={doc.is_active ? "pale_spring_bud" : "pale_pink"}
            />
          </TableCell>
          <div style={{ width: "10%" }}>
            <TableActions doc={doc} />
          </div>
        </TableRow>
      ))}
    </>
  );
};

export default TableBody;
