import { RiDeleteBinLine } from "react-icons/ri"
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain"
import { 
  ActionBtn, 
  Table, 
  TableCell, 
  TableHeaderRow, 
  TableHorizontalActionBtn, 
  TableRow, 
  TableText 
} from "../../../../../../../Components/FinalTable"
import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort"
import dummyData from "./dummyData"
import Badge from "../../../../../../../Components/Badges/Badge"

const OrderItems = ({ data = dummyData}) => {

  return (
    <>
    <Table>
      <TableHeaderRow bg=" bg-[#F9FAFB]">
        <HeaderBtnSort w={100 / 5} label="ITEM NAME" />
        <HeaderBtnSort w={100 / 5} label="CATEGORY" />
        <HeaderBtnSort w={100 / 5} label="ITEM TYPE" />
        <HeaderBtnSort w={100 / 5} label="QUANTITY" />
        <HeaderBtnSort w={100 / 5} label="UNIT COST" />
      </TableHeaderRow>
      {data.map((doc, i) => (
        <TableRow mt={10} key={i}>
          <TableCell w={100 / 5}>
            <TableText label={doc.name} />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.category} />
          </TableCell>
          <TableCell w={100 / 5}>
            <Badge label={doc.type} bg="white" />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.quantity} />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.unitCost} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
    <div className="w-full flex pt-9 justify-end">
     <h1 className="font-DM-Bold text-2xl text-black">Cumulative Amount: 254789</h1>
    </div>
    </>
  )
}

export default OrderItems