import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    month: "JAN",
    expenses: 230,
  },
  {
    month: "FEB",
    expenses: 280,
  },
  {
    month: "MAR",
    expenses: 150,
  },
  {
    month: "APR",
    expenses: 210,
  },
  {
    month: "MAY",
    expenses: 230,
  },
  {
    month: "JUN",
    expenses: 180,
  },
  {
    month: "JUL",
    expenses: 290,
  },
  {
    month: "AUG",
    expenses: 240,
  },
  {
    month: "SEP",
    expenses: 200,
  },
  {
    month: "OCT",
    expenses: 60,
  },
  {
    month: "NOV",
    expenses: 120,
  },
  {
    month: "DEC",
    expenses: 300,
  },
];

export default function ExpensesChart({expenses}) {
  function getMonthName(month) {
    const date = new Date(2023, parseInt(month) - 1);
    return date.toLocaleString('default', { month: 'short' });
  }

  const monthData = [];

// Initialize month data objects with zero patient visits
for (let i = 0; i < 12; i++) {
  monthData.push({ month: getMonthName(i + 1), sum: 0 });
}

// Fill in month data with patient data
expenses.forEach(visit => {
  const monthName = getMonthName(visit.month);
  const monthIndex = monthData.findIndex(month => month.month === monthName);
  if (monthIndex !== -1) {
    monthData[monthIndex].sum = visit.sum;
  }
});


  return (
    <div className=" rounded-xl h-96 px-6 py-3 bg-white">
      <div className="flex justify-between pb-14">
        <h1 className="font-bold text-base  ">Expenses per month</h1>
      </div>
      <div className="w-full h-72">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={750}
            height={250}
            data={monthData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="month" padding={{ bottom: 20 }} axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip />
            <Bar dataKey="sum" fill="#a4a9e1" barSize={15} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

