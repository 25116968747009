import React, { useRef, useState } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";
import useAddVendor from "../hooks/useAddVendor";
import useAxios from "../../../../../../../Hooks/useAxios";

const VendorAddress = ({ setStep }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { address, setAddress } = useAddVendor();

  const request = useAxios();

  const handleSubmit = async () => {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let keys = Object.keys(address);
    let is_changed = false;

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (input.current.form[key] !== address[key]) {
        is_changed = true;
        break;
      }
    }

    if (is_changed === false) {
      setStep((c) => c + 1);
      return;
    }

    let res = await request({
      method: "PUT",
      url: "vendor",
      data: { address: { ...input.current.form, id: address.id } },
    });

    await setAddress({ ...address, ...input.current.form });

    if (res === "error") {
      return;
    }

    setStep((c) => c + 1);
  };

  return (
    <div
      className="flex flex-col w-full max-w-6xl mx-auto bg-white rounded-2xl p-10"
      style={{ width: "70%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        {/* Left */}
        <div className="flex w-full md:w-1/2 flex-col ">
          <TextInput
            initial={address.addressOne || ""}
            input={input}
            showError={showError}
            inputKey="addressOne"
            mt={0.5}
            config={{
              required: true,
              label: "Address 1",
              placeholder: "Enter address",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={address.addressTwo || ""}
            input={input}
            showError={showError}
            inputKey="addressTwo"
            mt={0.5}
            config={{
              required: false,
              label: "Address 2",
              placeholder: "Enter address",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={address.addressThree || ""}
            input={input}
            showError={showError}
            inputKey="addressThree"
            mt={0.5}
            config={{
              required: false,
              label: "Address 3",
              placeholder: "Enter address",
              type: "phone",
              maxChar: 250,
              minChar: 9,
            }}
          />

          <TextInput
            initial={address.city || ""}
            input={input}
            showError={showError}
            inputKey="city"
            mt={0.5}
            config={{
              required: true,
              label: "City",
              placeholder: "Enter city",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>

        {/* Right */}
        <div className="flex w-full md:w-1/2 flex-col ">
          <TextInput
            initial={address.country || ""}
            input={input}
            showError={showError}
            inputKey="country"
            mt={0.5}
            config={{
              required: true,
              label: "Country",
              placeholder: "Kenya",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={address.postalCode || ""}
            input={input}
            showError={showError}
            inputKey="postalCode"
            mt={0.5}
            config={{
              required: true,
              label: "Postal Code",
              type: "text",
              maxChar: 250,
              placeholder: "00400",
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Continue</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  );
};

export default VendorAddress;
