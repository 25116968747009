import { useContext, useEffect, useState } from "react";

import { Table } from "../../../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../../../Hooks/usePouchPagination";

import { TableTopBar } from "../../../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../../../Hooks/useFuzzySearch";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../../../../Hooks/useApp";

import AllocateContext from "../../AllocateContex";
import Fuse from "fuse.js";
import useAxios from "../../../../../../../../Hooks/useAxios";

const LiveData = ({ showLive, setShowLive }) => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();
  const { setShowFormModal, setModalMessage } = useApp();
  const [docs, setDocs] = useState(0);
  const navigate = useNavigate();

  const { setStep, setAllocate, allocate } = useContext(AllocateContext);


  useEffect(() => {
    getInitVisitLogs(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/spaces/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;
      let new_docs = [];

      for (let i = 0; i < res.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = res[i]
        new_docs.push(doc);
      }
      
      setData(new_docs);
      setLimit(5)
      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitVisitLogs(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/spaces",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitVisitLogs(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitVisitLogs(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitVisitLogs(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitVisitLogs(1, field, order);
  }

  async function handleClearFilters() {
    await getInitVisitLogs(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }


  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Space");
      setShowFormModal(true);
      return;
    }
    setAllocate({ ...allocate, to: checked_id });
    setStep((c) => c + 1);
  }

  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="px-7 pb-8">
        <TableTopBar
           mt={2}
           mb={2}
           addLabel="Add"
           showAdd={false}
           changes={0}
           handleLiveStream={() => {}}
           isFiltered={isFiltered}
           handleClearFilters={handleClearFilters}
           tableIsLoading={tableIsLoading}
           showReport={false}
           showFilter={false}
           searchInput={searchInput}
           setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Space</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default LiveData;
