import React, { useContext, useEffect, useState } from "react";

import {
  TableRow,
  TableCell,
  SingleCheckBox,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import usePouch from "../../../../../../../../../Hooks/usePouch";

import { BsCloudDownload } from "react-icons/bs";

import useDownloader from "react-use-downloader";

import { convDate } from "./../../../../../../../../../Util/Time";

import useAxios from "../../../../../../../../../Hooks/useAxios";
import TContext from "./TContext"
import useApp from "../../../../../../../../../Hooks/useApp";
import usePouchCud from "../../../../../../../Hooks/usePouchCud";

const TRow = ({ doc }) => {
  const { download } = useDownloader();
  const {data, setData} = useContext(TContext)
  const request = useAxios();
  const { deleteDoc } = usePouchCud();
  const { setShowSuccessModal, setModalMessage } = useApp();

  async function handleDownload(doc) {
    let res = await request({
      method: "POST",
      url: "purchase/documents/download",
      data: {
        name: doc.url,
      },
    });
    if (res === "error") return;
    
    download(res.link, doc.document_name);
  }

  async function handleDelete(doc) {
    let res = await request({
      method: "DELETE",
      url: `purchase/documents/${doc.id}`,
    });
    if (res !== "error"){
      setModalMessage("Document deleted!");
      setShowSuccessModal(true);
      await deleteDoc({ name: "purchase_documents", doc: res });
    }
    let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
   
  }


  return (
    <TableRow>
      <TableCell w={90 / 4}>
        <TableText label={doc.description} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.document_type} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell>
        <p className=" w-full flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer"
            onClick={() => handleDownload(doc)}
          >
            <BsCloudDownload />
          </span>
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => handleDelete(doc)}
          >
            <MdDelete />
          </span>
        </p>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
