import React, { useEffect, useState } from "react";
import chekingBoxes from "../../../../../../../Assets/Images/amico.png";
import "../styles.css";

import Badge from "../../../../../../../Components/Badges/Badge";

import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../../../../../Hooks/useApp";
import useAxios from "../../../../../../../Hooks/useAxios";

const InventoryItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIsLoading } = useApp();
  const request = useAxios()

  const [inv, setInv] = useState({});

  useEffect(() => {
    async function fetchAll() {
      try {
        setIsLoading(true);
        let inv = await request({
          method: "GET",
          url: `fixed/${id}`,
        });
        setInv(inv);
      } catch (e) {
        // navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    fetchAll();
  }, [id]);

  return (
    <div className="flex flex-col md:flex-row w-full inventory-item">
      <div className="flex-1 flex flex-col gap-9">
        <img
          className="max-w-[200px] max-h-[200px]"
          src={chekingBoxes}
          alt="Checking Boxes"
        />
        <div>
          <h2 className=" text-[#98A1B5]">NAME</h2>
          <p>{inv.name ? inv.name : '---'}</p>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start  w-full gap-9">
        <Badge click={() => navigate(`../edit/${id}`)} label="Edit" />
        <div>
          <h2>Depreciation Rate Pa</h2>
          <p>{inv.depreciation_rate_pa ? inv.depreciation_rate_pa : '---'}</p>
        </div>
        <div>
          <h2>Unit cost</h2>
          <p>Tsh {inv?.unit_cost ? inv.unit_cost : '---'}</p>
        </div>

        <div>
          <h2>Depreciation Years</h2>
          <p>{inv.depreciation_years ? inv.depreciation_years : '---'}</p>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start w-full gap-9">
        <Badge bg="primary" textColor="white" label={`${inv.fixed_assets_categories ? inv.fixed_assets_categories.name : '---'}`} />
        <div>
          <h2>shelf no.</h2>
          <p>{inv?.shelf_no || "Not Set"}</p>
        </div>
      </div>
    </div>
  );
};

export default InventoryItem;
