import React, { useRef, useState, useEffect } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";

import TextInput from "../../../../../../../Components/Inputs/TextInput";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import useAddVendor from "../hooks/useAddVendor";

import useAxios from "../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

const PaymentDetails = ({ setStep }) => {
  const input = useRef({
    form: { bankName: "", accountNo: null, paybillNo: null, buyGoodsNo: null },
    err: {},
  });

  const { setShowSuccessModal, setModalMessage } = useApp();

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { payment, setPayment } = useAddVendor();

  const request = useAxios();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    // setStep(prev=>prev+1)
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    let keys = Object.keys(payment);
    let is_changed = false;

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (input.current.form[key] !== payment[key]) {
        is_changed = true;
        break;
      }
    }

    if (is_changed === false) {
      return;
    }

    let paymentDetails = { id: payment.id };

    if (
      input.current.form.bankName !== "" &&
      input.current.form.bankName !== null
    ) {
      paymentDetails.bankName = input.current.form.bankName;
    }

    if (
      input.current.form.accountNo !== "" &&
      input.current.form.accountNo !== null
    ) {
      paymentDetails.accountNo = input.current.form.accountNo;
    }

    if (
      input.current.form.paybillNo !== "" &&
      input.current.form.paybillNo !== null
    ) {
      paymentDetails.paybillNo = input.current.form.paybillNo;
    }

    if (
      input.current.form.buyGoodsNo !== "" &&
      input.current.form.buyGoodsNo != null
    ) {
      paymentDetails.buyGoodsNo = input.current.form.buyGoodsNo;
    }

    let res = await request({
      method: "PUT",
      url: "vendor",
      data: {
        paymentDetails: paymentDetails,
      },
    });

    await setPayment({ ...input.current.form, ...payment });

    if (res !== "error") {
      setModalMessage("Vendor updated");
      setShowSuccessModal(true);
      navigate(-1);
    }
  };

  return (
    <form className="flex flex-col w-full max-w-xl mx-auto bg-white rounded-[15px] p-10 gap-y-4">
      <TextInput
        initial={payment.bankName || ""}
        input={input}
        showError={showError}
        inputKey="bankName"
        mt={0.5}
        config={{
          required: true,
          label: "Bank Name",
          placeholder: "Enter bank name",
          type: "text",
          maxChar: 50,
          minChar: 4,
        }}
      />

      <NumberInput
        initial={payment.accountNo || ""}
        input={input}
        showError={showError}
        inputKey="accountNo"
        mt={0.5}
        config={{
          required: false,
          label: "Account Number",
          placeholder: "Enter account number",
          min: 1000,
          max: 99999999999999,
        }}
      />

      <NumberInput
        initial={payment.paybillNo || ""}
        input={input}
        showError={showError}
        inputKey="paybillNo"
        mt={0.5}
        config={{
          required: false,
          label: "MPESA Paybill No.",
          placeholder: "Enter MPESA Paybill No.",
          min: 1000,
          max: 99999999,
        }}
      />

      <NumberInput
        initial={payment.buyGoodsNo || ""}
        input={input}
        showError={showError}
        inputKey="buyGoodsNo"
        mt={0.5}
        config={{
          required: false,
          label: "MPESA Buy Goods No.",
          placeholder: "Enter MPESA Buy Goods No",
          min: 1000,
          max: 99999999,
        }}
      />

      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Submit</span>
        <span className="px-3 font-normal text-2xl">
          <FaLongArrowAltRight />
        </span>
      </BtnRounded>
    </form>
  );
};

export default PaymentDetails;
