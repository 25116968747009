import React, { useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../../Util/Time";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";

const TRow = ({ doc }) => {

  const { checked_id, setCheckedId, setDocs } = useContext(TContext);

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.space_types.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.no} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.description} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
