import { useState } from "react";
import { FaCoins, FaPlusCircle, FaUserPlus, FaWpforms } from "react-icons/fa";
import { AiOutlineFileAdd } from "react-icons/ai"
import { CiViewList } from "react-icons/ci"
import Steppers from "../../../../../../Components/Steppers/Steppers";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SinglePurchaseOrder from "../SinglePurchaseOrder"
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import AddOrderItems from "./components/AddOrderItems";
import ChooseVendor from "./components/ChooseVendor";
import AddOrderDetails from "./components/AddOrderDetails";
import AddOrdersCharges from "./components/AddOrdersCharges";
import ViewPurchaseOrder from "../Add/components/ViewPurchaseOrder";

const AddDetails = () => {
  const [close, setClose] = useState(false);
  const [step, setStep] = useState(0);


  const renderItems = ()=>{
    return (
        <AddOrderItems
            setStep={setStep}
        />
    )
  }
  const renderVendors = ()=>{
    return (
        <ChooseVendor 
            setStep={setStep}
        />
    )
  }
  const renderOrderDetails = ()=>{
    return (
        <AddOrderDetails
            setStep={setStep}
        />
    )
  }
  const renderOrderCharges = ()=>{
    return (
        <AddOrdersCharges
            setStep={setStep}
        />
    )
  }
  const renderViewOrder = ()=>{
    return (
        <ViewPurchaseOrder
        />
    )

  }



  const options = [
    {
      step: 0,
      icon: <CiViewList />,
      title: "Add Items",
      data: renderItems(),
    },
    {
      step: 1,
      icon: <FaUserPlus />,
      title: "Choose Vendor",
      data: renderVendors(),
    },
    {
      step: 2,
      icon: <AiOutlineFileAdd />,
      title: "Add Order Details",
      data: renderOrderDetails(),
    },
    {
      step: 3,
      icon: <FaCoins />,
      title: "Add Order Charges",
      data: renderOrderCharges(),
    },
    {
      step: 4,
      icon: <FaWpforms />,
      title: "View Order",
      data: renderViewOrder(),
    },
  ];
  
  return (
    <div className="flex flex-col w-full  min-h-screen bg-bright_grey">
      <Header
        icon={<FaPlusCircle />}
        text={options.filter((option) => option.step === step)[0].title}
      />
      <Steppers currentStep={step} close={() => setClose(true)}>
        {options.map((option, index) => {
          return (
            <div
              className={`flex space-x-3 items-center ${
                index - 2 < step && " text-white"
              }`}
              key={index}
            >
              <span className="text-[1.1rem]">{option.icon}</span>
              <p className="uppercase text-[15px] py-3 px-4 font-pop-reg">
                {option.title}
              </p>
            </div>
          );
        })}
      </Steppers>
      <div className="mt-10 p-9">
        {options.filter((option) => option.step === step)[0] &&
          options.filter((option) => option.step === step)[0].data}
      </div>
      <Footer step={step} setStep={setStep} stepCount={options.length} />
    </div>
  )
}

export default AddDetails