import { useState } from "react";

import { FaUsers } from "react-icons/fa";

import FormScreen from "../../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import AddVendorContext from "./context/AddVendor";

import { useNavigate } from "react-router-dom";

const AddVendor = () => {
  const navigate= useNavigate();
  
  const [step, setStep] = useState(-1);

  const [vendor, setVendor] = useState({
    name: "",
    email: "",
    phone: null,
    traPin: "",
    bio: "",
  });

  const [contactPerson, setContactPerson] = useState({
    name: "",
    email: "",
    phone: null,
  });

  const [address, setAddress] = useState({
    city: "",
    country: "",
    addressOne: "",
    addressTwo: "",
    addressThree: "",
    postalCode: "",
  });

  const [payment, setPayment] = useState({
    bankName: "",
    accountNo: null,
    paybillNo: null,
    buyGoodsNo: null,
  });

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <AddVendorContext.Provider
      value={{
        vendor,
        setVendor,
        contactPerson,
        setContactPerson,
        address,
        setAddress,
        payment,
        setPayment,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Add Vendor" mt={2} mb={2}>
          <FaUsers />
        </FormLabel>
        <AddSteppers currentStep={step} close={() => {navigate("/dashboard/vendors")}} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddVendorContext.Provider>
  );
};

export default AddVendor;
