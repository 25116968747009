import React, { useRef, useState, useEffect } from "react";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import DatePicker from "../../../../../../../Components/Inputs/DatePicker";
import SelectInput from "../../../../../../../Components/Inputs/SelectInput";

import useAxios from "../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../Hooks/useApp";

import { useNavigate, useParams } from "react-router-dom";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import { convDate2, toTimeStamp } from "./../../../../../../../Util/Time";

const Form = () => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [showInv, setShowInv] = useState(false);

  const [inv, setInv] = useState({});

  const request = useAxios();

  const { setShowSuccessModal, setShowFormModal, setModalMessage } = useApp();

  const navigate = useNavigate();

  const formErrors = useHandleFormError();

  const { id } = useParams();

  useEffect(() => {
    async function init() {
      try {
        let inv = await request({
          method: "GET",
          url: `inventory/single/${id}`,
        });

        setInv(inv);

        //console.log(inv);

        let cats = await request({
          method: "GET",
          url: `inventory/enums`,
        });

        let c = [{ value: "", label: "Select Category" }];
        let m = [{ value: "", label: "Select Unit Of Measure" }];

        cats.categories.forEach((cat) =>
          c.push({ value: cat.id, label: cat.name })
        );
        cats.unit_of_measures.forEach((mess) =>
          m.push({ value: mess.id, label: mess.name })
        );

        setCategories(c);
        setMeasures(m);
        setShowInv(true);
      } catch (e) {
        setModalMessage("Error Editing inventory");
        setShowFormModal(true);
        navigate(-1);
      }
    }
    init();
  }, []);

  const handleSubmit = async () => {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let exp = null;

    //console.log(input);

    if (
      input.current.form.expiry_date != null ||
      input.current.form.expiry_date !== ""
    ) {
      exp = toTimeStamp(input.current.form.expiry_date);
    }

    let data = {
      id: parseInt(inv.id),
      name: input.current.form.name,
      category_id: parseInt(input.current.form.category_id),
      unit_of_measure_id: parseInt(input.current.form.unit_of_measure_id),
      unit_cost: parseInt(input.current.form.unit_cost),
      moq: parseInt(input.current.form.moq),
      shelf_no: input.current.form.shelf_no || null,
      expiry_date: exp,
    };

    // console.log(data);

    // return;
    let res = await request({
      method: "PUT",
      url: "inventory",
      data: data,
    });

    if (res !== "error") {
      setModalMessage("Inventory Item Edited");
      setShowSuccessModal(true);

      navigate(-1);
      setInv(res);
    }
  };

  if (showInv === false) {
    return;
  }

  return (
    <div style={{ width: "70%" }} className="bg-white rounded-[15px] p-10">
      <div className="flex gap-x-6 w-full    ">
        <div className=" flex-1">
          <TextInput
            initial={inv.name}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. Syringe",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          {/* <TextInput
            initial={inv.code}
            input={input}
            showError={showError}
            inputKey="code"
            mt={0.5}
            config={{
              required: true,
              label: "Item Code",
              placeholder: "md-hxbr23",
              type: "text",
              maxChar: 250,
              minChar: 2,
            }}
          /> */}
          <SelectInput
            initial={inv.category_id}
            input={input}
            inputKey="category_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: categories,
              label: "Category",
            }}
          />
          <SelectInput
            initial={inv.unit_of_measure_id}
            input={input}
            inputKey="unit_of_measure_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: measures,
              label: "Unit of Measure",
            }}
          />
        </div>
        <div className=" flex-1">
          <NumberInput
            initial={inv.unit_cost || ""}
            input={input}
            showError={showError}
            inputKey="unit_cost"
            mt={0.5}
            config={{
              required: true,
              label: "Unit Cost",
              placeholder: "Enter Unit Cost ",
            }}
          />
          <NumberInput
            initial={inv.moq || 1}
            input={input}
            showError={showError}
            inputKey="moq"
            mt={0.5}
            config={{
              required: true,
              label: "Minimuim Order Quantity",
              placeholder: "eg. 20 ",
            }}
          />
          <DatePicker
            initial={
              inv.expiry_date === null || inv.expiry_date === undefined
                ? ""
                : convDate2(parseInt(inv.expiry_date))
            }
            input={input}
            showError={showError}
            inputKey="expiry_date"
            mt={-1}
            mb={0}
            config={{
              disabled: false,
              label: "Expiry Date",
              min: convDate2(Date.now()),
            }}
          />

          <TextInput
            initial={inv.shelf_no || ""}
            input={input}
            showError={showError}
            inputKey="shelf_no"
            mt={0}
            config={{
              required: false,
              label: "Shelf Number",
              placeholder: "eg. 20 ",
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <BtnPlain
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={handleSubmit}
          className="btn"
        >
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnPlain>
      </div>
    </div>
  );
};

export default Form;
