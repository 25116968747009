import { useState, useEffect, useRef } from "react";
import FilterModal from "../../../../../../../Components/Modals/FilterModal";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import CheckBox from "../../../../../../../Components/Inputs/CheckBox";
import DatePicker from "../../../../../../../Components/Inputs/DatePicker";

import useAxios from "../../../../../../../Hooks/useAxios";

import usePouch from "../../../../../../../Hooks/usePouch";

import useAllInventory from "../Hooks/useFixed";

import useApp from "../../../../../../../Hooks/useApp";

const AllItemsFilter = ({ open, setOpen }) => {
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState([]);

  const request = useAxios();

  const { findAll } = usePouch();

  const { setIsFiltered, handleFilterQuery } = useAllInventory();

  const input = useRef( {
    form: {
      min_qty: "",
      max_qty: "",
      max_price: "",
      min_price: "",
    },
    err: {},
  });

  // const { setIsLoading, setModalMessage, setShowFormModal } = useApp();

  // useEffect(() => {
  //   async function fetchEnums() {
  //     try {
  //       setIsLoading(true);

  //       let categories = await findAll({
  //         db: "fixed_assets_categories",
  //         index: ["_id"],
  //         selector: {},
  //         sort: [{ _id: "asc" }],
  //       });

  //       setCategories(categories);
  //       setMeasures(measures);
  //     } catch (e) {
  //       setModalMessage("Filter Fail. Contact Admin");
  //       setShowFormModal(true);
  //       setOpen(false);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  //   fetchEnums();
  // }, []);

  async function applyFilters() {

    let filters = {}
    let to_filter = false


    if (input.current.form.min_qty !== "") {
      filters.filter_min_quantity =  parseInt(input.current.form.min_qty)
      to_filter = true
      };
   
    if (input.current.form.max_qty !== "") {
      filters.filter_max_quantity =  parseInt(input.current.form.max_qty)
      to_filter = true
    };

    if (input.current.form.min_price !== "") {
      filters.filter_min_price =  parseInt(input.current.form.min_price)
      to_filter = true
    };

    if (input.current.form.max_price !== "") {
      filters.filter_max_price =  parseInt(input.current.form.max_price)
      to_filter = true
    };

    if (to_filter) {
      handleFilterQuery(filters);
      setOpen(false); 
      return;
    }

    setOpen(true); 

    
  }
    if (!setOpen) return null;

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters}>
      <div style={{ width: "320px" }}>
        <div className="flex flex-col  gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Qty Filter
          </h3>
          <div className=" flex gap-x-2" style={{}}>
            <div className="flex-1 ">
              <NumberInput
                input={input}
                inputKey="max_qty"
                initial={""}
                config={{
                  required: false,
                  label: "Max Qty",
                  min: 0,
                }}
              />
            </div>
            <div className=" flex-1">
              <NumberInput
                input={input}
                inputKey="min_qty"
                initial={""}
                config={{
                  required: false,
                  label: "Min Qty",
                  min: 0,
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col  gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Price Filter
          </h3>
          <div className=" flex gap-x-2" style={{}}>
            <div className="flex-1 ">
              <NumberInput
                input={input}
                inputKey="max_price"
                initial={""}
                config={{
                  required: false,
                  label: "Max Price",
                  min: 0,
                }}
              />
            </div>
            <div className=" flex-1">
              <NumberInput
                input={input}
                inputKey="min_price"
                initial={""}
                config={{
                  required: false,
                  label: "Min Price",
                  min: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </FilterModal>
  );
};

export default AllItemsFilter;
