import { HiOutlinePencil } from "react-icons/hi";

import { FaUserSlash, FaUser } from "react-icons/fa";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import useAxios from "../../../../../../../Hooks/useAxios";

import { useNavigate } from "react-router-dom";

const ActionList = ({ vendor }) => {
  const request = useAxios();
  const navigate = useNavigate();

  async function handleVendorStatus() {
    let res = await request({
      method: "PUT",
      url: "vendor/status",
      data: {
        vendorId: vendor.id,
        isActive: !vendor.is_active,
      },
    });

    if (res === "error") {
      return;
    }

    vendor.is_active = !vendor.is_active;
  }

  return (
    <div className=" gap-y-2 w-fit flex flex-col absolute top-10 right-2  text-sm bg-white rounded-2xl p-3 shadow-xl">
      <BtnPlain
        activeBgColor="white"
        bg="white"
        click={() => navigate(`../edit/${vendor.id}`)}
      >
        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
          <p className="  text-black">
            <HiOutlinePencil />
          </p>
          <p className=" ">Edit Vendor</p>
        </div>
      </BtnPlain>
      <BtnPlain activeBgColor="white" bg="white" click={handleVendorStatus}>
        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
          <p className="text-red">
            {vendor.is_active ? <FaUserSlash /> : <FaUser />}
          </p>
          <p className="text-red ">
            {vendor.is_active ? "deactivate" : "activate"} Vendor
          </p>
        </div>
      </BtnPlain>
    </div>
  );
};

export default ActionList;
