import { useState, useEffect } from "react";
import BellTopBar from "../../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../../Components/FinalTable";
import AddOrderModal from "./components/Modals/AddOrderModal";
import PurchaseOrderFilters from "./components/Modals/PurchaseOrderFilters";
import useAxios from "../../../../../../Hooks/useAxios";
import AllContext from "./Context";
import Table from "./components/Table";

const LiveData = ({ showLive, setShowLive }) => {
  const [addModal, setShowAddModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [page, setPage] = useState(1);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [sortValue, setSortValue] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [tabsData, setTabsData] = useState({})
  const [searchInput, setSearchInput] = useState("");

  const [filters, setFilters] = useState({});
  
  const request = useAxios();

  useEffect(() => {
    getRequests(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "purchase/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);
  

  async function getRequests(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);

    let res = await request({
      method: "GET",
      url: "purchase/all",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });


    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setTabsData({
      
      all : res.total_docs,
      
      approved : res.total_approved,
      
      canceled : res.total_cancelled,
      
      pending : res.total_pending,

      received : res.total_received,

    })

    setData(res.docs);
    
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getRequests(page + 1, sortField, sortValue);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getRequests(page - 1, sortField, sortValue);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortValue("desc");
    getRequests(1, "created_at", "desc", filters);
    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortValue(order);
    setSortField(field);
    getRequests(1, field, order);
  }


  async function handleClearFilters() {
    await getRequests(1, "created_at", 'desc', {})
    setPage(1);
    setSortField("created_at");
    setSortValue("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        setShowLive,
        changes:0,
        limit,
        setLimit,
        data,
        setData,
        tabsData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-screen bg-ghost_white">
        <div className="w-full ">
          <BellTopBar label="Orders" />
        </div>
        <div className="w-full px-7 pb-8 mt-8">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => setShowAddModal(true)}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <Table />
        </div>
        <AddOrderModal showModal={addModal} setShowModal={setShowAddModal} />
        <PurchaseOrderFilters
          showFilter={openFilter}
          setShowFilter={setOpenFilter}
        />
      </div>
    </AllContext.Provider>
  );
};

export default LiveData;
