import React from 'react'

import { Table } from '../../../../../Components/FinalTable'

import TableHeader from './TableHeader'

import TBody from './TBody'

import TableInfoSection from './TableInfoSection'

const AllRequestsTable = () => {
  return (
    <Table>
      <TableHeader />
      <TBody />
      <TableInfoSection />
    </Table>
  )
}

export default AllRequestsTable