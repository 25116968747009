import React,{useContext, useRef} from "react";
import NumberInput from "../../../../../../../../Components/Inputs/NumberInput";
import DateRangePicker from "../../../../../../../../Components/Inputs/DateRangePicker";
import FilterModal from "../../../../../../../../Components/Modals/FilterModal";
import AllContext from "../../Context";
import { convDate2, toTimeStamp } from "../../../../../../../../Util/Time";

const PurchaseOrderFilters = ({ showFilter, setShowFilter }) => {
  const { handleFilterQuery } = useContext(AllContext);

  const input= useRef({
    form:{
      mintotal:"",
      maxtotal: "",
      minRec: "",
      maxRec: "",
      minOrd: "",
      maxOrd: ""
    },
    err:{}
  })


  function applyFilter () {

    let filters = {};
    let to_filter = false


    if (input.current.form.mintotal !== "") {
      filters.filter_min = parseInt(input.current.form.mintotal);
      to_filter = true;
    }

    if (input.current.form.maxtotal !== "") {
      filters.filter_max = parseInt(input.current.form.maxtotal);
      to_filter = true;
    }

    if (input.current.form.minRec !== "") {
        filters.filter_start_receiving_date = toTimeStamp((input.current.form.minRec))
        to_filter = true;
    }

    if (input.current.form.maxRec !== "") {
      filters.filter_end_receiving_date = toTimeStamp((input.current.form.maxRec))
      to_filter = true;
    }

    if (input.current.form.minOrd !== "") {
      filters.filter_start_order_date = toTimeStamp((input.current.form.minOrd))
      to_filter = true;
    }

    if (input.current.form.maxOrd !== "") {
      filters.filter_end_order_date = toTimeStamp((input.current.form.maxOrd))
      to_filter = true;
    }
    
    if (to_filter) {
      handleFilterQuery(filters);
      setShowFilter(false);
      return;
    }
   
  }

  if(!showFilter){
    input.current.form.mintotal = 0;
    input.current.form.maxtotal = 0;
    input.current.form.minRec = "";
    input.current.form.maxRec = "";
    input.current.form.minOrd = "";
    input.current.form.maxOrd = "";
    return null
  }

  return (
    <FilterModal 
      showFilter={showFilter} 
      setShowFilter={setShowFilter}
      apply = {applyFilter}
      >
        <div className="w-fit">
            <div className="flex flex-col">
            <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              total
            </h3>
            <div className="flex justify-between font-pop-reg text-[13px] text-auro_metal_saurus  gap-x-4">
              <NumberInput 
                initial={0}
                input={input}
                inputKey="mintotal"
                config={{
                  required: false,
                  min: 0,
                  label: "MIN"
                }}

              />
              <span className="flex items-center font-bold text-xl">-</span>
              <NumberInput 
                initial={0}
                input={input}
                inputKey="maxtotal"
                config={{
                  required: false,
                  min: 0,
                  label: "MAX"
                }}

              />
              
            </div>
          </div>
          
          <div className="flex flex-col">
            <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              Order date
            </h3>
            <DateRangePicker
              initial={""}
              input={input}
              inputKey1="minOrd"
              inputKey2="maxOrd"
              mt={0}
              mb={0}
              config={{
                required: false,
                disabled: false,
                max: convDate2(Date.now()),
                label: "",
              }}
            />
          
          </div>

          <div className="flex flex-col">
            <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              receiving date
            </h3>
            <DateRangePicker
              initial={""}
              input={input}
              inputKey1="minRec"
              inputKey2="maxRec"
              mt={0}
              mb={0}
              config={{
                required: false,
                disabled: false,
                max: convDate2(Date.now()),
                label: "",
              }}
            />
          
          </div>
        </div>
      

    </FilterModal>
  );
};

export default PurchaseOrderFilters;
