import React, { useState } from "react";

import { Table } from "../../../../../../../../Components/FinalTable";

import TableHeader from "./TableHeader";

import TableBody from "./TableBody";

import TableInfoSection from "./TableInfoSection";

import BtnPlain from "../../../../../../../../Components/Buttons/BtnPlain";

import AddModal from "../AddModal";

const VendorDocsTable = ({ documents, setDocuments }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Table>
      <div className=" flex justify-end w-full mb-2">
        <BtnPlain
          bg="primary"
          textColor="white"
          click={() => setShowModal(true)}
        >
          + Document
        </BtnPlain>
      </div>
      {documents.length === 0 ? null : (
        <>
          <TableHeader />
          <TableBody documents={documents} setDocument={setDocuments} />
        </>
      )}
      <AddModal
        showModal={showModal}
        setShowModal={setShowModal}
        documents={documents}
        setDocuments={setDocuments}
      />
    </Table>
  );
};

export default VendorDocsTable;
