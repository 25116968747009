import React, { useState, useEffect } from "react";
import AddressCard from "./Components/AddressCard";
import Contact from "./Components/ContactCard";

import PaymentDetails from "./Components/Payment";

import PersonalDetails from "./Components/PersonalDetails";

import BellTopBar from "../../../../../../Components/Bars/BellTopBar.jsx";

import { useParams, useNavigate } from "react-router-dom";

import useAxios from "../../../../../../Hooks/useAxios";

import AllDocTable from "./Components/VendorDocs/Table";

const LiveData = () => {
  const { id } = useParams();

  const [vendor, setVendor] = useState({});

  const [address, setAddress] = useState({});

  const [contactPerson, setContactPerson] = useState({});

  const [paymentDetails, setPaymentDetails] = useState({});

  const [documents, setDocuments] = useState([]);

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    const getVendor = async () => {
      let res = await request({
        method: "GET",
        url: `vendor/${id}`,
      });

      if (res === "error") {
        navigate("../");
      }

      setVendor(res);
      setAddress(res.vendor_address);
      setContactPerson(res.vendor_contact_person);
      setPaymentDetails(res.vendor_payment_details);
      setDocuments(res.vendor_documents);
    };

    getVendor();
  }, []);

  return (
    <div className=" bg-ghost_white  w-full h-full overflow-y-auto ">
      <BellTopBar label={`Vendor/ ${vendor.name}`} />

      <div className=" w-full flex justify-center">
        <PersonalDetails vendor={vendor} />
      </div>
      <div className=" flex justify-center items-center w-full mb-10 ">
        <AddressCard address={address} />
        <div className="flex flex-col">
          <Contact contactPerson={contactPerson} />
          <PaymentDetails paymentDetails={paymentDetails} />
        </div>
      </div>
      <div className=" w-full flex justify-center">
        <div className=" mb-8" style={{ width: "85%" }}>
          <AllDocTable documents={documents} setDocuments={setDocuments} />
        </div>
      </div>
    </div>
  );
};

export default LiveData;
