import React from "react";
import InventoryItem from "./components/InventoryItem";

import { MdOutlineInventory } from "react-icons/md";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import UserAllocation from "./components/UsersAllocations/Table";

import RoomAllocations from "./components/RoomAllocations/Table";

const SingleInventoryItem = () => {
  
  return (
    <div className=" bg-ghost_white h-screen w-full flex flex-col gap-11 ">
      <FormLabel label="Inventory Items" mt={2}>
        <MdOutlineInventory />
      </FormLabel>
      <div className=" p-8">
        <div className="flex flex-col md:flex-row items-stretch w-full gap-5">
          <div className="w-full md:w-2/3 rounded-lg bg-white p-6">
            <InventoryItem  />
          </div>
        </div>
        <UserAllocation />
        <RoomAllocations />
      </div>
    </div>
  );
};

export default SingleInventoryItem;
