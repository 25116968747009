import React, { useState, useContext, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import { useParams, useNavigate } from "react-router-dom";

import AddDocumentModal from "./../../Modals/AddDocumentModal";

const DocumentTable = ({ documents, setDocuments, fetchData }) => {
  const [limit, setLimit] = useState(10);

  const [showPagination, setShowPagination] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          data: documents,
          setData: setDocuments,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Purchase Order Documents
            </p>
            <BtnRounded click={() => setShowAddModal(true)}>
              <span className="text-sm pr-3">
                {" "}
                <FaPlus />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">Add Document</span>
            </BtnRounded>
          </div>

          <THead />
          <TBody />
          <TInfo />
        </Table>
        <AddDocumentModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          data={documents}
          setData={setDocuments}
          fetchData={fetchData}
        />
      </TContext.Provider>
    </div>
  );
};

export default DocumentTable;
