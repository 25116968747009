import Steppers from "../../../../../../../Components/Steppers/Steppers";

import {
  FaPlusCircle,
  FaWpforms,
  FaUserPlus,
  FaLocationArrow,
  FaCoins,
} from "react-icons/fa";
const AddSteppers = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>Add Vendor Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaUserPlus />
        <p>Add Contact Person</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaLocationArrow />
        <p>Add Vendor Address</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaCoins />
        <p>Add Payment Details</p>
      </div>
    </Steppers>
  );
};

export default AddSteppers;
