import { useEffect, useState } from "react";

import BellTopBar from "../../../../../../Components/Bars/BellTopBar.jsx";
import { TableTopBar } from "../../../../../../Components/FinalTable";
import AllItemsFilter from "./Components/FilterInventory";
import AllItemsTable from "./Components/Table";

import { useNavigate } from "react-router-dom";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";

import AllInventoryContext from "./Context/AllInventoryContext";
import Fuse from "fuse.js";
import useAxios from "../../../../../../Hooks/useAxios.jsx";

const LiveData = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter_expired, setfilter_expired] = useState(true);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});
  const [allDocs, setAllDocs] = useState(0);
  const [expDocs, setExpDocs] = useState(0);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();

  useEffect(() => {
    getInitInventoryItems(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "inventory/search",
        params: {
          value: searchInput,
        },
        network_error: false,
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitInventoryItems(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "inventory",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setAllDocs(res.total_docs);
    setExpDocs(res.total_expired);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInventoryItems(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInventoryItems(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitInventoryItems(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitInventoryItems(1, field, order);
  }

  async function handleClearFilters() {
    await getInitInventoryItems(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllInventoryContext.Provider
      value={{
        showLive,
        getInitInventoryItems,
        limit,
        setLimit,
        data,
        allDocs,
        expDocs,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Inventory Items" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => navigate("add")}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllItemsTable />
          <AllItemsFilter open={openFilter} setOpen={setOpenFilter} />
        </div>
      </div>
    </AllInventoryContext.Provider>
  );
};

export default LiveData;
