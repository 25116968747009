import { useRef, useState } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import DatePicker from "../../../../../../../Components/Inputs/DatePicker";
import CheckBox from "../../../../../../../Components/Inputs/CheckBox";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import { AiOutlineArrowRight } from "react-icons/ai";

const AddOrderDetails = ({setStep}) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  return (
    <div className="flex justify-center">
        <div className="bg-white rounded-[15px] p-9 lg:w-[610px]">
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "VAT",
                 placeholder: "Enter VAT",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Status",
                 disabled: true,
                 placeholder: "Pending",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />
            <DatePicker
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Receiving Date",
                 maxChar: 250,
                 minChar: 4,
               }}
            />

            <div className="flex flex-col gap-y-2">
                <CheckBox label="Is paid" />
                <CheckBox label="Sent" />
            </div>

            <div className="mx-3 py-8">
                <BtnRounded click={()=>setStep(prev=> prev+1)} className="btn">
                    <div className="flex gap-x-3 items-center justify-center">
                        <span className="font-DM-Med text-[15px]">Continue</span>
                        <span  className="font-DM-Med text-[1.1rem]"><AiOutlineArrowRight /></span>
                    </div>
                </BtnRounded>
            </div>
        </div>

    </div>
  )
}

export default AddOrderDetails