import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { MdOutlineInventory } from "react-icons/md";

import useAxios from "../../../../../../../Hooks/useAxios";

const TableAction = ({ doc }) => {
  const navigate = useNavigate();

  const request = useAxios();

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={() => navigate(`view/${doc.id}`)}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`edit/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiEdit2 />
              </p>
              <p className=" ">Edit Inventory</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`allocate/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <MdOutlineInventory />
              </p>
              <p className=" ">Alocate</p>
            </div>
          </BtnPlain>
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
