export default [
    {
        name: "Stephanie Mboya",
        email: "stephaniemboya@gmail.com",
        phone: "+25471234567",
        category: "Clinical",
        status: "Activated"
    },
    {
        name: "Stephanie Mboya",
        email: "stephaniemboya@gmail.com",
        phone: "+25471234567",
        category: "Clinical",
        status: "Activated"
    },
    {
        name: "Stephanie Mboya",
        email: "stephaniemboya@gmail.com",
        phone: "+25471234567",
        category: "Clinical",
        status: "Activated"
    },
    {
        name: "Stephanie Mboya",
        email: "stephaniemboya@gmail.com",
        phone: "+25471234567",
        category: "Clinical",
        status: "Activated"
    },
    {
        name: "Stephanie Mboya",
        email: "stephaniemboya@gmail.com",
        phone: "+25471234567",
        category: "Clinical",
        status: "Activated"
    },
]