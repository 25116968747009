import { useState } from "react";

import SelectRoom from "./Components/Steps/SelectRoom";

import SelectUser from "./Components/Steps/SelectUser";

import SelectAllocationType from "./Components/Steps/SelectAllocationType";

import Submit from "./Components/Steps/Submit";

const Forms = ({ step, allocate }) => {
  if (step === -1) {
    return <SelectAllocationType />;
  }

  if (step === 0) {
    if (allocate.to_user) {
      return <SelectUser />;
    }
    return <SelectRoom />;
  }

  if (step === 1) {
    return <Submit />;
  }
};

export default Forms;
