import InvTable from "./Inventory/Table";

import VendorSelect from "./Vendors/Table";

const Forms = ({ step }) => {
  if (step === -1) {
    return <InvTable />;
  }

  if (step === 0) {
    return <VendorSelect />;
  }
};

export default Forms;
