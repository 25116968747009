export default [
    {
        name: "Bandages",
        category: "Medical",
        type: "Inventory",
        quantity: 500,
        unitCost: "Kshs 50,345"
    },
    {
        name: "Bandages",
        category: "Medical",
        type: "Inventory",
        quantity: 500,
        unitCost: "Kshs 50,345"
    },
    {
        name: "Bandages",
        category: "Medical",
        type: "Inventory",
        quantity: 500,
        unitCost: "Kshs 50,345"
    },
    {
        name: "Bandages",
        category: "Medical",
        type: "Inventory",
        quantity: 500,
        unitCost: "Kshs 50,345"
    },
]