import React, { useState, useContext, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import usePouchPagination from "../../../../../../../../../Hooks/usePouchPagination";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import { useNavigate, useParams } from "react-router-dom";

const FixedAssets = ({ po, fixed, setFixed }) => {
  const [limit, setLimit] = useState(10);

  const [showPagination, setShowPagination] = useState(true);

  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const navigate = useNavigate();

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          limit,
          setLimit,
          data: fixed,
          setData: setFixed,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Fixed Assets
            </p>
            <BtnRounded click={() => navigate("add/fixed")}>
              <span className="text-sm pr-3">
                {" "}
                <FaPlus />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">Add Fixed Asset</span>
            </BtnRounded>
          </div>

          <THead />
          <TBody />

          <div className=" w-full flex justify-end">
            <p className=" text-2xl text-cadet_grey">
              <small>TZS: </small>
              <span className=" font-DM-Bold">{po.fixed_total}</span>
            </p>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default FixedAssets;
