import useApp from "../../../Hooks/useApp";
let params = { sort_field: "created_at", sort_order: "desc", limit: 400 };

const useTables = () => {
  return [
    {
      name: "inventory_items",
      url: "inventory",
      params,
    },
    {
      name: "fixed_assets",
      url: "fixed",
      params,
    },
    {
      name: "vendors",
      url: "vendor",
      params,
    },
    {
      name: "inventory_data",
      url: "hospital/analytics/inventory",
    },
  ];
};

export default useTables;
