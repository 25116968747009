import React, { useState, useContext, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import useFuzzySearch from "../../../../../../../../../Hooks/useFuzzySearch";

import usePouchPagination from "../../../../../../../../../Hooks/usePouchPagination";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import BtnPlain from "../../../../../../../../../Components/Buttons/BtnPlain";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import SearchInput2 from "../../../../../../../../../Components/Inputs/SearchInput";

import useApp from "./../../../../../../../../../Hooks/useApp";

import AddInvContext from "../AddInvContext";
import useAxios from "../../../../../../../../../Hooks/useAxios";
import Fuse from "fuse.js";

const LiveData = ({}) => {
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { setShowFormModal, setModalMessage } = useApp();

  const { docs, checkedDocs, setStep } = useContext(AddInvContext);

  const request = useAxios();

  useEffect(() => {
    getInitInventory(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      let res = await request({
        method: "GET",
        url: "inventory/search",
        params: {
          value: searchInput,
        },
      });
      if (res === "network-error") {
        return;
      }

      if (res === "error") return;
      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
    }

    search();
  }, [searchInput]);

  async function getInitInventory(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    let res = await request({
      method: "GET",
      url: "inventory",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
      network_error: false,
    });

    if (res === "network-error") {
      return;
    }

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInventory(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInventory(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(q) {
    //console.log(q);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSordOrder(order);
    setSortField(field);
    getInitInventory(1, field, order);
  }

  async function handleClearFilters() {
    await getInitInventory(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }

  async function Submit() {
    if (checkedDocs.length === 0) {
      setModalMessage("Select at least one inventory item");
      setShowFormModal(true);
      return;
    }
    setStep((c) => c + 1);
  }

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          showLive: true,
          limit,
          setLimit,
          data,
          page,
          hasNextPage,
          hasPrevPage,
          handleNext,
          handlePrev,
          handleHeaderQuery,
          handleFilterQuery,
          isFiltered,
          handleClearFilters: handleClearFilters,
          total: "...",
          setIsFiltered,
          showPagination,
          resetQuery: handleClearFilters,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <SearchInput2
              placeholder="Search"
              input={searchInput}
              setInput={setSearchInput}
            />
            {isFiltered ? (
              <BtnRounded click={handleClearFilters}>Clear</BtnRounded>
            ) : null}
          </div>
          <THead />
          <TBody />
          <TInfo />
          <div className="w-full flex justify-end">
            <BtnPlain bg="primary" textColor="white" click={Submit}>
              Complete
            </BtnPlain>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default LiveData;
