import AllUsers from "../SelectTeam/AllUsers";

const SelectUser = () => {
  return (
    <div className=" p-4 rounded-2xl bg-white overflow-y-auto -my-10" style={{ width: "80%" }}>
      <AllUsers />
    </div>
  );
};

export default SelectUser;
