import React, { useRef, useState, useEffect } from "react";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import SelectInput from "../../../../../../../Components/Inputs/SelectInput";

import useAxios from "./../../../../../../../Hooks/useAxios";

import useApp from "./../../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import usePouch from "./../../../../../../../Hooks/usePouch";

import usePouchCud from "./../../../../../Hooks/usePouchCud";

const Form = () => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState([]);

  const request = useAxios();

  const { setShowSuccessModal, setModalMessage } = useApp();

  const { findAll } = usePouch();

  const navigate = useNavigate();

  const formErrors = useHandleFormError();

  const { create } = usePouchCud();

  useEffect(() => {
    async function init() {

      try {
        let cats = await request({
          method: "GET",
          url: `inventory/enums`,
        });
  
        let c = [{ value: "", label: "Select Category" }];
        let m = [{ value: "", label: "Select Unit Of Measure" }];
  
        cats.categories.forEach((cat) => c.push({ value: cat.id, label: cat.name }));
        cats.unit_of_measures.forEach((mess) =>
          m.push({ value: mess.id, label: mess.name })
        );
  
        setCategories(c);
        setMeasures(m);
      } catch (error) {
        navigate("/dashboard/inventory")
      }
    }
    init();
  }, []);

  const handleSubmit = async () => {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "inventory",
      data: {
        ...input.current.form,
        category_id: parseInt(input.current.form.category_id),
        unit_of_measure_id: parseInt(input.current.form.unit_of_measure_id),
        shelf_no: null,
        expiry_date: null,
        is_purchased: false,
      },
    });

    if (res !== "error") {
      setModalMessage("Inventory Item Added");
      setShowSuccessModal(true);
      await create({
        name: "inventory_items",
        doc: res,
      });
      navigate("../");
    }
  };

  return (
    <div style={{ width: "70%" }} className="bg-white rounded-[15px] p-10">
      <div className="flex gap-x-6 w-full    ">
        <div className=" flex-1">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. Syringe",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={`md-${Date.now()}`}
            input={input}
            showError={showError}
            inputKey="code"
            mt={0.5}
            config={{
              required: true,
              label: "Item Code",
              placeholder: "md-hxbr23",
              type: "text",
              maxChar: 250,
              minChar: 2,
            }}
          />
          <SelectInput
            input={input}
            inputKey="category_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: categories,
              label: "Category",
            }}
          />
          <SelectInput
            input={input}
            inputKey="unit_of_measure_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: measures,
              label: "Unit of Measure",
            }}
          />
        </div>
        <div className=" flex-1">
          <NumberInput
            initial={1}
            input={input}
            showError={showError}
            inputKey="unit_cost"
            mt={0.5}
            config={{
              required: true,
              label: "Unit Cost",
              placeholder: "Enter Unit Cost ",
            }}
          />
          <NumberInput
            initial={1}
            input={input}
            showError={showError}
            inputKey="moq"
            mt={0.5}
            config={{
              required: true,
              label: "Minimuim Order Quantity",
              placeholder: "eg. 20 ",
            }}
          />
          <NumberInput
            initial={1}
            input={input}
            showError={showError}
            inputKey="total_qty"
            mt={0.5}
            config={{
              required: true,
              label: "Quantity",
              placeholder: "eg. 20 ",
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <BtnPlain
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={handleSubmit}
          className="btn"
        >
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnPlain>
      </div>
    </div>
  );
};

export default Form;
