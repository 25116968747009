import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
} from "./../../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 4} label="Name" />
        <HeaderBtn w={90 / 4} label="Email" />
        <HeaderBtn w={90 / 4} label="Phone" />
        <HeaderBtn w={90 / 4} label="Role" />
        <HeaderBtn label="Select" />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
