import React, { useEffect, useState, useContext } from "react";

import {
  TableRow,
  TableCell,
  MultiCheckBox,
  NumberInput,
  SingleCheckBox,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import usePouch from "../../../../../../../../../Hooks/usePouch";

import AddInvContext from "../AddInvContext";

const TRow = ({ doc }) => {
  const { fetchById } = usePouch();

  const { checkedVendor, setCheckedVendor } = useContext(AddInvContext);

  return (
    <TableRow>
      <TableCell w={90 / 4}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.email} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.phone} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.tra_pin} />
      </TableCell>
      <TableCell>
        <div className=" w-full flex justify-center gap-x-4 text-3xl text-primary">
          <SingleCheckBox
            checked_id={checkedVendor}
            setCheckedId={setCheckedVendor}
            id={doc.id}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
