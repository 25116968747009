import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import BtnPlain from "../../../../../Components/Buttons/BtnPlain";

import { ActionBtn } from "../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { FaBan, FaRegTrashAlt } from "react-icons/fa";

import useAxios from "../../../../../Hooks/useAxios";

import useApp from "../../../../../Hooks/useApp";

import useAllRequests from "../hooks/useAllRquests";
import ApproveRequest from "./Modals/ApproveRequest";
import usePouchCud from "../../../Hooks/usePouchCud";

const TableActions = ({ doc }) => {
  const navigate = useNavigate();
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const { data, setData } = useAllRequests();
  const [addModal, setShowAddModal] = useState(false);
  const { update } = usePouchCud();


  const handleCancel = async () => {

    let dataa = {
      id: parseInt(doc.id),
      status: "canceled",
    };

    let res = await request({
      method: "PUT",
      url: "store/request/status",
      data: dataa,
    });

    if (res === "error") return;

    if (res !== "error") {
      setModalMessage("Request Canceled!");
      setShowSuccessModal(true);
      await update({ name: "store_request", doc: res });
      
      setData([res, ...data]);
    }
  };

 
  return (
    <div className="flex-1">
      <ApproveRequest
        showModal={addModal}
        setShowModal={setShowAddModal}
        id={doc.id}
        qtyy={doc.qty}
      />
      <ActionBtn showNext={false} >
        <div className=" gap-y-2 flex flex-col text-sm">
          {doc.status !== "approved" && (
            <BtnPlain
              activeBgColor="white"
              bg="white"
              click={() => setShowAddModal(true)}
            >
              <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                <p className="  text-primary">
                  <FiEdit2 />
                </p>
                <p>Approve</p>
              </div>
            </BtnPlain>
          )}
         
          {doc.status !== "approved" && (
            <BtnPlain activeBgColor="white" bg="white" click={() => handleCancel()}>
              <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                <p className="  text-red">
                  <FaBan />
                </p>
                <p>Cancel</p>
              </div>
            </BtnPlain>
          )}
          
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableActions;
