import React, { useState, useEffect } from "react";

import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import HeaderBtn from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import useAllInventory from "./../Hooks/useAllInventory";
import useAxios from '../../../../../../../Hooks/useAxios'
import useApp from "../../../../../../../Hooks/useApp";

const TableHeader = () => {
  const [name, setName] = useState(true);
  const [store_qty, setstore_qty] = useState(true);
  const [unit_cost, setUnit_cost] = useState(true);
  const [catNo, setCatNo] = useState(0);
  const [categeries, setCategories] = useState([]);
  const { setTableIsLoading } = useApp()
  const request = useAxios()

  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useAllInventory();

  useEffect(() => {
    async function getCategories() {
      let res = await request({
        method: "GET",
        url: "inventory/enums",
      });
  
      if (res === "error") return;
      setCategories(res.categories);
    }
  
    getCategories();
  }, []);

  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }
  }

  function handleCostSort() {
    if (showLive) {
      handleHeaderQuery("unit_cost", unit_cost ? "asc" : "desc");
      setUnit_cost(!unit_cost);
      setIsFiltered(true);
      return;
    }
  }

  function handlestore_qtySort() {

    if (showLive) {
      handleHeaderQuery("store_qty", store_qty ? "asc" : "desc");
      setstore_qty(!store_qty);
      setIsFiltered(true);
      return;
    }

    }

  function handleCategoryFilter() {

    if (showLive) {
      handleHeaderQuery("category_id", categeries ? "asc" : "desc");
      setCategories(!categeries);
      setIsFiltered(true);
      return;
    }

    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };

    const secondarySelector = {
      category_id: {
        $eq: catNo > categeries.length - 1 ? categeries[0].name : categeries[catNo].name,
      },
    };

    if (catNo > categeries.length - 1) {
      setCatNo(0);
    } else {
      setCatNo((c) => c + 1);
    }
    handleFilterQuery(index, sort, selector, secondarySelector);

    setIsFiltered(true);

  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 6} label="NAME" click={handleNameSort} />
      <HeaderBtnSort w={90 / 6} label="Category" click={handleCategoryFilter} />
      <HeaderBtnSort w={90 / 6} label="Cost" click={handleCostSort} />
      <HeaderBtnSort w={90 / 6} label="Qty" click={handlestore_qtySort} />
      <HeaderBtn w={90 / 6} label="Stats" />
      <HeaderBtn w={90 / 6} label="State" />
      <div className="flex-1 invisible">
        {/* <TableHorizontalActionBtn /> */}
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  );
};

export default TableHeader;
