import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { FaUserSlash, FaUser } from "react-icons/fa";

import useAxios from "../../../../../../../Hooks/useAxios";

const TableAction = ({ doc }) => {
  const navigate = useNavigate();

  const request = useAxios();

  async function handleVendorStatus() {
    let res = await request({
      method: "PUT",
      url: "vendor/status",
      data: {
        vendorId: doc.id,
        isActive: !doc.is_active,
      },
    });

    if (res === "error") {
      return;
    }

    doc.is_active = !doc.is_active;
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={() => navigate(`view/${doc.id}`)}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`edit/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                <FiEdit2 />
              </p>
              <p className=" ">Edit Vendor</p>
            </div>
          </BtnPlain>
          <BtnPlain activeBgColor="white" bg="white" click={handleVendorStatus}>
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="text-red">
                {doc.is_active ? <FaUserSlash /> : <FaUser />}
              </p>
              <p className="text-red ">
                {doc.is_active ? "deactivate" : "activate"} Vendor
              </p>
            </div>
          </BtnPlain>
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
