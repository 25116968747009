import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
  HorizontalActionBtn,
} from "./../../../../../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 6} label="NAME" />
        <HeaderBtn w={90 / 6} label="CODE" />
        <HeaderBtn w={90 / 6} label="VENDOR" />
        <HeaderBtn w={90 / 6} label="UNIT COST" />
        <HeaderBtn w={90 / 6} label="QTY" />
        <HeaderBtn w={90 / 6} label="TOTAL" />
        <div className=" invisible  flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => {}}
          >
            <MdDelete />
          </span>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
