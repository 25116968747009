import { FaPlus } from "react-icons/fa"
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded"


const OrderDetails = ({ image }) => {
  return (
    <div className="flex flex-col rounded-t-[30px] w-full bg-white gap-y-9">
        <div className="flex flex-col md:flex-row w-full justify-between items-start">
          <div className="flex flex-col gap-y-2">
            {image&&<img src={image} alt="Boxes Stacked" />}
            <h1 className="font-DM-Bold text-2xl">Bandages Order</h1>
          </div>
          <h1 className="font-DM-Reg text-2xl">18 Oct 2020</h1>
        </div>

        <div className="max-w-xl ">
          <h1 className="font-DM-Reg text-2xl text-[#55585B]">Description</h1>
          <p className="font-Dm-Reg text-2xl text-black">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere provident ipsa obcaecati culpa laboriosam corrupti 
            exercitationem fuga nesciunt impedit possimus repellendus magnam cumque,
          </p>
          <p className="text-xl font-Dm-Reg text-cadet_grey pt-2">
            Receiving Date: 22 Nov 2020
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex items-center justify-start font-DM-Bold text-[16px]">
            <span className="px-6 py-3 bg-[#ACF18C] text-[#40712F] rounded-xl mr-2">Paid</span>
            <span className="px-6 py-1 bg-[#C6CEDB] text-[#15286D] rounded-lg mr-6">Sent</span>
            <span className="font-DM-Reg text-[#7A1050]">vat inclusive</span>
          </div>
          <BtnRounded click={null} className="btn">
            <span className="text-sm pr-3"> <FaPlus/> </span>
            <span className="font-DM-Med text-[15px]">Change Status</span>
          </BtnRounded>
        </div>
    </div>

  )
}

export default OrderDetails