import { RiDeleteBinLine } from "react-icons/ri"
import { 
  ActionBtn, 
  Table, 
  TableCell, 
  TableHeaderRow, 
  TableRow, 
  TableText 
} from "../../../../../../../Components/FinalTable"
import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort"

const dummyData = [
  {
    amount: "3627",
    reason: "loremipsumdelor",
    type: "John Doe",
    info: "loremipsumdelordelut."
  },
  {
    amount: "3627",
    reason: "loremipsumdelor",
    type: "John Doe",
    info: "loremipsumdelordelut."
  },
  {
    amount: "3627",
    reason: "loremipsumdelor",
    type: "John Doe",
    info: "loremipsumdelordelut."
  }
]

const OrderCharges = ({ data = dummyData}) => {
  return (
    <>
    <Table>
      <TableHeaderRow bg=" bg-[#F9FAFB]">
        <HeaderBtnSort w={100 / 5} label="AMOUNT" />
        <HeaderBtnSort w={100 / 5} label="REASON" />
        <HeaderBtnSort w={100 / 5} label="TYPE" />
        <HeaderBtnSort w={100 / 5} label="INFO" />
      </TableHeaderRow>
      {data.map((doc, i) => (
        <TableRow mt={10} key={i}>
          <TableCell w={100 / 5}>
            <TableText label={doc.amount} />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.reason} />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.type} />
          </TableCell>
          <TableCell w={100 / 5}>
            <TableText label={doc.info} />
          </TableCell>
          <div className="flex-1">
            <ActionBtn>
              <div className=" gap-y-2 flex flex-col text-sm">
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className=" text-red">
                    <RiDeleteBinLine />
                  </p>
                  <p className=" ">Delete</p>
                </div>
              </div>
            </ActionBtn>
          </div>
        </TableRow>
      ))}
    </Table>
    <div className="w-full flex pt-9 justify-end">
      <h1 className="font-DM-Bold text-2xl text-black">TOTAL: 312000</h1>
    </div>
    </>
  )
}

export default OrderCharges