import React, { useContext } from "react";

import AllInventoryContext from "./../Context/AllInventoryContext";

const useAllInventory = () => {
  const {
    getInitInventoryItems,
    limit,
    setLimit,
    showLive,
    data,
    allDocs,
    expDocs,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    isFiltered,
    handleClearFilters,
    total,
    setIsFiltered,
    showPagination,
    setFixed,
    resetQuery,
  } = useContext(AllInventoryContext);

  return {
    getInitInventoryItems,
    limit,
    setLimit,
    showLive,
    data,
    allDocs,
    expDocs,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    isFiltered,
    handleClearFilters,
    total,
    setIsFiltered,
    showPagination,
    setFixed,
    resetQuery,
  };
};

export default useAllInventory;
