import React, { useState } from "react";
import Avatar from "./Avatar";

import Menu from "./Btns/Menu";

import SummaryBtn from "./Btns/SummaryBtn";

// import SettingsBtn from "./Btns/SettingsBtn";

// import Team from "./Btns/Team";

// import Items from "./Btns/Items";

import PurchaseOrder from "./Btns/PurchaseOrder";

import Vendors from "./Btns/Vendors";

import Inventory from "./Btns/Inventory";

import FixedAssets from "./Btns/FixedAssets";

import Requests from "./Btns/Request";

import LogOut from "./Btns/LogOut";

const SideBar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className=" transition-all duration-500 ease-in-out h-screen shadow-md w-fit flex flex-col items-center border-r-2 gap-y-2"
      style={{}}
    >
      <Menu open={open} setOpen={setOpen} />
      <SummaryBtn open={open} />
      <Vendors open={open} />
      {/* <Items open={open} /> */}
      <Inventory open={open} />
      <FixedAssets open={open} />
      <PurchaseOrder open={open} />
      <Requests open={open} />
      <div className="flex-1"></div>
      <div
        style={{ height: "1.3px" }}
        className=" w-full bg-bright_grey mb-4"
      ></div>
      {/* <SettingsBtn open={open} active={false} /> */}
      {/* <Team open={open} active={false} /> */}
      <LogOut open={open} />
      <Avatar name="Samuel mwangi" />
      <div className=" mb-7"></div>
    </div>
  );
};

export default SideBar;
