import BellTopBar from "../../../../../../Components/Bars/BellTopBar";
import OrderDescription from "./components/OrderDescription";
import AddOrderItems from "./components/AddOrderItems";
import AddOrderCharges from "./components/AddOrderCharges";

import AddDetails from "../AddDetails"

const AddPurchaseOrder = () => {
  return (
    <div className="bg-bright_grey w-full min-h-screen">
      <div className="w-full bg-white">
        <BellTopBar label="Order SN-FD4353534" />
      </div>
      <div className="py-10 px-6">
        <div className="bg-white rounded-[30px] space-y-3">
          <OrderDescription />  
          <AddOrderItems />
          <AddOrderCharges />
        </div>
      </div>
    </div>
  );
};

export default AddPurchaseOrder;
