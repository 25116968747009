import { useContext } from "react";

import AddVendor from "../context/AddVendor";

const useAddVendor = () => {
  const {
    vendor,
    setVendor,
    contactPerson,
    setContactPerson,
    address,
    setAddress,
    payment,
    setPayment,
  } = useContext(AddVendor);
  return {
    vendor,
    setVendor,
    contactPerson,
    setContactPerson,
    address,
    setAddress,
    payment,
    setPayment,
  };
};

export default useAddVendor;
