import React, { useRef, useState, useEffect } from "react";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import SelectInput from "../../../../../../../Components/Inputs/SelectInput";

import useAxios from "./../../../../../../../Hooks/useAxios";

import useApp from "./../../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import usePouch from "../../../../../../../Hooks/usePouch";

import usePouchCud from "./../../../../../Hooks/usePouchCud";

const Form = () => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);
  const [categories, setCategories] = useState([]);

  const { findAll } = usePouch();

  const request = useAxios();

  const { setShowSuccessModal, setModalMessage } = useApp();

  const navigate = useNavigate();

  const formErrors = useHandleFormError();

  const { create } = usePouchCud();

  useEffect(() => {
    async function fetchEnums() {
      try {
        let res = await request({
          method: "GET",
          url: `fixed/categories`,
        });

        let c = [{ value: "", label: "Select Category" }];
        for (let i = 0; i < res.length; i++) {
          let cat = res[i];
          c.push({
            value: cat.id,
            label: cat.name,
          });
        }
        setCategories(c);
      } catch (e) {
        navigate("../");
        return;
      }
    }
    fetchEnums();
  }, []);

  const handleSubmit = async () => {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let data = {
      ...input.current.form,
      category_id: parseInt(input.current.form.category_id),
      qty: parseInt(input.current.form.qty),
      shelf_no: "",
      unit_cost: parseInt(input.current.form.unit_cost),
      depreciation_rate_pa: parseInt(input.current.form.depreciation_rate_pa),
      depreciation_years: parseInt(input.current.form.depreciation_years),
      is_purchased: false,
    };

    let res = await request({
      method: "POST",
      url: "fixed",
      data: data,
    });

    if (res !== "error") {
      setModalMessage("Fixed Asset Added");
      await create({ name: "fixed_assets", doc: res });
      setShowSuccessModal(true);
      navigate("../");
    }
  };

  return (
    <div style={{ width: "70%" }} className="bg-white rounded-[15px] p-10">
      <div className="flex gap-x-6 w-full    ">
        <div className=" flex-1">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. Syringe",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={Date.now().toString()}
            input={input}
            showError={showError}
            inputKey="sn"
            mt={0.5}
            config={{
              required: true,
              label: "Sn",
              placeholder: "Enter Serial number",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <SelectInput
            input={input}
            inputKey="category_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: false,
              items: categories,
              label: "Category",
            }}
          />
          <NumberInput
            initial={1}
            input={input}
            showError={showError}
            inputKey="total_qty"
            mt={0.5}
            config={{
              required: true,
              label: "Quantity",
              placeholder: "eg. 20 ",
            }}
          />
        </div>
        <div className=" flex-1">
          <NumberInput
            initial={1}
            input={input}
            showError={showError}
            inputKey="unit_cost"
            mt={0.5}
            config={{
              required: true,
              label: "Unit Cost",
              placeholder: "Enter Unit Cost ",
            }}
          />
          <NumberInput
            initial={0}
            input={input}
            showError={showError}
            inputKey="depreciation_rate_pa"
            mt={0.5}
            config={{
              required: true,
              label: "Depreciation Rate Per Annum in %",
              placeholder: "eg. 20 ",
              max: 99,
              min: 0,
            }}
          />
          <NumberInput
            initial={0}
            input={input}
            showError={showError}
            inputKey="depreciation_years"
            mt={0.5}
            config={{
              required: true,
              label: "Depreciation Years",
              placeholder: "eg. 20 ",
              min: 0,
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <BtnPlain
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={handleSubmit}
          className="btn"
        >
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnPlain>
      </div>
    </div>
  );
};

export default Form;
