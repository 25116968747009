import { useContext } from 'react'

import AllRequestsContext from '../context/AllRequestsContext'

const useAllRequests = () => {

    const doc = useContext(AllRequestsContext)

  return doc
}

export default useAllRequests