import React from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import TableHeader from "./TableHeader";

import TableBody from "./TableBody";

import TableInfoSection from "./TableInfoSection";

const AllVendorTable = () => {
  return (
    <Table>
      <TableHeader />
      <TableBody />
      <TableInfoSection />
    </Table>
  );
};

export default AllVendorTable;
