import React, { useEffect } from "react";

import { TableTopBar } from "../../../../Components/FinalTable";

import BellTopBar from "../../../../Components/Bars/BellTopBar.jsx";

import AllRequestsTable from "./Components/AllRequestsTable";

import AllRequestsContext from "./context/AllRequestsContext";

import usePouchPagination from "../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../Hooks/useFuzzySearch";

import { useNavigate } from "react-router-dom";

import { useState } from "react";

import FilterComponent from "./Components/Filter";
import useAxios from "../../../../Hooks/useAxios";
import Fuse from "fuse.js";
import useApp from "../../../../Hooks/useApp";

const LiveData = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);
  const { user } = useApp();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({});
  const request = useAxios();

  useEffect(() => {
    getAllRequests(1, "created_at", "desc", filters);
  }, [limit]);

  async function getAllRequests(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "store/requests",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        user_id: user.id,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getAllRequests(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getAllRequests(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    setPage(1);
    getAllRequests(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getAllRequests(1, field, order);
  }

  async function handleClearFilters() {
    await getAllRequests(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllRequestsContext.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        showLive,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        getAllRequests,
      }}
    >
      <div className="w-full h-full bg-ghost_white ">
        <BellTopBar label="Requests" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add Procedure"
            showAdd={false}
            AddClick={() => navigate("add")}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={true}
            showSearch={false}
          />
          <AllRequestsTable />
          <FilterComponent open={openFilter} setOpen={setOpenFilter} />
        </div>
      </div>
    </AllRequestsContext.Provider>
  );
};

export default LiveData;
