import React, { useContext, useEffect, useState } from "react";

import {
  TableRow,
  TableCell,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import { MdDelete } from "react-icons/md";

import usePouch from "../../../../../../../../../Hooks/usePouch";

import useAxios from "../../../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../../../Hooks/useApp";
import usePouchCud from "../../../../../../../Hooks/usePouchCud";
import TContext from "./TContext";

const TRow = ({ doc }) => {
  const { deleteDoc } = usePouchCud();
  const request = useAxios();
  const { setModalMessage, setShowSuccessModal } = useApp();
  const { data, setData } = useContext(TContext);


  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `purchase/fixed/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Fixed Asset Deleted");
      setShowSuccessModal(true);
      await deleteDoc({ name: "purchase_fixed", doc: res });
    }
    let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
  }

  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={doc.fixed_assets.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.fixed_assets.sn} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.vendors.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.unit_cost} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty * doc.unit_cost} />
      </TableCell>
      <TableCell>
        <div className=" flex-1 flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => handleDelete()}
          >
            <MdDelete />
          </span>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
