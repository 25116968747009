import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
  HorizontalActionBtn,
} from "../../../../../../../../../Components/FinalTable";

import { MdDelete } from "react-icons/md";

const THead = () => {
  return (
    <div className="w-full ">
      <TableHeaderRow>
        <HeaderBtn w={90 / 4} label="NAME" />
        <HeaderBtn w={90 / 4} label="AMOUNT" />
        <HeaderBtn w={90 / 4} label="DESCRIPTION" />
        <HeaderBtn w={90 / 4} label="DATE" />
        <div className=" flex-1 invisible flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => {}}
          >
            <MdDelete />
          </span>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
