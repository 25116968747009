import React, { useState, useContext } from "react";

import SearchInput2 from "../../../../../../../../Components/Inputs/SearchInput";

import usePouchPagination from "../../../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../../../Hooks/useFuzzySearch";

import { FaLongArrowAltRight } from "react-icons/fa";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import TeamContext from "./TeamContext";

import TableHead from "./THead";

import TBody from "./TBody";

import TableInfoSection from "./TableInfoSection";

import useApp from "../../../../../../../../Hooks/useApp";

import AllocateContext from "../../AllocateContex";

const AllRooms = ({ showLive }) => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { setModalMessage, setShowFormModal } = useApp();

  const { setStep, setAllocate, allocate } = useContext(AllocateContext);

  const [checked_id, setCheckedId] = useState(-1);

  const [searchInput, setSearchInput] = useState("");

  const db_name = "team";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["no", "location", "info"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleSubmit() {
    if (checked_id === -1) {
      setModalMessage("Select user to continue");
      setShowFormModal(true);
      return;
    }

    setAllocate({ ...allocate, to: checked_id });

    setStep((c) => c + 1);
  }

  return (
    <TeamContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        total,
        setIsFiltered,
        showPagination,
      }}
    >
      {/* <SearchInput2 mb={0} /> */}
      <TableHead />
      <TBody checked_id={checked_id} setCheckedId={setCheckedId} />
      <TableInfoSection />
      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
        <span className="px-3 font-normal text-2xl">
          <FaLongArrowAltRight />
        </span>
      </BtnRounded>
    </TeamContext.Provider>
  );
};

export default AllRooms;
