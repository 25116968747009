import React, { useState, useEffect } from "react";

import { colors } from "./../../../../../../../../Util/colors";

const MultiCheckBox = ({
  _id,
  checkedDocs = [],
  setCheckedDocs = () => {},
  mt = 0,
  mb = 0,
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    if (checkedDocs.includes(_id) === false) {
      setCheckedDocs([...checkedDocs, _id]);
      return;
    }

    let newDocs = checkedDocs.filter((id) => (_id === id ? false : true));
    setCheckedDocs(newDocs);
  };

  return (
    <div
      className="flex flex-col px-2"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <input
        className={`border-2 rounded-lg h-5 w-5 cursor-pointer checked:span>text-primary placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
        }}
        type="checkbox"
        checked={checkedDocs.includes(_id)}
        onChange={handleChange}
      />
    </div>
  );
};

export default MultiCheckBox;
