import React, { useState, useEffect } from "react";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import { TableTabs } from "../../../../../../../Components/FinalTable";

import useAllInventory from "../Hooks/useAllInventory";

import usePouchCount from "../../../../../../../Hooks/usePouchCount";
import { useParams } from "react-router-dom";

const AllTabs = () => {
  const {
    setFixed,
    resetQuery,
    isFiltered,
    setIsFiltered,
    handleFilterQuery,
    handleClearFilters,
    getInitInventoryItems,
    data,
    allDocs,
    expDocs,
  } = useAllInventory();

  const {id} = useParams()

  const [tabs, setTabs] = useState([
    { title: "all", color: "primary", no: allDocs },
    { title: "expired", color: "red", no: expDocs },
    // { title: "reorder", color: "red", no: null },
  ]);

  const [acitiveTab, setActiveTab] = useState("all");


  useEffect(() => {
    if (isFiltered) return;
    setActiveTab("all");
  }, [isFiltered]);

  useEffect(() => {
    const countAsync = async () => {
      const newTabs = [...tabs];

      newTabs[0].no = allDocs;
      newTabs[1].no = expDocs;
      // newTabs[2].no = reorder;

      setTabs(newTabs);
    };

    countAsync();
  }, [allDocs, expDocs, acitiveTab]);

  async function handleTab(tab) {
    setActiveTab(tab.title);
    if (tab.title === "all") {
      getInitInventoryItems()
      return;
    }
    let filters = {}
    let to_filter = false
    if (tab.title === "expired") {
      filters.filter_expired =  true
        to_filter = true
      if (to_filter) {
        handleFilterQuery(filters);
        return;
      }
      await resetQuery();
      return;
    }

    setFixed({
      reorder: { $eq: true },
    });
    setIsFiltered(true);
    resetQuery();
  }

  return <TableTabs tabs={tabs} activeTab={acitiveTab} handleTab={handleTab} />;
};

export default AllTabs;
