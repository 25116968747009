import React, { useState, useEffect } from "react";

import usePouch from "../../../../../../Hooks/usePouch";

import { useParams } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";

import OrderDetails from "./components/OrderDetails";
import BellTopBar from "../../../../../../Components/Bars/BellTopBar";

import InventoryTable from "./components/Tables/Inventory/Table";

import FixedAssetTable from "./components/Tables/FixedAss/Table";

import OtherCharges from "./components/Tables/OtherCharges/Table";

import DocumentTable from "./components/Tables/Documents/Table";

import { useNavigate } from "react-router-dom";

import useAxios from "../../../../../../Hooks/useAxios";

const ViewPo = () => {
  const [po, setPo] = useState({ name: "" });
  const { id } = useParams();

  const [inv, setInv] = useState([]);
  const [fixed, setFixed] = useState([]);
  const [charges, setCharges] = useState([]);
  const [documents, setDocuments] = useState([]);

  const { setIsLoading } = useApp();

  const navigate = useNavigate();

  const request = useAxios();

  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
  }, []);

  async function fetchData() {
    try {
      let res = await request({
        method: "GET",
        url: `purchase?purchase_order_id=${id}`,
      });

      //console.log(res);

      if (res === "error") {
        navigate("../");
        return;
      }

      setPo(res);
      setDocuments(res.purchase_documents);
      setCharges(res.purchase_other_charges);
      setFixed(res.purchase_fixed);
      setInv(res.purchase_inventory);
    } catch (e) {
      navigate("../");
    } finally {
      setIsLoading(false);
    }
  }

  //console.log(fixed);

  return (
    <div className="w-full bg-ghost_white ">
      <BellTopBar label={`Order- ${po.name || ""} `} />
      <div className=" p-9">
        <OrderDetails doc={po} setDoc={setPo} fetchData={fetchData} />
        <DocumentTable
          documents={documents}
          setDocuments={setDocuments}
          fetchData={fetchData}
        />
        <InventoryTable
          po={po}
          inv={inv}
          setInv={setInv}
          fetchData={fetchData}
        />
        <FixedAssetTable
          po={po}
          fixed={fixed}
          setFixed={setFixed}
          fetchData={fetchData}
        />
        <OtherCharges
          po={po}
          charges={charges}
          setCharges={setCharges}
          fetchData={fetchData}
        />
      </div>
    </div>
  );
};

export default ViewPo;
