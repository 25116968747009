import { GiOfficeChair } from "react-icons/gi";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Inventory = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "fixed-assets-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Fixed-Assets"}
        open={open}
        active={location.pathname.includes("dashboard/fixed-asset")}
        click={() => navigate("fixed-asset")}
      >
        <GiOfficeChair />
      </BtnTemplate>
      <Tooltip id={id} txt1="Fixed Assets" txt2="in one spot">
        <GiOfficeChair />
      </Tooltip>
    </div>
  );
};

export default Inventory;
