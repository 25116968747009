import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
  HorizontalActionBtn,
  HeaderBtnSort,
} from "../../../../../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 4} label="NAME" />
        <HeaderBtn w={90 / 4} label="Type" />
        <HeaderBtn w={90 / 4} label="Date uploaded" />
        <HeaderBtn w={90 / 4} label="Actions" />
        <div className="flex-1 invisible">
          {/* <TableHorizontalActionBtn /> */}
          <HorizontalActionBtn></HorizontalActionBtn>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
