import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  SingleCheckBox,
  HorizontalActionBtn,
} from "./../../../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 5} label="SN" />
        <HeaderBtn w={90 / 5} label="NAME" />
        <HeaderBtn w={90 / 5} label="CATEGORY" />
        <HeaderBtn w={90 / 5} label="COST" />
        <HeaderBtn w={90 / 5} label="QTY" />
        <div className="flex-1 invisible">
          {/* <TableHorizontalActionBtn /> */}
          <HorizontalActionBtn></HorizontalActionBtn>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
