import { useRef, useState, useEffect } from "react";
import TextInput from "../../../../../../../../Components/Inputs/TextInput";
import BasicModal from "../../../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsFillPlusCircleFill } from "react-icons/bs";
import useAxios from "../../../../../../../../Hooks/useAxios";
import usePouchCud from "../../../../../../Hooks/usePouchCud";
import { useParams } from "react-router-dom";
import { FcDocument } from "react-icons/fc";
import useApp from "../../../../../../../../Hooks/useApp";

const AddModal = ({ showModal, setShowModal, data, setData, fetchData }) => {
  const { id } = useParams();
  const input = useRef({ form: {}, err: {} });
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(null);

  const doc = useRef();

  const { create } = usePouchCud();

  const [showError, setShowError] = useState(0);

  const { setShowSuccessModal, setModalMessage } = useApp();

  const request = useAxios();

  function handleAdd() {
    doc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      let formData = new FormData();

      formData.append("document", files[0]);
      formData.append("purchase_order_id", id);
      formData.append("description", input.current.form["description"]);
      setShowModal(false);

      let res = await request({
        method: "POST",
        url: "purchase/documents/upload",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });

      if (res !== "error") {
        setModalMessage("Document added to server. ");
        setShowSuccessModal(true);
        setData([res, ...data]);
        await create({ name: "purchase_documents", doc: res });
        await fetchData();
      }
    }
    addDocument();
  }, [submited, files]);

  //console.log(data);

  function handleSubmit() {
    setShowError((c) => c + 1);
    if (
      input.current.form["description"] === null ||
      input.current.form["description"] === undefined ||
      input.current.form["description"] === ""
    ) {
      setSubmited(false);
      return;
    }
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add Purchase Order document
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            initial=""
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "eg Contract",
              type: "text",
              inputType: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <div className="flex justify-center items-center">
            <p className="text-4xl">
              {" "}
              <FcDocument />
            </p>

            <span
              onClick={handleAdd}
              className=" text-3xl cursor-pointer shadow-2xl bg-transparent rounded-full z-50 text-primary"
            >
              <BsFillPlusCircleFill />
            </span>
          </div>
          <input
            className="hidden"
            ref={doc}
            type="file"
            id="myFile"
            name="filename"
            onChange={(e) => setFiles(e.target.files)}
          />
          <BtnBlock
            click={handleSubmit}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Submit Document</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default AddModal;
