import { useContext, useRef, useState } from "react";
import TextInput from "../../../../../../../../Components/Inputs/TextInput";
import TextArea from "../../../../../../../../Components/Inputs/TextArea";
import NumberInput from "../../../../../../../../Components/Inputs/NumberInput";

import BasicModal from "../../../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";

import useHandleFormError from "../../../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../../../Hooks/useAxios";

import { useParams } from "react-router-dom";
import useApp from "../../../../../../../../Hooks/useApp";
import usePouchCud from "../../../../../../Hooks/usePouchCud";

import TContext from "../Tables/OtherCharges/TContext";

const EditPo = ({ showModal = false, setShowModal }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  const {data, setData} = useContext(TContext)
  const request = useAxios();

  const {create} = usePouchCud()

  const formErrors = useHandleFormError();

  const { id } = useParams();

  const { setShowSuccessModal, setModalMessage } = useApp()

  async function handleSubmit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let datas = {
      purchase_order_id: parseInt(id),
      amount: parseInt(input.current.form.amount),
      name: input.current.form.name,
      description: input.current.form.description,
    };

    let res = await request({
      method: "POST",
      url: "purchase/other",
      data: datas,
    });

    if (res !== "error") {
    
    setModalMessage("Charge Added ");
    setShowSuccessModal(true);
    await create({name:"purchase_other_charges", doc: res});
    
    setData([res, ...data]);
    
    }
    
    setShowModal(false);
    
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add Other Charges
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Charges Name",
              placeholder: "Enter Name",
              type: "text",
              inputType: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <NumberInput
            initial={""}
            inputKey="amount"
            input={input}
            mt={0.5}
            showError={showError}
            config={{
              required: true,
              label: "Enter Amount",
              placeholder: "eg: 2000",
              min: 0,
              max: 99999999999999999999,
            }}
          />
          <TextArea
            initial={""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: false,
              label: "Description",
              placeholder: "Enter Description",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />

          <BtnBlock
            click={handleSubmit}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Continue</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default EditPo;
