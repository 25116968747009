import React, { useState, useEffect, useContext } from "react";
import AllContext from "../../Context";
import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "../../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import HeaderBtn from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

const THead = () => {

  const {handleHeaderQuery, showLive, setIsFiltered} = useContext(AllContext);

  const [id, setId] = useState(true);
  const [name, setName] = useState(true);
  const [vat, setVat] = useState(true);
  const [status, setStatus] = useState(true);
  const [created, setCreated] = useState(true);
  const [total, setTotal] = useState(true);


  function handleVatSort(){
    if (showLive){
        handleHeaderQuery("vat", vat ? "asc" : "desc");
        setVat(!vat);
        return;
    }

   }

   function handleTotalSort(){
    if (showLive){
        handleHeaderQuery("total", total ? "asc" : "desc");
        setTotal(!total);
        return;
    }

   }


  function handleIdSort(){
    if (showLive){
        handleHeaderQuery("id", id ? "asc" : "desc");
        setId(!id);
        return;
    }

   }

  function handleNameSort(){
    if (showLive){
        handleHeaderQuery("name", name ? "asc" : "desc");
        setName(!name);
        return;
    }

   }

  function handleDateSort(){
    if (showLive){
        handleHeaderQuery("created_at", created ? "asc" : "desc");
        setCreated(!created)
        return;
    }
   }


  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtnSort w={90 / 6} label="ORDER ID" click={() => handleIdSort()} />
        <HeaderBtnSort w={90 / 6} label="NAME" click={() => handleNameSort()} />
        <HeaderBtnSort w={90 / 6} label="VAT" click={() => handleVatSort()} />
        <HeaderBtn w={90 / 6} label="STATUS" />
        <HeaderBtnSort w={90 / 6} label="DATE CREATED" click={() => handleDateSort()} />
        <HeaderBtnSort w={90 / 6} label="TOTAL" click={() => handleTotalSort()} />
        <div className="flex-1 invisible">
          {/* <TableHorizontalActionBtn /> */}
          <HorizontalActionBtn></HorizontalActionBtn>
        </div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
