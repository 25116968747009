import React from "react";


import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";


const InvTable = ({ po, inv, setInv }) => {

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          data: inv,
          setData: setInv,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Inventory items
            </p>
            
          </div>

          <THead />
          <TBody />

          <div className=" w-full flex justify-end">
            <p className=" text-2xl text-cadet_grey">
              <small>TZS: </small>
              <span className=" font-DM-Bold">{po.inventory_total}</span>
            </p>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default InvTable;
