import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../../Util/Time";

const TRow = ({ doc }) => {

  return (
    <div className="w-full">
      <TableRow>
        <TableCell w={100 / 5}>
          <TableText label={doc.spaces.no} />
        </TableCell>
        <TableCell w={100 / 5}>
          <TableText label={doc.spaces.name} />
        </TableCell>
        <TableCell w={100 / 5}>
          <TableText label={doc.spaces.space_types.name} />
        </TableCell>
        <TableCell w={100 / 5}>
          <TableText label={doc.qty} />
        </TableCell>
        <TableCell w={100 / 5}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
      </TableRow>
    </div>
  );
};

export default TRow;
