import React, { useState, useEffect } from "react";
import { Table } from "./../../../../../../../../Components/FinalTable";

import usePouchPagination from "./../../../../../../../../Hooks/usePouchPagination";

import TBody from "./TBody";

import THead from "./THeader";

import TInfo from "./TInfo";

import TContext from "./TContext";

import { useParams } from "react-router-dom";

const PouchData = ({ showLive }) => {
  const [changes, setChanges] = useState(0);
  const { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const db_name = "allocations";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed: { to_user: { $eq: true }, inventory_item_id: parseInt(id) },
  });

  useEffect(() => {
    setTimeout(() => {
      resetQuery();
    }, 3000);
  }, [changes]);

  return (
    <TContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        total,
        setIsFiltered,
        showPagination,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <p className="font-DM-Med text-lg uppercase text-cadet_grey">
          Users: Allocation history
        </p>
        <Table>
          <THead />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default PouchData;
