import React, { useContext, useEffect, useState } from "react";

import {
  TableRow,
  TableCell,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import { MdDelete } from "react-icons/md";

import useAxios from "../../../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../../../Hooks/useApp";

import usePouchCud from "../../../../../../../Hooks/usePouchCud";

import TContext from "./TContext";

const TRow = ({ doc }) => {
  const request = useAxios();
  const { deleteDoc } = usePouchCud();
  const { setModalMessage, setShowSuccessModal } = useApp();
  const { data, setData } = useContext(TContext);

  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `purchase/inventory/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Inventory Order Deleted");
      setShowSuccessModal(true);
      await deleteDoc({ name: "purchase_inventory", doc: res });
      let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
      setData(newDocs);
    }
  }

  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={doc.inventory_items.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.inventory_items.code} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.vendors.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.unit_cost} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty * doc.unit_cost} />
      </TableCell>
      <TableCell>
        <div className=" w-full flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => handleDelete()}
          >
            <MdDelete />
          </span>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
