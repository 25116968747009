import { useState, useRef, useEffect } from "react";

import FormScreen from "../../../../../../../../Components/Templates/FormScreen2";

import FormLabel from "../../../../../../../../Components/Label/FormLabel";

import { MdOutlineInventory } from "react-icons/md";

import Steppers from "./../Steppers";

import AddContext from "./AddContext";

import Forms from "./Forms";

import { useNavigate, useParams } from "react-router-dom";

const AddFixed = () => {
  const [step, setStep] = useState(-1);
  const [addInput, setAddInput] = useState({});
  const [docs, setDocs] = useState([]);
  const [changedDocs, setChangedDocs] = useState({});
  const [checkedDocs, setCheckedDocs] = useState([]);
  const [checkedVendor, setCheckedVendor] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  function handleClose() {
    navigate(`/dashboard/orders/edit/${id}`);
  }

  return (
    <AddContext.Provider
      value={{
        step,
        setStep,
        addInput,
        setAddInput,
        docs,
        setDocs,
        checkedDocs,
        setCheckedDocs,
        changedDocs,
        setChangedDocs,
        checkedVendor,
        setCheckedVendor,
      }}
    >
      <div className="w-full h-full  bg-ghost_white">
        <FormScreen heart={false} backBtn={true} back={() => handleBack()}>
          <FormLabel label={`Add to purchase order`} mt={2} mb={2}>
            <MdOutlineInventory />
          </FormLabel>
          <Steppers
            currentStep={step}
            close={() => handleClose()}
            inv={false}
          />
          <div className="w-full flex flex-row justify-center items-center px-9">
            <Forms step={step} />
          </div>
        </FormScreen>
      </div>
    </AddContext.Provider>
  );
};

export default AddFixed;
