import AllRooms from "./../SelectRoom/Table";

const SelectRoom = () => {
  return (
    <div className=" p-4 rounded-2xl bg-white h-full" style={{ width: "80%" }}>
      <AllRooms />
    </div>
  );
};

export default SelectRoom;
