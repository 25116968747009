import VendorDetails from "./VendorDetails";
import ContactPerson from "./ContactPerson";
import VendorAddress from "./VendorAddress";
import PaymentDetails from "./PaymentDetails";

const Forms = ({ step, setStep }) => {
  if (step === -1) {
    return <VendorDetails setStep={setStep} />;
  }
  if (step === 0) {
    return <ContactPerson setStep={setStep} />;
  }
  if (step === 1) {
    return <VendorAddress setStep={setStep} />;
  }

  return <PaymentDetails />;
};

export default Forms;
