import React, { useState, useContext, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import useFuzzySearch from "../../../../../../../../../Hooks/useFuzzySearch";

import usePouchPagination from "../../../../../../../../../Hooks/usePouchPagination";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import BtnPlain from "../../../../../../../../../Components/Buttons/BtnPlain";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import SearchInput2 from "../../../../../../../../../Components/Inputs/SearchInput";

import AddInvContext from "../AddContext";

import useApp from "./../../../../../../../../../Hooks/useApp";

import useAxios from "./../../../../../../../../../Hooks/useAxios";

import usePouchCud from "../../../../../../../Hooks/usePouchCud";

import { useParams, useNavigate } from "react-router-dom";

const InvTable = () => {
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { docs, changedDocs, checkedVendor } = useContext(AddInvContext);

  const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();

  const db_name = "vendors";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const { id } = useParams();

  const request = useAxios();

  const { create } = usePouchCud();

  const navigate = useNavigate();

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed: { is_active: { $eq: true } },
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
    fixed: { is_active: { $eq: true } },
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  async function handleSubmit() {
    if (checkedVendor === null || checkedVendor === -1) {
      setModalMessage("Select vendor");
      setShowFormModal(true);
      return;
    }

    let purchase_inv = [];
    let new_docs = [];

    for (let doc of docs) {
      let found = false;
      for (let doc2 of changedDocs) {
        if (doc.id === doc2.id) {
          new_docs.push(doc2);
          found = true;
          break;
        }
      }

      if (found) continue;

      new_docs.push({ ...doc, qty: 1 });
    }

    for (let i = 0; i < new_docs.length; i++) {
      let doc = new_docs[i];
      purchase_inv.push({
        inventoryItemId: parseInt(doc.id),
        purchaseOrderId: parseInt(id),
        vendorId: parseInt(checkedVendor),
        quantity: parseInt(doc.qty) || 1,
        unitCost: parseInt(doc.unit_cost),
      });
    }

    let res = await request({
      method: "POST",
      url: "purchase/inventory",
      data: purchase_inv,
    });

    //console.log(res);

    if (res !== "error") {
      for (let doc of res) {
        let res2 = await create({ name: "purchase_inventory", doc: doc });
      }

      await setModalMessage("Purchase Inventory Added");
      await setShowSuccessModal(true);

      navigate(`/dashboard/orders/edit/${id}`);
    }
  }

  return (
    <div className="w-full mt-8">
      <TContext.Provider
        value={{
          limit,
          setLimit,
          data,
          page,
          hasNextPage,
          hasPrevPage,
          handleNext,
          handlePrev,
          handleHeaderQuery,
          handleFilterQuery,
          total,
          setIsFiltered,
          showPagination,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <SearchInput2
              placeholder="Search"
              input={searchInput}
              setInput={setSearchInput}
            />
            {isFiltered ? (
              <BtnRounded click={handleClearFilters}>Clear</BtnRounded>
            ) : null}
          </div>
          <THead />
          <TBody />
          <TInfo />
          <div className="w-full flex justify-end">
            <BtnPlain bg="primary" textColor="white" click={handleSubmit}>
              Complete
            </BtnPlain>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default InvTable;
