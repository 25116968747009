import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import { Table } from "../../../../../../../../Components/FinalTable";

import TTabs from "./TTabs";

const AllTable = () => {
  return (
    <Table>
      <TTabs />
      <THead />
      <TBody />
      <TInfo />
    </Table>
  );
};

export default AllTable;
