import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { MdOutlineInventory } from "react-icons/md";

const TAction = ({ doc }) => {
  const navigate = useNavigate();

  function handleNext() {
    if (doc.status === "pending") {
      navigate(`edit/${doc.id}`);
      return;
    }
    navigate(`view/${doc.id}`);
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={handleNext} showOptions={false}></ActionBtn>
    </div>
  );
};

export default TAction;
