import React, { useState, useEffect, useRef } from "react";

import { FaPlus } from "react-icons/fa";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import Badge from "../../../../../../../Components/Badges/Badge";

import image from "../../../../../../../Assets/Images/ordersAsset.png";

import usePouch from "../../../../../../../Hooks/usePouch";

import { convDate } from "../../../../../../../Util/Time";

import OrderStatusModal from "../../../../../../../Components/Modals/OrderStatusModal";

import { MdDelete } from "react-icons/md";

const status = {
  pending: "approved",
  approved: "received",
  received: false,
  canceled: "canceled",
};

const statusDescription = {
  approved:
    "This action cannot be undone. Once approved you can't add or edit the purchase order",
  received:
    "This action cannot be undone. Once received the items will be added to the store",
  cancel: "This action cant be undone.",
};

const OrderDetails = ({ doc, setDoc }) => {
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [newStatus, setNewStatus] = useState(status[doc.status]);

  function handleChangeStatus(state) {
    setNewStatus(state);
    setShowStatusModal(true);
  }

  return (
    <div className="flex flex-col rounded-t-[30px] w-full bg-white gap-y-9 p-8">
      <div className="flex flex-col md:flex-row w-full justify-between items-start">
        <div className="flex flex-col gap-y-2">
          <img src={image} alt="Boxes Stacked" width={"200px"} />
          <h1 className="font-DM-Bold text-2xl">{doc.name}</h1>
        </div>
        <div>
          <h1 className="font-DM-Reg text-2xl">
            Order Date: {convDate(parseInt(doc.order_date))}
          </h1>
          <div className="flex items-center font-medium gap-x-2 mt-4">
            Status: <Badge label={doc.status} />
          </div>
        </div>
      </div>

      <div className="max-w-xl ">
        <h1 className="font-DM-Reg text-2xl text-[#55585B]">Description</h1>
        <p className="font-Dm-Reg text-2xl text-black">{doc.description}</p>
        <p className="text-2xl font-Dm-Reg text-cadet_grey pt-2 uppercase">
          vat:{doc.vat} %
        </p>
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex gap-x-6">
          {status[doc.status] ? (
            <BtnRounded click={() => handleChangeStatus(status[doc.status])}>
              <span className="text-sm pr-3">
                {" "}
                <FaPlus />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">
                {status[doc.status]}
              </span>
            </BtnRounded>
          ) : null}

          {doc.status === "approved" ? (
            <BtnRounded bg="red" click={() => handleChangeStatus("canceled")}>
              <span className="text-sm pr-3">
                {" "}
                <MdDelete />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">Cancel</span>
            </BtnRounded>
          ) : null}
        </div>
      </div>
      <div className=" w-full flex flex-col justify-end mt-4">
        <p className=" text-3xl text-cadet_grey">
          <small>TZS: </small>
          <span className=" font-DM-Bold">{doc.total}</span>
        </p>
        {doc.status !== "received" ? (
          <p className=" text-3xl text-cadet_grey">
            <small>Received on: </small>
            <span className=" font-DM-Bold">Order Not Received yet</span>
          </p>
        ) : (
          <p className=" text-3xl text-cadet_grey">
            <small>Received on: </small>
            <span className=" font-DM-Bold">
              {doc.receiving_date === null ? "" : convDate(parseInt(doc.receiving_date))}
            </span>
          </p>
        )}
      </div>

      <OrderStatusModal
        status={newStatus}
        id={doc.id}
        showModal={showStatusModal}
        setShowModal={setShowStatusModal}
        setDoc={setDoc}
      />
    </div>
  );
};

export default OrderDetails;
