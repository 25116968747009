import React, { useState } from "react";

import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import useAllVendors from "./../hooks/useAllVendors";

const TableHeader = () => {
  const [name, setName] = useState(true);
  const [email, setEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [status, setStatus] = useState(true);
  const [tra, setTra] = useState(true);

  const { handleHeaderQuery, handleFilterQuery, setIsFiltered, showLive } =
    useAllVendors();

  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }
    setIsFiltered(false);
    const index = ["name"];
    const sort = [{ name: name ? "asc" : "desc" }];
    const selector = { name: { $gte: null } };

    setName(!name);

    handleHeaderQuery(index, sort, selector);
  }

  function handleEmailSort() {
    if (showLive) {
      handleHeaderQuery("email", email ? "asc" : "desc");
      setEmail(!email);

      return;
    }

    setIsFiltered(false);
    const index = ["email"];
    const sort = [{ email: email ? "asc" : "desc" }];
    const selector = { email: { $gte: null } };

    setEmail(!email);

    handleHeaderQuery(index, sort, selector);
  }

  function handlePhoneSort() {
    if (showLive) {
      handleHeaderQuery("phone", phone ? "asc" : "desc");
      setPhone(!phone);

      return;
    }

    setIsFiltered(false);
    const index = ["phone"];
    const sort = [{ phone: phone ? "asc" : "desc" }];
    const selector = { phone: { $gte: null } };
    setPhone(!phone);
    handleHeaderQuery(index, sort, selector);
  }

  function handleTraSort() {
    if (showLive) {
      handleHeaderQuery("tra_pin", tra ? "asc" : "desc");
      setTra(!tra);

      return;
    }

    setIsFiltered(false);
    const index = ["tra_pin"];
    const sort = [{ tra: tra ? "asc" : "desc" }];
    const selector = { tra: { $gte: null } };
    setTra(!tra);
    handleHeaderQuery(index, sort, selector);
  }

  function handleStatusFilter() {
    //console.log(showLive);

    if (showLive) {
      //console.log(status);
      handleHeaderQuery("is_active", status ? "asc" : "desc");
      setStatus(!status);
      setIsFiltered(true);
      return;
    }

    setIsFiltered(true);
    const index = ["is_active"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };
    const secondarySelector = {
      is_active: {
        $eq: status,
      },
    };

    handleFilterQuery(index, sort, selector, secondarySelector);
    setStatus(!status);
    setIsFiltered(true);
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="NAME" click={handleNameSort} />
      <HeaderBtnSort w={90 / 5} label="EMAIL" click={handleEmailSort} />
      <HeaderBtnSort w={90 / 5} label="PHONE" click={handlePhoneSort} />
      <HeaderBtnSort w={90 / 5} label="TRA" click={handleTraSort} />
      <HeaderBtnSort w={90 / 5} label="Status" click={handleStatusFilter} />

      <div style={{ width: "10%" }}></div>
    </TableHeaderRow>
  );
};

export default TableHeader;
