import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import HeartBeat from "../../../../../../../Assets/Images/heartAsset.png"
import BtnPlain from '../../../../../../../Components/Buttons/BtnPlain';


const Footer = ({ step, setStep, stepsCount}) => {

  const handleBack = ()=>{
    setStep(prev=> prev-1)
  }

  return (
    <div className='flex justify-between py-9'>
        <div>
            {
                step-1 >= 0 && 
                <BtnPlain  click={handleBack} className="btn">
                    <span className="pl-10 font-normal text-primary text-2xl">
                        <FaRegArrowAltCircleLeft/>
                    </span>
                    <span className="pl-5 font-DM-Bold uppercase text-xl underline">back</span>
                </BtnPlain>
            }
        </div>
        <img src={HeartBeat} alt="" />
    </div>
  )
}

export default Footer