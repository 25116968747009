import React, { useState, useEffect } from "react";
import { Table } from "./../../../../../../../../Components/FinalTable";

import TBody from "./TBody";

import THead from "./THeader";

import TInfo from "./TInfo";

import TContext from "./TContext";
import useAxios from "../../../../../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";

const LiveData = ({ showLive, setShowLive }) => {
    const {id} = useParams()
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState("created_at");
    const [inventory_item_id, setinventory_item_id] = useState(`${id}`)
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [data, setData] = useState([]);
    const [showPagination, setShowPagination] = useState(true);
    const [filters, setFilters] = useState({});
    
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
  
    const request = useAxios();
    useEffect(() => {
        getUserAllocations(1, "created_at", "desc", `${id}`, filters);
      }, [limit]);
    
      
    
      async function getUserAllocations(
        page = 1,
        sort_field = "created_at",
        sort_order = "desc",
        inventory_item_id = `${id}`,
        filters = {}
      ) {
        setTableIsLoading(true);
        let res = await request({
          method: "GET",
          url: "store/allocations",
          params: {
            page,
            limit,
            sort_field: sort_field,
            sort_order: sort_order,
            inventory_item_id: inventory_item_id,
            ...filters,
          },
          network_error: false,
        });
    
        if (res === "network-error") {
          setShowLive(false);
          return;
        }
    
        setTableIsLoading(false);
    
        if (res === "error") return;
        setData(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPrevPage(res.hasPrevPage);
      }
    
      async function handleNext() {
        if (hasNextPage) {
          setPage(page + 1);
          getUserAllocations(page + 1, sortField, sortOrder, inventory_item_id);
        }
      }
    
      async function handlePrev() {
        if (hasPrevPage) {
          setPage(page - 1);
          getUserAllocations(page - 1, sortField, sortOrder, inventory_item_id);
        }
      }
    
      async function handleFilterQuery(filters) {
        setSortField("created_at");
        setSortOrder("desc");
        getUserAllocations(1, "created_at", "desc", `${id}`, filters);
    
        setIsFiltered(true);
        setFilters(filters);
      }
    
      async function handleHeaderQuery(field, order) {
        setPage(1);
        setSortOrder(order);
        setSortField(field);
        getUserAllocations(1, field, order, inventory_item_id);
      }
    
      async function handleClearFilters() {
        await getUserAllocations(1, "created_at", "desc", `${id}`);
        setPage(1);
        setSortField("created_at");
        setSortOrder("desc");
        setIsFiltered(false);
        setShowPagination(true);
      }
    
  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <p className="font-DM-Med text-lg uppercase text-cadet_grey">
          Users: Allocation history
        </p>
        <Table>
          <THead />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default LiveData;
