import React, { useState } from "react";

import { FaPlus } from "react-icons/fa";

import usePouchPagination from "../../../../../../../../../Hooks/usePouchPagination";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import { useParams, useNavigate } from "react-router-dom";

const InvTable = ({ po, inv, setInv }) => {
  const navigate = useNavigate();

  console.log("Inventory Table");

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          data: inv,
          setData: setInv,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Inventory items
            </p>
            <BtnRounded click={() => navigate("add/inventory")}>
              <span className="text-sm pr-3">
                {" "}
                <FaPlus />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">
                Add Inventory Item
              </span>
            </BtnRounded>
          </div>

          <THead />
          <TBody />

          <div className=" w-full flex justify-end">
            <p className=" text-2xl text-cadet_grey">
              <small>TZS: </small>
              <span className=" font-DM-Bold">{po.inventory_total}</span>
            </p>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default InvTable;
