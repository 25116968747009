import React, { useContext, useState } from "react";

import { SingleCheckBox } from "./../../../../../../../../Components/FinalTable";

import {
  TableInfoCell,
  TableInfoRow,
  TablePagination,
} from "./../../../../../../../../Components/FinalTable";

import TeamContext from "./TeamContext";
const TableInfoSection = () => {
  const {
    limit,
    setLimit,
    total,
    data,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    showPagination,
  } = useContext(TeamContext);

  return (
    <TableInfoRow mt={20}>
      <TableInfoCell limit={5} setLimit={() => {}} total={total} docs={data} />
      {showPagination ? (
        <TablePagination
          page={page}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextClick={handleNext}
          prevClick={handlePrev}
        />
      ) : null}
    </TableInfoRow>
  );
};

export default TableInfoSection;
