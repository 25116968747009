import React, { } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../Components/FinalTable";

import TableActions from "./TableActions";
import { convDate } from "../../../../../Util/Time";
import Badge from "../../../../../Components/Badges/Badge";


const TRow = ({ doc }) => {

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 6}>
        <TableText label={`${doc.inventory_items ? doc.inventory_items.name : doc.fixed_assets ?  doc.fixed_assets.name : ""}`} />  
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.is_inventory ? "INVENTORY" : "FIXED ASSET"} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <Badge
          label={doc.status}
          bg={
            doc.status === "pending"
              ? "pale_spring_bud"
              : doc.status === "canceled"
              ? "pale_pink"
              : "blue"
          }
          textColor={
            doc.status === "pending"
              ? "blue"
              : doc.status === "canceled"
              ? "blue"
              : "white"
          }
        />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText  />
      </TableCell>
      <TableCell >
        <TableActions doc={doc} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
