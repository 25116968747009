import { useState, useRef, useEffect } from "react";

import { MdOutlineInventory } from "react-icons/md";

import FormScreen from "../../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import AllocateSteppers from "./Components/AllocateSteppers";

import Forms from "./Forms";

import AllocateContext from "./AllocateContex";

import usePouch from "../../../../../../Hooks/usePouch";

import { useParams, useNavigate } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";
import useAxios from "../../../../../../Hooks/useAxios";
import CancelBar from "../../../../../../Components/Steppers/CancelBar";

const AllocateInventory = () => {
  const [step, setStep] = useState(-1);

  const { id } = useParams();

  const [allocate, setAllocate] = useState({ to_user: "" });
  const [inv, setInv] = useState({});

  const { setIsLoading, setShowFormModal, setModalMessage } = useApp();

  const request = useAxios()

  const navigate = useNavigate();

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        let res = await request({
          method: "GET",
          url: `inventory/single/${id}`,
        });
        setInv(res);
      } catch (e) {
        setModalMessage("Error, Inventory item not found");
        setShowFormModal(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <AllocateContext.Provider
      value={{
        allocate,
        setAllocate,
        step,
        setStep,
        inv,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label={`Allocate: ${inv.name} `} mt={2} mb={2}>
          <MdOutlineInventory />
        </FormLabel>
        
        <AllocateSteppers currentStep={step} close={()=>{navigate(-1)}}/>
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} allocate={allocate} />
        </div>
      </FormScreen>
    </AllocateContext.Provider>
  );
};

export default AllocateInventory;
