import { useState, useEffect } from "react";

import { FaUserEdit } from "react-icons/fa";

import FormScreen from "../../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import EditVendorContext from "./context/editVendor";

import { useParams, useNavigate } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";

import useAxios from "../../../../../../Hooks/useAxios";

const EditVendor = () => {
  const { id } = useParams();

  const [step, setStep] = useState(-1);

  const { setIsLoading } = useApp();

  const navigate = useNavigate();

  const [vendor, setVendor] = useState(null);

  const [contactPerson, setContactPerson] = useState(null);

  const [address, setAddress] = useState(null);

  const [payment, setPayment] = useState(null);

  const request = useAxios();

  useEffect(() => {
    async function fetchVendoretails() {
      try {
        setIsLoading(true);


        let res = await request({
          method: "GET",
          url: `vendor/${id}`,
          network_error: false,
        });

        if (res === "network-error") {
          navigate("../");
          return;
        }
    
    
          if (res === "error") return;
  
          setVendor({
            id: res.id,
            name: res.name,
            email: res.email,
            phone: res.phone,
            traPin: res.tra_pin,
            bio: res.bio,
          });

          setAddress({
          id: res.vendor_address.id,
          city: res.vendor_address.city,
          country: res.vendor_address.country,
          addressOne: res.vendor_address.address_1,
          addressTwo: res.vendor_address.address_2,
          addressThree: res.vendor_address.address_3,
          postalCode: res.vendor_address.postal_code,
          });

          setContactPerson({
            id: res.vendor_contact_person.id,
            name: res.vendor_contact_person.name,
            email: res.vendor_contact_person.email,
            phone: res.vendor_contact_person.phone,
          });

          setPayment({
            id: res.vendor_payment_details.id,
            bankName: res.vendor_payment_details.bank_name,
            accountNo: res.vendor_payment_details.acc_no,
            paybillNo: res.vendor_payment_details.mpesa_paybill_no,
            buyGoodsNo: res.vendor_payment_details.mpesa_buy_good_no,
          });

      } catch (e) {
        navigate("/dashboard/vendors");
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, [2000]);
      }
    }
    fetchVendoretails();
  }, []);

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <EditVendorContext.Provider
      value={{
        vendor,
        setVendor,
        contactPerson,
        setContactPerson,
        address,
        setAddress,
        payment,
        setPayment,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Edit Vendor" mt={2} mb={2}>
          <FaUserEdit />
        </FormLabel>
        <AddSteppers currentStep={step} close={() => {}} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </EditVendorContext.Provider>
  );
};

export default EditVendor;
