import { useEffect, useState } from "react";
import Badge from "../../../../../../../../Components/Badges/Badge";
import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../../Util/Time";

import TAction from "./TActions";

const TRow = ({ doc }) => {
  return (
    <div className=" w-full">
      <TableRow>
        <TableCell w={90 / 6}>
          <TableText label={`ord-${doc.id}`} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={`${doc.vat} %`} />
        </TableCell>
        <TableCell w={90 / 6}>
          <Badge label={doc.status} bg={doc.status === "pending" ? "pale_pink" : "pale_spring_bud"} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={`Created: ${convDate(doc.created_at)}`} />
          {doc.status === "received" ? (
            <TableText
              label={`Received: ${convDate(parseInt(doc.receiving_date)) || ""}`}
            />
          ) : null}
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.total} />
        </TableCell>
        <TAction doc={doc} />
      </TableRow>
    </div>
  );
};

export default TRow;
