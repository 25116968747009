import React, { useState } from "react";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";


const FixedAssets = ({ po, fixed, setFixed }) => {


  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          data: fixed,
          setData: setFixed,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Fixed Assets
            </p>
            
          </div>

          <THead />
          <TBody />

          <div className=" w-full flex justify-end">
            <p className=" text-2xl text-cadet_grey">
              <small>TZS: </small>
              <span className=" font-DM-Bold">{po.fixed_total}</span>
            </p>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default FixedAssets;
