import React from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import TableHeader from "./TableHeader";

import TableTabs from "./TableTabs";

import TableBody from "./TableBody";

import TableInfoSection from "./TableInfoSection";
import useAllInventory from "../Hooks/useAllInventory";

const AllInventory = () => {
  const { showLive } = useAllInventory();
  return (
    <Table>
      {showLive && <TableTabs />}
      <TableHeader />
      <TableBody />
      <TableInfoSection />
    </Table>
  );
};

export default AllInventory;
