import PayImg from "../../../../../../../Assets/Images/borrow money.png";

const PaymentDetails = ({ paymentDetails }) => {
  const { bank_name, acc_no, mpesa_paybill_no, mpesa_buy_good_no } =
    paymentDetails;

  return (
    <div
      className=" bg-white rounded-md shadow-lg mt-5 p-5 ml-5 flex w-full justify-between "
      style={{ width: 428, height: 161 }}
    >
      <div>
        <p className=" font-DM-Bold"> PAYMENT DETAILS</p>
        <img src={PayImg} alt="borrow money " />
      </div>

      <div>
        <p className=" text-sm text-[#98A1B5] font-DM-Bold">BANK NAME</p>
        <p className=" text-sm mb-5 font-DM-Reg">{bank_name}</p>

        <p className=" text-sm text-[#98A1B5] font-DM-Bold">MPESA BUY GOODS</p>
        <p className=" text-sm mb-5 font-DM-Reg">{mpesa_buy_good_no}</p>
      </div>

      <div>
        <p className=" text-sm text-[#98A1B5] font-DM-Bold">MPESA PAYBILL</p>
        <p className=" text-sm mb-5 font-DM-Reg">{mpesa_paybill_no}</p>
        <p className=" text-sm text-[#98A1B5] font-DM-Bold">ACCOUNT NUMBER</p>
        <p className=" text-sm font-DM-Reg">{acc_no}</p>
      </div>
    </div>
  );
};

export default PaymentDetails;
