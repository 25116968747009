import React, { useContext, useEffect, useState } from "react";

import {
  TableRow,
  TableCell,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import usePouch from "../../../../../../../../../Hooks/usePouch";

import { limitText } from "../../../../../../../../../Util/text";

import { convDate } from "../../../../../../../../../Util/Time";

import useAxios from "../../../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../../../Hooks/useApp";

import usePouchCud from "../../../../../../../Hooks/usePouchCud";

import TContext from "./TContext";

const TRow = ({ doc }) => {
  const { data, setData } = useContext(TContext);
  const request = useAxios();
  const { setModalMessage, setShowSuccessModal } = useApp();

  const { deleteDoc } = usePouchCud();

  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `purchase/other/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Charge Deleted");
      setShowSuccessModal(true);
      await deleteDoc({ name: "purchase_other_charges", doc: res });
      let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
      setData(newDocs);
    }
  }

  return (
    <div className="w-full">
      <TableRow>
        <TableCell w={90 / 4}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={doc.amount} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={limitText(doc.description, 30)} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <div className=" flex-1 flex justify-center gap-x-4 text-3xl text-primary">
          <span
            className=" active:opacity-50 cursor-pointer text-red"
            onClick={() => handleDelete()}
          >
            <MdDelete />
          </span>
        </div>
      </TableRow>
    </div>
  );
};

export default TRow;
