import { useContext, useState } from "react";

import { Table } from "../../../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../../../Hooks/usePouchPagination";

import { TableTopBar } from "../../../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../../../Hooks/useFuzzySearch";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../../../../Hooks/useApp";

import AllocateContext from "../../AllocateContex";

const PouchData = ({showLive}) => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const { setShowFormModal, setModalMessage } = useApp();
  const [docs, setDocs] = useState(0);
  const navigate = useNavigate();

  const db_name = "spaces";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const { setStep, setAllocate, allocate } = useContext(AllocateContext);

  const searchOptions = {
    keys: ["name", "no", "description"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Space");
      setShowFormModal(true);
      return;
    }
    setAllocate({ ...allocate, to: checked_id });
    setStep((c) => c + 1);
  }

  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="px-7 pb-8">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          changes={0}
          showFilter={false}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Space</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default PouchData;
