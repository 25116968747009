import React, { useState, useEffect, useContext } from "react";

import { TableTabs } from "../../../../../../../../Components/FinalTable";

import usePouchCount from "../../../../../../../../Hooks/usePouchCount";

import AllContext from "../../Context";

const TTabs = () => {
  
  const {
    setFixed,
    resetQuery,
    isFiltered,
    setIsFiltered,
    showLive,
    tabsData,
    handleFilterQuery,
    handleClearFilters,
    
  } = useContext(AllContext);

  const [tabs, setTabs] = useState([
    { title: `All (${tabsData.all || 0 })`, color: "primary", no: null },
    { title: "Approved", color: "primary", no: null },
    { title: "Pending", color: "pale_pink", no: null },
    { title: "Cancelled", color: "red", no: null },
    { title: "Received", color: "primary", no: null },
  ]);

  const [activeTab, setActiveTab] = useState("All");

  const countDocs = usePouchCount();

  useEffect(()=>{
    setTabs([
      {title: `All (${tabsData.all || 0 })`, color: "primary", no: null},
      {title: `Approved (${tabsData.approved || 0})`, color: "primary", no: null},
      {title: `Pending (${tabsData.pending || 0})`, color: "pale_pink", no: null},
      {title: `Cancelled (${tabsData.canceled || 0})`, color: "red", no: null},
      {title: `Received (${tabsData.received || 0})`, color: "primary", no: null},
    ]);
  },[tabsData])

  useEffect(() => {
    if (isFiltered) return;
    setActiveTab("All");
  }, [isFiltered]);

  useEffect(() => {
    const countAsync = async () => {
      let db_name = "store_request";
      let all = await countDocs({
        db_name,
        selector: {},
      });

      let approved_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "approved"},
        },
      });
      let pending_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "pending" },
        },
      });

      let cancelled_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "canceled"}
        },
      });

      let received_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "received"}
        },
      });

      
      const newTabs = [...tabs];

      newTabs[0].no = all;
      newTabs[1].no = approved_Docs;
      newTabs[2].no = pending_Docs;
      newTabs[3].no = cancelled_Docs;
      newTabs[4].no = received_Docs;
      

      setTabs(newTabs);

    };
    countAsync();
  }, []);

  async function handleTab(tab) {
    setActiveTab(tab.title);
    if (tab.title.includes("All")) {
      if(showLive){
        handleFilterQuery({})
        return;
      }
      setFixed({});
      handleClearFilters();
      return;
    }

    if (tab.title.includes("Approved")) {
      if(showLive){
        handleFilterQuery({filter_status: "approved"})
        return;
      }
      setFixed({
        status: { $eq: "approved"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    }

    if (tab.title.includes("Pending")) {
      if(showLive){
        handleFilterQuery({filter_status: "pending"})
        return;
      }
      setFixed({
        status: { $eq: "pending"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    }

    if (tab.title.includes("Cancelled")) {
      if(showLive){
        handleFilterQuery({filter_status: "canceled"})
        return;
      }
      setFixed({
        status:{ $eq: "canceled"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    };

    if (tab.title.includes("Received")) {
      if(showLive){
        handleFilterQuery({filter_status: "received"})
        return;
      }
      setFixed({
        status:{ $eq: "received"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    };
   

  };

  return <TableTabs tabs={tabs} activeTab={activeTab} handleTab={handleTab} />;
};

export default TTabs;
