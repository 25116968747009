import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import SignIn from "./Pages/SignIn";

import Dashboard from "./Pages/Dashboard";

import Summary from "./Pages/Dashboard/Features/Summary";

import SummaryTwo from "./Pages/Dashboard/Features/SummaryTwo";

import Vendors from "./Pages/Dashboard/Features/Vendors";
import AllVendors from "./Pages/Dashboard/Features/Vendors/Pages/All";
import AddVendor from "./Pages/Dashboard/Features/Vendors/Pages/Add";
import EditVendor from "./Pages/Dashboard/Features/Vendors/Pages/Edit";
import SingleVendor from "./Pages/Dashboard/Features/Vendors/Pages/SingleVendor";

import PurchaseOrders from "./Pages/Dashboard/Features/PurchaseOrders";
import AddPurchaseOrder from "./Pages/Dashboard/Features/PurchaseOrders/Pages/Add";
import EditPurchaseOrder from "./Pages/Dashboard/Features/PurchaseOrders/Pages/Edit";
import ViewPurchaseOrder from "./Pages/Dashboard/Features/PurchaseOrders/Pages/ViewP";
import AddPurchaseInventory from "./Pages/Dashboard/Features/PurchaseOrders/Pages/Edit/components/AddInv";
import AddPurchaseFixed from "./Pages/Dashboard/Features/PurchaseOrders/Pages/Edit/components/AddFixed";
import AddPurchaseOrderDetails from "./Pages/Dashboard/Features/PurchaseOrders/Pages/AddDetails";

//import SinglePurchaseOrder from "./Pages/Dashboard/Screens/PurchaseOrders/Pages/SinglePurchaseOrder";
import AllPurchaseOrders from "./Pages/Dashboard/Features/PurchaseOrders/Pages/All";

import Requests from "./Pages/Dashboard/Features/Requests";

import InventoryItems from "./Pages/Dashboard/Features/InventoryItems";
import AllInventoryItems from "./Pages/Dashboard/Features/InventoryItems/Pages/All";
import AddInventoryItems from "./Pages/Dashboard/Features/InventoryItems/Pages/Add";
import EditInventoryItems from "./Pages/Dashboard/Features/InventoryItems/Pages/Edit";
import AllocateInventoryItem from "./Pages/Dashboard/Features/InventoryItems/Pages/AllocateInventoryItem";
import SingleInventoryItem from "./Pages/Dashboard/Features/InventoryItems/Pages/SingleInventoryItem";

import FixedAssests from "./Pages/Dashboard/Features/FixedAssets";
import AddFixed from "./Pages/Dashboard/Features/FixedAssets/Pages/Add";
import EditFixed from "./Pages/Dashboard/Features/FixedAssets/Pages/Edit";
import AllFixedAssets from "./Pages/Dashboard/Features/FixedAssets/Pages/All";
import AllocateFixedAsset from "./Pages/Dashboard/Features/FixedAssets/Pages/AllocateFixesAsset";
import SingleFixedAsset from "./Pages/Dashboard/Features/FixedAssets/Pages/SingleFixedAsset";

import Notifications from "./Pages/Dashboard/Features/Notifications";

// import Settings from "./Pages/Dashboard/Features/Settings/index";
// import ViewSettings from "./Pages/Dashboard/Features/Settings/ViewSettings";
// import EditSettings from "./Pages/Dashboard/Features/Settings/EditSettings";

import NotFound from "./Pages/404";

import Protected from "./Protected";

import AppContext from "./Context/AppContext";

// import useAxios from "./Hooks/useAxios";

import LoadingModal from "./Components/Modals/LoadingModal";

import RequestModal from "./Components/Modals/RequestModal";

import FormModal from "./Components/Modals/FormModal";

import SuccessModal from "./Components/Modals/SuccessModal";

const APP = () => {
  // const request = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [isOnline, setIsOnline] = useState(true);

  const [user, setUser] = useState({ name: "na" });

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [modal_type, setModalType] = useState(null);
  const [modal_message, setModalMessage] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <AppContext.Provider
      value={{
        setIsLoading,
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        token,
        setToken,
        showRequestModal,
        setShowRequestModal,
        modal_type,
        setModalType,
        modal_message,
        setModalMessage,
        setShowFormModal,
        setShowSuccessModal,
        isOnline,
        setIsOnline,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<SignIn />} />
          <Route path="" element={<Protected />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<SummaryTwo />} />
              <Route path="summary" element={<SummaryTwo />} />
              <Route path="vendors" element={<Vendors />}>
                <Route path="" element={<AllVendors />} />
                <Route path="add" element={<AddVendor />} />
                <Route path="edit/:id" element={<EditVendor />} />
                <Route path="view/:id" element={<SingleVendor />} />
              </Route>
              <Route path="orders" element={<PurchaseOrders />}>
                <Route path="" element={<AllPurchaseOrders />} />
                <Route path="add" element={<AddPurchaseOrder />} />
                <Route
                  path="add/details"
                  element={<AddPurchaseOrderDetails />}
                />
                <Route path="edit/:id" element={<EditPurchaseOrder />} />
                <Route
                  path="edit/:id/add/inventory"
                  element={<AddPurchaseInventory />}
                />
                <Route
                  path="edit/:id/add/fixed"
                  element={<AddPurchaseFixed />}
                />
                <Route path="view/:id" element={<ViewPurchaseOrder />} />
              </Route>
              <Route path="inventory" element={<InventoryItems />}>
                <Route path="" element={<AllInventoryItems />} />
                <Route path="add" element={<AddInventoryItems />} />
                <Route path="edit/:id" element={<EditInventoryItems />} />
                <Route
                  path="allocate/:id"
                  element={<AllocateInventoryItem />}
                />
                <Route path="view/:id" element={<SingleInventoryItem />} />
              </Route>
              <Route path="fixed-asset" element={<FixedAssests />}>
                <Route path="" element={<AllFixedAssets />} />
                <Route path="add" element={<AddFixed />} />
                <Route path="edit/:id" element={<EditFixed />} />
                <Route path="allocate/:id" element={<AllocateFixedAsset />} />
                <Route path="view/:id" element={<SingleFixedAsset />} />
              </Route>
              <Route path="requests" element={<Requests />} />
              <Route path="notifications" element={<Notifications />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <LoadingModal showModal={isLoading} />
      <RequestModal
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        modal_type={modal_type}
        modal_message={modal_message}
      />
      <FormModal
        showFormModal={showFormModal}
        setShowFormModal={setShowFormModal}
        modal_message={modal_message}
      />
      <SuccessModal
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
        modal_message={modal_message}
      />
    </AppContext.Provider>
  );
};

export default APP;
