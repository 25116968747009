import { useState, useEffect, useRef } from "react";
import FilterModal from "../../../../../../../Components/Modals/FilterModal";
import NumberInput from "./../../../../../../../Components/Inputs/NumberInput";
import CheckBox from "../../../../../../../Components/Inputs/CheckBox";
import DatePicker from "../../../../../../../Components/Inputs/DatePicker";

import useAxios from "../../../../../../../Hooks/useAxios";

import usePouch from "../../../../../../../Hooks/usePouch";

import useAllInventory from "../Hooks/useAllInventory";

import useApp from "./../../../../../../../Hooks/useApp";

const AllItemsFilter = ({ open, setOpen }) => {
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState([]);

  const request = useAxios();

  const { findAll } = usePouch();

  const { setIsFiltered, handleFilterQuery } = useAllInventory();

  const input_initial = {
    form: {
      min_qty: "",
      max_qty: "",
      max_price: "",
      min_price: "",
      is_expired: "",
      reoder: "",
    },
    err: {},
  };

  const input = useRef(input_initial);

  const catFilter = useRef({ form: {} });
  const mesFilter = useRef({ form: {} });

  const { setIsLoading, setModalMessage, setShowFormModal } = useApp();

  useEffect(() => {
    async function fetchEnums() {
      try {
        setIsLoading(true);
        let res = await request({
          method: "GET",
          url: "inventory/enums",
        });
        setCategories(res.categories);
        setMeasures(res.unit_of_measures);
      } catch (e) {
        //console.log(e)
        setOpen(false);
      } finally {
        setIsLoading(false);
      }
    }
    fetchEnums();
  }, []);

  async function applyFilters() {
    let filters = {};
    let to_filter = false;
    let catKeys = Object.keys(catFilter.current.form);
    let mesKeys = Object.keys(mesFilter.current.form);
    let the_catFilter = [];
    let the_measureFilter = [];

    if (catFilter.current.form !== "") {
      for (let i = 0; i < catKeys.length; i++) {
        let key = catKeys[i];
        filters.filter_category = parseInt(key);
        to_filter = true;
        catFilter.current.form = "";
      }
    }

    if (mesFilter.current.form !== "") {
      for (let i = 0; i < mesKeys.length; i++) {
        let key = catKeys[i];
        filters.filter_unit_of_measure = parseInt(key);
        to_filter = true;
        mesFilter.current.form = "";
      }
    }

    if (input.current.form.min_qty !== "") {
      filters.filter_min_quantity = parseInt(input.current.form.min_qty);
      to_filter = true;
    }

    if (input.current.form.max_qty !== "") {
      filters.filter_max_quantity = parseInt(input.current.form.max_qty);
      to_filter = true;
    }

    if (input.current.form.min_price !== "") {
      filters.filter_min_price = parseInt(input.current.form.min_price);
      to_filter = true;
    }

    if (input.current.form.max_price !== "") {
      filters.filter_max_price = parseInt(input.current.form.max_price);
      to_filter = true;
    }
    if (input.current.form.is_expired !== "") {
      filters.filter_expired = input.current.form.is_expired;
      to_filter = true;
    }

    if (to_filter) {
      handleFilterQuery(filters);
      setOpen(false);
      return;
    }

    setOpen(true);
  }
  if (!setOpen) return null;

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters}>
      <div style={{ width: "320px" }}>
        {/* <div className="flex flex-col py-6 gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Categories
          </h3>
          <div className=" flex gap-x-4">
            <div className=" flex flex-col gap-y-2 ">
              {categories.map((categ, index) => {
                return index % 2 === 0 ? (
                  <CheckBox
                    key={categ.id}
                    input={catFilter}
                    inputKey={categ.id}
                    label={categ.name}
                  />
                ) : null;
              })}
            </div>
            <div className=" flex flex-col gap-y-2 ">
              {categories.map((categ, index) => {
                return index % 2 === 0 ? null : (
                  <CheckBox
                    key={categ.id}
                    label={categ.name}
                    input={catFilter}
                    inputKey={categ.id}
                  />
                );
              })}
            </div>
          </div>
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Unit of measures
          </h3>
          <div className=" flex gap-x-4">
            <div className=" flex flex-col gap-y-2 ">
              {measures.map((measure, index) => {
                return index % 2 === 0 ? (
                  <CheckBox
                    key={measure.id}
                    input={mesFilter}
                    inputKey={measure.id}
                    mesFilter={measure.name}
                    label={measure.name}
                  />
                ) : null;
              })}
            </div>
            <div className=" flex flex-col gap-y-2 ">
              {measures.map((measure, index) => {
                return index % 2 === 0 ? null : (
                  <CheckBox
                    key={measure.id}
                    input={mesFilter}
                    inputKey={measure.id}
                    mesFilter={measure.name}
                    label={measure.name}
                  />
                );
              })}
            </div>
          </div>
        </div> */}

        <div className="flex flex-col  gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Qty Filter
          </h3>
          <div className=" flex gap-x-2" style={{}}>
            <div className="flex-1 ">
              <NumberInput
                input={input}
                inputKey="max_qty"
                initial={""}
                config={{
                  required: false,
                  label: "Max Qty",
                  min: 0,
                }}
              />
            </div>
            <div className=" flex-1">
              <NumberInput
                input={input}
                inputKey="min_qty"
                initial={""}
                config={{
                  required: false,
                  label: "Min Qty",
                  min: 0,
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col  gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Price Filter
          </h3>
          <div className=" flex gap-x-2" style={{}}>
            <div className="flex-1 ">
              <NumberInput
                input={input}
                inputKey="max_price"
                initial={""}
                config={{
                  required: false,
                  label: "Max Price",
                  min: 0,
                }}
              />
            </div>
            <div className=" flex-1">
              <NumberInput
                input={input}
                inputKey="min_price"
                initial={""}
                config={{
                  required: false,
                  label: "Min Price",
                  min: 0,
                }}
              />
            </div>
          </div>
          <div>
            <h3 className="uppercase mt-2 mb-2  font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              Other Filters
            </h3>
            <CheckBox
              label={"expired ?"}
              input={input}
              inputKey="is_expired"
              initial=""
            />
          </div>
        </div>
      </div>
    </FilterModal>
  );
};

export default AllItemsFilter;
