import React from 'react'

import HeaderBtn from '../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../../../Components/FinalTable'

const THeader = () => {
  return (
    <TableHeaderRow>
      <HeaderBtn w={90 / 6} label="NAME"  />
      <HeaderBtn w={90 / 6} label="TYPE"  />
      <HeaderBtn w={90 / 6} label="NO" />
      <HeaderBtn w={90 / 6} label="DESCRIPTION" />
      <HeaderBtn w={90 / 6} label="DATE CREATED" />
      
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader