import { useContext, useEffect, useRef, useState } from "react";
import NumberInput from "../../../../../../Components/Inputs/NumberInput";
import BasicModal from "../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";
import { useNavigate } from "react-router-dom";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

import AllRequestsContext from "../../context/AllRequestsContext";

const ApproveRequest = ({ showModal = false, setShowModal, id, qtyy }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  const { getAllRequests } = useContext(AllRequestsContext);

  const request = useAxios();
  const formErrors = useHandleFormError();
  const { setModalMessage, setShowSuccessModal } = useApp();

  const handleSubmit = async () => {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let datas = {
      id: parseInt(id),
      qty: parseInt(input.current.form.qty),
      status: "approved",
    };

    let res = await request({
      method: "PUT",
      url: "store/request/status",
      data: datas,
    });

    if (res === "error") return;

    setShowModal(false);
    setModalMessage("Request Approved!");
    setShowSuccessModal(true);

    await getAllRequests();
  };

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Select Quantity to be assigned
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <NumberInput
            initial={qtyy}
            inputKey="qty"
            input={input}
            mt={0.5}
            showError={showError}
            config={{
              required: true,
              label: "Quantity",
              placeholder: "14",
              min: 0,
              max: 999999,
            }}
          />

          <BtnBlock
            click={() => handleSubmit()}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Approve</span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default ApproveRequest;
