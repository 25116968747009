import React, { useState } from 'react'

import HeaderBtnSort from '../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'

import HeaderBtn from '../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../Components/FinalTable'

  import useAllRequests from '../hooks/useAllRquests'

const TableHeader = () => {
  const [qty, setQty] = useState(true);
  const [type, setType] = useState(true);
  const [status, setStatus] = useState(true)
  const [created, setCreated] = useState(true);

  const { handleHeaderQuery, showLive} = useAllRequests();

  function handleQtySort() {
    if (showLive){
      handleHeaderQuery("qty", qty ? "asc" : "desc")
      setQty(!qty);
      return;
    }
    
  }

  function handleStatusSort() {
    if (showLive) {
      handleHeaderQuery("status", status ? "asc" : "desc");
      setStatus(!status);
      return;
    }
      
  }

  function handleTypeSort() {
    if (showLive){
      handleHeaderQuery("created_at", type ? "asc" : "desc");
      setType(!type);
      return;
    }
    }
    

  function handleDateSort(){
    if (showLive){
        handleHeaderQuery("created_at", created ? "asc" : "desc");
        setCreated(!created)
        return;
    }
   }

 
  return (
    <TableHeaderRow>
      <HeaderBtn w={90 / 6} label="ITEM"  />
      <HeaderBtnSort w={90 / 6} label="TYPE" click={() => handleTypeSort()} />
      <HeaderBtnSort w={90 / 6} label="QUANTITY" click={handleQtySort} />
      <HeaderBtnSort w={90 / 6} label="STATUS" click={() => handleStatusSort()}  />
       <HeaderBtnSort w={90 / 6} label="DATE CREATED" click={() => handleDateSort()} />
       <HeaderBtn w={90 / 6} label="" />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default TableHeader