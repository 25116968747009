import React, { useEffect, useState, useContext } from "react";

import {
  TableRow,
  TableCell,
  TableText,
} from "../../../../../../../../../Components/FinalTable";

import AddInvContext from "../AddInvContext";

import MultiCheckBox from "./../../Shared/MultiCheckbox";

import NumberInput from "../../Shared/NumberInput";

const TRow = ({ doc }) => {
  const { checkedDocs, setCheckedDocs, changedDocs, setChangedDocs } =
    useContext(AddInvContext);

  const [unitCost, setUnitCost] = useState(doc.unit_cost || 1);

  const [qty, setQty] = useState(1);

  useEffect(() => {
    if (changedDocs[doc.id]) {
      setUnitCost(changedDocs[doc.id].unit_cost);
      setQty(changedDocs[doc.id].qty || 1);
    }
  }, []);

  return (
    <TableRow>
      <TableCell w={90 / 5}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.inventory_item_categories.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.unit_of_measures.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <NumberInput
          input={unitCost}
          setInput={setUnitCost}
          doc={doc}
          changedDocs={changedDocs}
          setChangedDocs={setChangedDocs}
          checkedDocs={checkedDocs}
          input_key="unit_cost"
        />
      </TableCell>
      <TableCell w={90 / 5}>
        <NumberInput
          input={qty}
          setInput={setQty}
          doc={doc}
          changedDocs={changedDocs}
          setChangedDocs={setChangedDocs}
          checkedDocs={checkedDocs}
          input_key="qty"
        />
      </TableCell>
      <TableCell>
        <div className=" w-full flex justify-center gap-x-4 text-3xl text-primary">
          {/* <MultiCheckBox
            docs={docs}
            doc={doc}
            _id={doc._id}
            setDocs={setDocs}
          /> */}
          <MultiCheckBox
            checkedDocs={checkedDocs}
            setCheckedDocs={setCheckedDocs}
            _id={doc.id}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TRow;
