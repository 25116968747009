import axios from "axios";

const useAxios = () => {
  return async (obj) => {
    const {
      headers = {},
      method,
      data = {},
      url,
      baseUrl = "https://api.v2.mkombozihealthcare.co.tz/api",
      params = {},
    } = obj;
    try {
      let res = await axios({
        headers: headers,
        method: method,
        url: `${baseUrl}/${url}`,
        data: data,
        params: params,
      });
      return res.data;
    } catch (e) {
      return "error";
    }
  };
};

export default useAxios;
