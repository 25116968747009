import React from 'react'
import { FaPeopleCarry } from 'react-icons/fa'

export default function Vendors({vendors}) {
    return (
        <div className="w-full h-56 shadow-sm rounded-2xl bg-primary p-6 flex flex-col justify-between">
        <div className="flex justify-between items-center mb-1 ">
            <div className="font-DM-Reg text-2xl text-white">
                <p>Vendors</p>
            </div>
            <div>
                <FaPeopleCarry
                    size="32px"
                    className="text-white"
                />
            </div>
        </div>
        <div className="flex justify-between items-center my-5 ">
            <div className="font-pop-reg text-5xl text-white">
                <p>{vendors}</p>
            </div>
            <div className="flex justify-center items-center ">
                <div className="flex flex-col justify-center items-start">
                    <p className="text-white my-0 underline cursor-pointer text-lg"></p>
                </div>
            </div>
        </div>
    </div>
    )
}
