import React, { useContext } from "react";

import Steppers from "../../../../../../../Components/Steppers/Steppers";

import AllocateContext from "../AllocateContex";

import {
  FaWpforms,
  FaUserPlus,
  FaLocationArrow,
  FaCheckSquare,
} from "react-icons/fa";

import { BsFillHouseFill } from "react-icons/bs";

const AddSteppers = ({ currentStep, close }) => {
  const { allocate, step } = useContext(AllocateContext);
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>Allocate Info</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        {allocate.to_user === true ? (
          <>
            {" "}
            <FaUserPlus />
            <p>Allocate To {step === 0 ? "User" : ""}</p>
          </>
        ) : (
          <>
            {" "}
            <BsFillHouseFill />
            <p>Allocate To {step === 0 ? "Space" : ""}</p>
          </>
        )}
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaCheckSquare />
        <p>Complete</p>
      </div>
    </Steppers>
  );
};

export default AddSteppers;
