import React, { useContext } from "react";

import VendorAll from "../context/VendorsContext";

const useAllVendors = () => {
  const doc = useContext(VendorAll);

  return doc;
};

export default useAllVendors;
