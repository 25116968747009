import { IoMdAdd } from "react-icons/io"
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded"
import OrderItems from "../../SinglePurchaseOrder/components/OrderItems"
import { useNavigate } from "react-router-dom";

const AddOrderItems = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    // setShowError(showError + 1);
    navigate("/dashboard/orders/add/details")
  };
  return (
    <div className="flex flex-col gap-y-10 p-9">
        <div className="flex justify-between items-center">
            <h1 className='font-DM-Bold font-bold text-2xl text-black'>Items</h1>

            <BtnRounded click={handleClick} className="btn">
                <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
                    <span className='text-xl'><IoMdAdd /></span>
                    <span>Add Items</span>
                </div>
            </BtnRounded>
        </div>

        <OrderItems data={[]}/>
    </div>
  )
}

export default AddOrderItems