import React from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../../Components/FinalTable";

import { BsCloudDownload } from "react-icons/bs";

import { MdDelete } from "react-icons/md";

import { convDate } from "./../../../../../../../../Util/Time";

import useAxios from "./../../../../../../../../Hooks/useAxios";
import useDownloader from "react-use-downloader";

import useApp from "../../../../../../../../Hooks/useApp";

const TableBody = ({ documents, setDocument }) => {
  const request = useAxios();

  const { download } = useDownloader();

  const { setShowSuccessModal, setModalMessage } = useApp();

  async function handleDownload(doc) {
    let res = await request({
      method: "POST",
      url: "vendor/download-document",
      data: {
        url: doc.url,
      },
    });

    if (res === "error") return;

    download(res.link, doc.documenent_name);
  }

  async function handleDelete(doc) {
    let res = await request({
      method: "DELETE",
      url: `vendor/document/${doc.id}`,
    });
    if (res === "error") return;
    let newDocs = documents.filter((row) => (row.id === doc.id ? false : true));
    setDocument(newDocs);
    setModalMessage("Document deleted.");
    setShowSuccessModal(true);
  }

  return (
    <>
      {documents.map((doc) => (
        <TableRow key={doc._id}>
          <TableCell w={90 / 4}>
            <TableText label={doc.description} />
          </TableCell>
          <TableCell w={90 / 4}>
            <TableText label={doc.document_type} />
          </TableCell>
          <TableCell w={90 / 4}>
            <TableText label={convDate(doc.created_at)} />
          </TableCell>
          <TableCell>
            <p className=" w-full flex justify-center gap-x-4 text-3xl text-primary">
              <span
                className=" active:opacity-50 cursor-pointer"
                onClick={() => handleDownload(doc)}
              >
                <BsCloudDownload />
              </span>
              <span
                className=" active:opacity-50 cursor-pointer text-red"
                onClick={() => handleDelete(doc)}
              >
                <MdDelete />
              </span>
            </p>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableBody;
