import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router";
import AppContext from "./Context/AppContext";

const ProtectedRoutes = () => {
  const { isLoggedIn } = useContext(AppContext);

  // return <Outlet />;
  return isLoggedIn ? <Outlet /> : <Navigate to="" />;
};

export default ProtectedRoutes;
