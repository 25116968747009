import Badge from "../../../../../../../Components/Badges/Badge"
import { 
    HorizontalActionBtn, 
    Table, 
    TableHeaderRow, 
    TableInfoCell, 
    TableInfoRow, 
    TablePagination, 
    TableText, 
    TableRow,
    TableCell
} from "../../../../../../../Components/FinalTable"
import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort"
import SearchInput2 from "../../../../../../../Components/Inputs/SearchInput"
import CheckBox from "../../../../../../../Components/Inputs/CheckBox"
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded"
import { AiOutlineArrowRight } from "react-icons/ai"
import dummyVendors from "./dummyVendors"

const ChooseVendor = ({data=dummyVendors, setStep}) => {
  return (
    <div className="bg-white rounded-[15px] p-5">
        <SearchInput2 />
        <Table>
            <TableHeaderRow>
                <HeaderBtnSort w={90 / 5} label="NAME" />
                <HeaderBtnSort w={90 / 5} label="EMAIL" />
                <HeaderBtnSort w={90 / 5} label="PHONE" />
                <HeaderBtnSort w={90 / 5} label="CATEGORY" />
                <HeaderBtnSort w={90 / 5} label="STATUS" />
                <div className="flex-1 invisible">
                    <HorizontalActionBtn></HorizontalActionBtn>
                </div>
            </TableHeaderRow>
            {data.map((doc, i) => (
            <TableRow mt={10} key={i}>
              <TableCell w={90 / 5}>
                <TableText label={doc.name} />
              </TableCell>
              <TableCell w={90 / 5}>
                <TableText label={doc.email} />
              </TableCell>
              <TableCell w={90 / 5}>
                <TableText label={doc.phone} />
              </TableCell>
              <TableCell w={90 / 5}>
                <TableText label={doc.category} />
              </TableCell>
              <TableCell w={90 / 5}>
                <Badge textColor={"primary"} label={doc.status}/>
              </TableCell>
              <div className="flex-1">
               <div className="flex justify-center items-start">
                <CheckBox label="" />
               </div>
              </div>
            </TableRow>
            ))}

            <TableInfoRow mt={20}>
              <TableInfoCell limit={10} setLimit={() => {}} />
              <TablePagination />
            </TableInfoRow>
        </Table>

        <div className="py-3 flex justify-center w-full">
            <div className="w-64">
                <BtnRounded click={()=>setStep(prev=> prev+1)} className="btn">
                    <div className="flex gap-x-3 items-center justify-center">
                        <span className="font-DM-Med text-[15px]">Continue</span>
                        <span  className="font-DM-Med text-[1.1rem]"><AiOutlineArrowRight /></span>
                    </div>
                </BtnRounded>
            </div>
        </div>

    </div>
  )
}

export default ChooseVendor