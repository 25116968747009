import { useRef, useContext, useState } from "react";
import FilterModal from "../../../../../Components/Modals/FilterModal";
import DatePicker from "../../../../../Components/Inputs/DatePicker"
import { convDate2, toTimeStamp } from "../../../../../Util/Time";
import AllRequestsContext from "../context/AllRequestsContext";
import SelectInput from "../../../../../Components/Inputs/SelectInput";

const FilterComponent = ({ open, setOpen }) => {
  
  const { handleFilterQuery } = useContext(AllRequestsContext);
  
  const input = useRef({
    form: {
      minDate: "",
      maxDate: "",
      status: "",
      item_type: "",
    },
    err: {},
  }); 

  let statuses = [
    {value : "", label: "Select Status" },
    {value : "approved", label: "Approved" },
    {value : "pending", label: "Pending" },
    {value : "canceled", label: "Cancelled" }
  ]

  let categories = [
    {value : "", label: "Select Category" },
    {value : "inventory", label: "Inventory Items" },
    {value : "fixed", label: "Fixed Assets" },
  ]

  
  async function applyFilters() {

    let filters = {};
    let to_filter = false;
    
    if (input.current.form.minDate !== "") {
      filters.filter_start_date = new Date(input.current.form.minDate).toISOString();
      to_filter = true;
    }

    if (input.current.form.maxDate !== "") {
      filters.filter_end_date = new Date(input.current.form.maxDate).toISOString();
      to_filter = true;
    }

    if (input.current.form.status !== "") {
      filters.status = input.current.form.status
      to_filter = true;
    }

    if (input.current.form.item_type === "inventory") {
      filters.filter_is_inventory = true
      to_filter = true;
    }
    if (input.current.form.item_type === "fixed") {
      filters.filter_is_inventory = false
      to_filter = true;
    }
   
    if (to_filter) {
      handleFilterQuery(filters);
      setOpen(false);
      return;
    }
  }

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters}>
      <div>
        
        <div className="flex flex-col">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            date filter
          </h3>
          
            <DatePicker
              initial={""}
              input={input}
              inputKey="minDate"
              mt={0}
              mb={0}
              config={{
                required: false,
                disabled: false,
                max: convDate2(Date.now()),
                label: "FROM",
              }}
            />
            
            <DatePicker
              initial={""}
              input={input}
              inputKey="maxDate"
              mt={0}
              mb={0}
              config={{
                required: false,
                disabled: false,
                max: convDate2(Date.now()),
                label: "TO",
              }}
            />
           
        </div>

        <div className="flex flex-col  gap-y-4">
          <div>
            <h3 className="uppercase mt-2 mb-2  font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              STATUS
            </h3>
            <div className="w-full">
              <div className="flex flex-col gap-y-2 ">
                <SelectInput 
                  input={input}
                  inputKey='status'
                  mt={0.5}
                  config={{
                    required: true,
                    firstDisabled: true,
                    items: statuses,
                    label: "",
                  }}
                />
              </div>
            </div>
            <h3 className="uppercase mt-2 mb-2  font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              TYPE
            </h3>
            <div className="w-full">
              <div className=" flex flex-col gap-y-2 ">
                <SelectInput 
                  input={input}
                  inputKey='item_type'
                  mt={0.5}
                  config={{
                    required: true,
                    firstDisabled: true,
                    items: categories,
                    label: "",
                  }}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </FilterModal>
  );
};

export default FilterComponent;
