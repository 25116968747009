import React, { useState } from "react";

import Image1 from "../../../../../../../Assets/Images/vendorAsset.png";
import ActionList from "./ActionList";

import { BsThreeDotsVertical } from "react-icons/bs";

import Badge from "../../../../../../../Components/Badges/Badge";

const PersonalDetails = ({ vendor }) => {
  const [clicked, setClicked] = useState(false);

  const { name, email, phone, bio, tra_pin, is_active } = vendor;

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div
      className=" bg-white rounded-lg shadow-md mt-10 p-8  relative"
      style={{ width: 1137, height: 276 }}
    >
      <div className=" flex justify-between self-center gap-x-8">
        <img src={Image1} alt="Vendor" style={{ width: 224, height: 225 }} />
        <div className=" ">
          <p className=" text-sm text-[#98A1B5] font-DM-Bold">NAME</p>
          <p className=" text-sm mb-5 font-DM-Reg">{name}</p>

          <p className=" text-sm text-[#98A1B5] font-DM-Bold">EMAIL</p>
          <p className=" text-sm mb-5 font-DM-Reg">{email}</p>

          <p className=" text-sm text-[#98A1B5] font-DM-Bold">PHONE NUMBER</p>
          <p className=" text-sm mb-5 font-DM-Reg">{phone}</p>
          <p className=" text-sm text-[#98A1B5] font-DM-Bold">TRA PIN</p>
          <p className=" text-sm mb-5 font-DM-Reg">{tra_pin}</p>
        </div>

        <div className=" ">
          <p className=" text-sm text-[#98A1B5] font-DM-Bold">BIO</p>
          <p className=" text-sm mb-5 font-DM-Reg">{bio}</p>
          <p>
            <Badge
              label={is_active ? "Active" : "Inactive"}
              bg={is_active ? "pale_spring_bud" : "pale_pink"}
            />
          </p>
        </div>
        <span
          className=" cursor-pointer absolute right-2 top-5"
          onClick={() => {
            handleClick();
          }}
        >
          <BsThreeDotsVertical />
        </span>
      </div>
      {clicked ? <ActionList vendor={vendor} /> : <div></div>}
    </div>
  );
};

export default PersonalDetails;
