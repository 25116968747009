import { useState, useRef, useEffect } from "react";

import { MdOutlineInventory } from "react-icons/md";
import FormScreen from "../../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import CancelBar from "../../../../../../Components/Steppers/CancelBar";
import Form from "./components/Form";

const EditInventory = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
      <FormScreen backBtn={false} back={() => {}}>
        <FormLabel label="Edit Fixed Asset Item" mt={2} mb={2}>
          <MdOutlineInventory />
        </FormLabel>
        <CancelBar />
        <div className="w-full h-full flex flex-row justify-center items-center">
          <Form />
        </div>
      </FormScreen>
    </div>
  );
};

export default EditInventory;
