import React, { useContext, useState } from "react";
import HeaderBtn from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";
import HeaderBtnSort from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";
import TableContext from "./TContext";
import {
  TableHeaderRow,
  TableHeaderBtnSort,
} from "../../../../../../../../Components/FinalTable";

const THead = () => {
  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
  useContext(TableContext);
  const [qty, setqty] = useState(true);
  const [dateCreated, setdateCreated] = useState(true);


  function handleDateCreatedSort() {
    if (showLive) {
          handleHeaderQuery("created_at", dateCreated ? "asc" : "desc");
          setdateCreated(!dateCreated);
          setIsFiltered(true);
          return;
        }
  }

  function handleQtySort() {

    if (showLive) {
      handleHeaderQuery("qty", qty ? "asc" : "desc");
      setqty(!qty);
      setIsFiltered(true);
      return;
    }

    }

  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={100 / 5} label="Name" />
        <HeaderBtn w={100 / 5} label="Role" />
        <HeaderBtnSort w={100 / 5} label="QTY" click={handleQtySort} />
        <HeaderBtnSort w={100 / 5} label="Date" click={handleDateCreatedSort} />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
