import React, { useEffect, useState } from "react";
import chekingBoxes from "../../../../../../../Assets/Images/checkingBoxes.png";
import "./../styles.css";

import { convDate2 } from "./../../../../../../../Util/Time";

import Badge from "../../../../../../../Components/Badges/Badge";

import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../../../Hooks/useAxios";

const InventoryItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const request = useAxios();

  const [inv, setInv] = useState({});

  useEffect(() => {
    async function fetchAll() {
      let res = await request({
        method: "GET",
        url: `inventory/${id}`,
      });

      if (res === "error") {
        navigate(-1);
        return;
      }
      setInv(res);
    }
    fetchAll();
  }, []);
  return (
    <div className="flex flex-col md:flex-row w-full inventory-item">
      <div className="flex-1 flex flex-col gap-9">
        <img
          className="max-w-[200px] max-h-[200px]"
          src={chekingBoxes}
          alt="Checking Boxes"
        />
        <div>
          <h2 className=" text-[#98A1B5]">NAME</h2>
          <p>{inv.name ? inv.name : "---"}</p>
        </div>
        <div>
          <h2>CATEGORY</h2>
          <p>
            {inv.inventory_item_categories
              ? inv.inventory_item_categories.name
              : "---"}
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start  w-full gap-9">
        <Badge click={() => navigate(`../edit/${id}`)} label="Edit" />
        <div>
          <h2>Unit of measure</h2>
          <p>{inv.unit_of_measures ? inv.unit_of_measures.name : "---"}</p>
        </div>
        <div>
          <h2>Unit cost</h2>
          <p>Tsh {inv.unit_cost ? inv.unit_cost : "---"}</p>
        </div>

        <div>
          <h2>Expiry date</h2>
          <p>
            {inv.expiry_date === null || inv.expiry_date === ""
              ? "Not Set"
              : convDate2(parseInt(inv.expiry_date))}
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start w-full gap-9">
        {/* <span className="py-2 px-4 bg-primary rounded-lg">
          <p className="opacity-40 text-white">Edit</p>
        </span> */}
        <Badge
          click={() => navigate(`../allocate/${id}`)}
          bg="primary"
          textColor="white"
          label="Alocate"
        />
        <div>
          <h2>Minimum order quantity</h2>
          <p>{inv.moq ? inv.moq : "---"}</p>
        </div>
        <div>
          <h2>Shelf no.</h2>
          <p>{inv.shelf_no || "Not Set"}</p>
        </div>
      </div>
    </div>
  );
};

export default InventoryItem;
