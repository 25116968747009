import React, { useState, useEffect } from "react";

import { FaPlus } from "react-icons/fa";

import BtnRounded from "../../../../../../../../../Components/Buttons/BtnRounded";

import { Table } from "../../../../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import AddOtherChargesModal from "./../../Modals/AddOtherCharges";


const FixedAssets = ({ po, charges, setCharges, fetchData }) => {

  const [showOtherChargesModal, setShowOtherChargesModal] = useState(false);

  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
  }, []);
  
  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          data: charges,
          setData: setCharges,
        }}
      >
        <Table>
          <div className=" w-full  flex justify-between  my-6">
            <p className=" text-xl font-DM-Bold text-cadet_grey">
              Other Charges
            </p>
            <BtnRounded click={() => setShowOtherChargesModal(true)}>
              <span className="text-sm pr-3">
                {" "}
                <FaPlus />{" "}
              </span>
              <span className="font-DM-Med text-[15px]">Add Charge</span>
            </BtnRounded>
          </div>

          <THead />
          <TBody />

          <div className=" w-full flex justify-end">
            <p className=" text-2xl text-cadet_grey">
              <small>TZS: </small>
              <span className=" font-DM-Bold">{po.other_total}</span>
            </p>
          </div>
        </Table>
        <AddOtherChargesModal
          showModal={showOtherChargesModal}
          setShowModal={setShowOtherChargesModal}
          fetchData={fetchData}
        />
      </TContext.Provider>
    </div>
  );
};

export default FixedAssets;
