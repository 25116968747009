import React, { useState, useContext } from "react";

import TContext from "./TContext";

import TRow from "./TRow";

const TBody = () => {
  const { data } = useContext(TContext);

  return (
    <>
     {data.map(doc => doc.spaces !== null && (<TRow key={doc._id || doc.id} doc={doc} />))}
    </>
  );
};

export default TBody;
