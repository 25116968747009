import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import BasicModal from "../../../../../../../../Components/Modals/BasicModal"
import useAxios from '../../../../../../../../Hooks/useAxios2';
import usePouchCud from '../../../../../../Hooks/usePouchCud';
import useApp from '../../../../../../../../Hooks/useApp';
import BtnRounded from '../../../../../../../../Components/Buttons/BtnRounded';
import { useState } from 'react';


const DeleteOrderModal = ({
    showModal = false,
    setShowModal,
    doc,
    setDoc
}) => {
const request = useAxios()
const navigate = useNavigate();
const { deleteDoc } = usePouchCud();
const { setModalMessage, setShowSuccessModal } = useApp();
const [showError, setShowError] = useState(0)
const {id} = useParams()

async function handleDeleteOrder() {
    await setShowError(showError + 1);

    let res = await request ({
        method: "DELETE",
        url: `purchase/${id}`
        });
        
    if (res !== "error"){
        await deleteDoc({name:"purchase_orders", doc:res})
        await setModalMessage("Order Deleted");
        await setShowSuccessModal(true);
        setShowModal(false)
        navigate("../")
    }  
        
      }
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col  gap-y-8 p-3" style={{ width: "30em" }}>
        <div style={{ marginTop: "-2.5em" }}>
          <p className=" text-2xl font-DM-Med text-red">
            Are you sure you want to delete 
            <span className='text-2xl font-DM-Med text-gray-900'> {doc.name}?</span> 
          </p>
        </div>
        <BtnRounded click={handleDeleteOrder}>Delete</BtnRounded>
      </div>
    </BasicModal>
  )
}

export default DeleteOrderModal;
