import React, { useState, useEffect } from "react";

import { colors } from "./../../Util/colors";

const TextArea = ({
  input,
  inputKey,
  initial = "",
  showError = 0,
  mt = 0,
  mb = 0,
  config = {
    disabled: false,
    required: true,
    label: "Text input",
    placeholder: "",
    type: "text",
    maxChar: 200,
    minChar: 0,
    inputType: "text",
  },
}) => {
  const [textInput, setTextInput] = useState(initial);
  const [borderColor, setBorderColor] = useState("bright_grey");

  const [err, setErr] = useState(false);

  const [errMessage, setErrorMessage] = useState("Field Required");

  useEffect(() => {
    if (showError === 0) {
      return;
    }
    handleBlur();
  }, [showError]);

  useEffect(() => {}, [textInput]);

  function handleChange(txt) {
    if (txt.length > config.maxChar) {
      return;
    }
    setTextInput(txt);
    input.current.form[inputKey] = txt;
  }

  function handleBlur() {
    if (config.required === false) {
      return;
    }

    if (textInput === "") {
      setBorderColor("red");
      setErr(true);
      input.current.err[inputKey] = true;
      setErrorMessage(`* Field required`);
      return;
    }

    if (textInput.length < config.minChar) {
      setBorderColor("red");
      setErr(true);
      setErrorMessage(`* min charters required ${config.minChar}`);
      input.current.err[inputKey] = true;
      return;
    }

    input.current.err[inputKey] = false;
    setBorderColor("bright_grey");
    setErr(false);
    setErrorMessage(`* Field required`);
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className=" font-DM-Reg text-base">{config.label}</label>
      <textarea
        className={`border-2 rounded-lg w-full min-h-[210px] py-2 px-8 placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
          cursor: config.disabled ? "not-allowed" : "text",
        }}
        placeholder={config.placeholder}
        value={textInput}
        disabled={config.disabled}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
      />
      {err ? <small className="text-red">{errMessage}</small> : null}
    </div>
  );
};

export default TextArea;
