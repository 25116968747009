import Location from "../../../../../../../Assets/Images/Address.png";

const AddressCard = ({ address }) => {
  const { city, country, address_1, address_2, address_3, postal_code } =
    address;
  return (
    <div
      className=" bg-white rounded-md shadow-lg p-5 mt-10"
      style={{ width: 649, height: 363 }}
    >
      <div className=" flex justify-between">
        <div className=" flex flex-col justify-between font-DM-Reg">
          <p className=" text-sm font-DM-Bold">VENDOR ADDRESS</p>
          <img src={Location} alt="Location" />
          <div className=" mt-2">
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">ADDRESS 1</p>
            <p className=" text-sm font-DM-Reg"> {address_1}</p>
          </div>
        </div>

        <div className="flex flex-col justify-between">
          <div>
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">ADDRESS 2</p>
            <p className=" text-sm font-DM-Reg">{address_2}</p>
          </div>

          <div>
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">ADDRESS 3</p>
            <p className=" text-sm font-DM-Reg">{address_3}</p>
          </div>

          <div>
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">City</p>
            <p className=" text-sm font-DM-Reg">{city}</p>
          </div>
        </div>

        <div className=" flex flex-col justify-between">
          <div>
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">COUNTRY</p>
            <p className=" text-sm font-DM-Reg">{country}</p>
          </div>

          <div>
            <p className=" text-sm text-[#98A1B5] font-DM-Bold">POSTAL CODE</p>
            <p className=" text-sm font-DM-Reg">{postal_code}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
