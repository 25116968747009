import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded"
import OrderCharges from "../../SinglePurchaseOrder/components/OrderCharges"
import OrderDetails from "../../SinglePurchaseOrder/components/OrderDetails"
import OrderItems from "../../SinglePurchaseOrder/components/OrderItems"

const ViewPurchaseOrder = () => {
  return (
    <div className="w-full  py-9 px-5">
        <div className="flex flex-col items-center rounded-t-[30px] w-full bg-white p-9 gap-9">
          <OrderDetails />
          <div className="w-full py-9">
            <h1 className="font-DM-Bold text-2xl text-black">Items</h1>
            <OrderItems />
          </div>
          <div className="w-full py-9">
            <h1 className="font-DM-Bold text-2xl text-black">Purchase Order Charges</h1>
            <OrderCharges />
          </div>
          <div className="md:w-[470px] ">
            <BtnRounded click={null} className="btn">
              <span className="font-DM-Med text-[15px]">Create Order</span>
            </BtnRounded>
          </div>
        </div>
      </div>
  )
}

export default ViewPurchaseOrder