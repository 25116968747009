import BasicModal from "./BasicModal";

import Badge from "./../../Components/Badges/Badge";

import BtnBlock from "../Buttons/BtnBlock";

import useAxios from "../../Hooks/useAxios";

import { useNavigate, useParams } from "react-router-dom";
import usePouchCud from "../../Pages/Dashboard/Hooks/usePouchCud";

const newStatus = {
  pending: "approve",
  approved: "receive",
  received: false,
  // canceled: "cancel"
};

const statusDescription = {
  approved:
    "This action cannot be undone. Once approved you can't add or edit the purchase order",
  received:
    "This action cannot be undone. Once received the items will be added to the store",
  canceled: "This action cannot be undone.",
};

const OrderStatusModal = ({
  status,
  id,
  showModal = false,
  setDoc,
  setShowModal,
}) => {
  const request = useAxios();
  const navigate = useNavigate();
  const { update } = usePouchCud();

  async function handleSubmit() {
    let res = await request({
      method: "PUT",
      url: "purchase/status",
      data: {
        id: parseInt(id),
        status: status,
      },
    });

    if (res !== "error") {
      await update({ name: "purchase_orders", doc: res });
      setDoc(res.purchaseOrder);
      setShowModal(false);
      navigate(`/dashboard/orders`);
    }
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Update Status <Badge label={status} />
        </h2>
        <div className=" my-2 border-dashed border-2 p-8 border-red text-black ">
          {statusDescription[status]}
        </div>
        <BtnBlock click={handleSubmit} mt={2.5} bg="primary" textColor="white">
          <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
            <span>Continue</span>
          </div>
        </BtnBlock>
      </div>
    </BasicModal>
  );
};

export default OrderStatusModal;
