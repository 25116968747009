import React from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import TableHeader from "./TableHeader";

import TableTabs from "./TableTabs";

import TableBody from "./TableBody";

import TableInfoSection from "./TableInfoSection";

const AllInventory = () => {
  return (
    <Table>
      {/* <TableTabs /> */}
      <TableHeader />
      <TableBody />
      <TableInfoSection />
    </Table>
  );
};

export default AllInventory;
