import React, { useRef, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import TextInput from "../../../../../../../Components/Inputs/TextInput";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import useAddVendor from "../hooks/useAddVendor";

import useAxios from "./../../../../../../../Hooks/useAxios";

const ContactPerson = ({ setStep }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);
  const formErrors = useHandleFormError();

  const { contactPerson, setContactPerson } = useAddVendor();

  const request = useAxios();

  const handleSubmit = async () => {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "vendor/validate",
      data: { contactPerson: input.current.form },
    });

    await setContactPerson({ ...contactPerson, ...input.current.form });
    if (res === "error") {
      return;
    }

    setStep((c) => c + 1);
  };

  return (
    <form className="flex flex-col w-full max-w-xl mx-auto bg-white rounded-[15px] p-10 ">
      <TextInput
        initial={contactPerson.name || ""}
        input={input}
        showError={showError}
        inputKey="name"
        mt={0.5}
        config={{
          required: true,
          label: "Name",
          placeholder: "Eg. John Doe",
          type: "text",
          maxChar: 250,
          minChar: 4,
        }}
      />

      <TextInput
        initial={contactPerson.email || ""}
        input={input}
        showError={showError}
        inputKey="email"
        mt={0.5}
        config={{
          required: true,
          label: "Email",
          placeholder: "Eg. johndoe@gmail.com",
          type: "email",
          inputType: "email",
          maxChar: 250,
          minChar: 4,
        }}
      />

      <NumberInput
        initial={contactPerson.phone || ""}
        input={input}
        showError={showError}
        inputKey="phone"
        mt={0.5}
        config={{
          required: true,
          label: "Phone",
          placeholder: "Enter phone number ",
        }}
      />

      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
        <span className="px-3 font-normal text-2xl">
          <FaLongArrowAltRight />
        </span>
      </BtnRounded>
    </form>
  );
};

export default ContactPerson;
