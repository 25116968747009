import Steppers from "../../../../../../../Components/Steppers/Steppers";

import { useNavigate } from "react-router-dom";

import {
  FaPlusCircle,
  FaWpforms,
  FaUserPlus,
  FaLocationArrow,
  FaCoins,
} from "react-icons/fa";
const EditStepper = ({ currentStep }) => {
  const navigate = useNavigate();
  return (
    <Steppers currentStep={currentStep} close={() => navigate(-1)}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>Edit Vendor Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaUserPlus />
        <p>Edit Contact Person</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaLocationArrow />
        <p>Edit Vendor Address</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaCoins />
        <p>Edit Payment Details</p>
      </div>
    </Steppers>
  );
};

export default EditStepper;
