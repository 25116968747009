import { useRef, useState } from "react";
import TextInput from "../../../../../../../../Components/Inputs/TextInput";
import NumberInput from "../../../../../../../../Components/Inputs/NumberInput";
import TextArea from "../../../../../../../../Components/Inputs/TextArea";
import BasicModal from "../../../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";

import useHandleFormError from "../../../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../../Hooks/useApp";
import usePouchCud from "../../../../../../Hooks/usePouchCud";


const EditPoModal = ({ showModal = false, setShowModal, doc, setDoc }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  const request = useAxios();

  const  { update } = usePouchCud() 

  const { setModalMessage, setShowSuccessModal } = useApp();

  const formErrors = useHandleFormError();
  
  const handleSubmit = async () => {
    setShowError(showError + 1);
    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let data = {
      id: parseInt(id),
      name: input.current.form.name,
      description: input.current.form.description,
      vat: parseInt(input.current.form.vat),
    };

    let res = await request({
      method: "PUT",
      url: "purchase",
      data: data,
    });

    if (res === "error") return;
      await update({name:"purchase_orders", doc:res})
      await setModalMessage("Order Updated");
      await setShowSuccessModal(true);
      setDoc(res);
      setShowModal(false);
  };
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Enter Name and Description of your Order
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            initial={doc.name}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Enter Name",
              type: "text",
              inputType: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <NumberInput
            initial={doc.vat}
            inputKey="vat"
            input={input}
            mt={0.5}
            showError={showError}
            config={{
              required: true,
              label: "Order Vat in %",
              placeholder: "14",
              min: 0,
              max: 99,
            }}
          />
          <TextArea
            initial={doc.description || ""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: false,
              label: "Description",
              placeholder: "Enter Description",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />

          <BtnBlock
            click={handleSubmit}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Continue</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default EditPoModal;
