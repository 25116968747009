import React from "react";
import {
  TableRow,
  TableCell,
  SingleCheckBox,
  TableText,
} from "../../../../../../../../Components/FinalTable";

const TRow = ({ checked_id, setCheckedId, doc }) => {
  return (
    <TableRow>
      <TableCell w={90 / 4}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.email} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.phone} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.team_roles.role} />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
