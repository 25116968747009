import { useRef, useState } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import DatePicker from "../../../../../../../Components/Inputs/DatePicker";
import CheckBox from "../../../../../../../Components/Inputs/CheckBox";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import { AiOutlineArrowRight } from "react-icons/ai";

const AddOrdersCharges = ({setStep}) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  return (
    <div className="flex justify-center">
        <div className="bg-white rounded-[15px] p-9 lg:w-[858px]">
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Amount",
                 placeholder: "Enter amount",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Reason",
                 placeholder: "Enter reason",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Type",
                 placeholder: "Admin",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />
            <TextInput
               initial=""
               input={input}
               showError={showError}
               inputKey="text"
               mt={0.5}
               config={{
                 required: true,
                 label: "Info",
                 placeholder: "Enter info",
                 type: "text",
                 inputType: "text",
                 maxChar: 250,
                 minChar: 4,
               }}
            />

            <div className="mx-3 py-8">
                <BtnRounded click={()=>setStep(prev=> prev+1)} className="btn">
                    <div className="flex gap-x-3 items-center justify-center">
                        <span className="font-DM-Med text-[15px]">Add Charge</span>
                        {/* <span  className="font-DM-Med text-[1.1rem]"><AiOutlineArrowRight /></span> */}
                    </div>
                </BtnRounded>
            </div>
        </div>

    </div>
  )
}

export default AddOrdersCharges