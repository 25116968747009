import { IoMdAdd } from "react-icons/io"
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded"
import OrderCharges from "../../SinglePurchaseOrder/components/OrderCharges"

const AddOrderCharges = () => {
  return (
    <div className="flex flex-col gap-y-10 p-9">
        <div className="flex justify-between items-center">
            <h1 className='font-DM-Bold font-bold text-2xl text-black'>Charges</h1>

            <BtnRounded className="btn">
                <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
                    <span className='text-xl'><IoMdAdd /></span>
                    <span>Add Charge</span>
                </div>
            </BtnRounded>
        </div>

        <OrderCharges data={[]}/>
    </div>
  )
}

export default AddOrderCharges