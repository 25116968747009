import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "./../../../../../../../../Util/Time";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={100 / 5}>
        <TableText label={doc.team.name} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={doc.team.team_roles.role} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={doc.qty} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
